import React, { Component } from "react";
import { TextField, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { getUsers, token, getUsersCPF } from "../services/dados"
import Swal from 'sweetalert2'

const $ = require('jquery');


export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201 || status === 110) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alert severity={classe}>
                    {param.texto}
                </Alert>
            </Snackbar>
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

function apareceAlert(props) {
    var url = props.location.pathname
    var path = props.location.search
    var caminho = url + path
    return (window.location = caminho);
}

class CadastroUsuarios extends Component {
    constructor() {
        super();
        this.state = {
            dados: [],
            role: '',
            nome: '',
            cpf: '',
            senha: '123456',
            alert: {
                status: '',
                message: ''
            },
            setOpen: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    // apareceAlert() {
    //     setTimeout(() => {
    //         this.setState({ setOpen: false })
    //         this.setState({
    //             role: '',
    //             nome: '',
    //             cpf: '',
    //             senha: ''
    //         })
    //     }, 3 * 10)
    // }

    buscaCpf = event => {
        var id = event.target.value;
        var cpf = window.btoa(id)
        getUsersCPF(cpf).then(response => {
            if (response.data === 1) {
                alert("Usuario já Cadastrado")
                this.setState({ cpf: '' })
                setTimeout(() => {
                    $("#resposta").html('');
                }, 1 * 1000)
                return (window.location = "/CadastrarUsuarios")
            } else {
                setTimeout(() => {
                    $("#resposta").html('');
                }, 1 * 1000)
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const { nome, username, role, senha } = this.state
        var raw = { nome, username, role, senha }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log(raw)
        apiBusca.post("/cadastros/formUser", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                let propis = this.props
                if (this.state.alert.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: this.state.alert.message
                    }).then(function () {
                        apareceAlert(propis)
                    })

                }
                else if (this.state.alert.status === 201) {
                    Toast.fire({
                        icon: 'error',
                        title: this.state.alert.message
                    })
                }
            })

            .catch(error => {
                this.setState({
                    alert: {
                        status: 201,
                        message: "Contate o Desenvolvedor do Sistema! insertUsuarios()->BAD_CONFIG"
                    }
                })
            })
        event.target.reset();
    }

    render() {
        var alertMsg = this.state.alert
        var dados = this.state.dados
        var valores = []
        if (dados.role === 'MEGA_ADM') {
            valores = [
                {
                    value: 'ROLE_CAD',
                    label: 'Cadastros',
                },
                {
                    value: 'ROLE_ADM',
                    label: 'Administrador',
                },
                {
                    value: 'ROLE_UNI',
                    label: 'Baixas',
                },
            ];
        } else {
            valores = [
                {
                    value: 'ROLE_CAD',
                    label: 'Cadastros',
                },
                {
                    value: 'ROLE_UNI',
                    label: 'Baixas',
                },
            ];
        }
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Início
            <small>V. API 0.1</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Social">
                                        <i className="fa fa-dashboard" />Início
              </a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <fieldset className="card">
                                    <legend className="card-header text-left legend-pma faq-btn-link">
                                        Informações importantes
              </legend>
                                    <div className="col-md-4 alert-danger" id="resposta"></div>
                                    <div className="col-md-12 campos-pma card-body">

                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-11"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            fullWidth
                                            name="nome"
                                            label="Nome"
                                            variant="outlined"
                                            type="text"
                                            onChange={e => this.setState({ nome: e.target.value })}
                                        />
                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-11"
                                            id="cpf"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            fullWidth
                                            name="cpf"
                                            label="C.P.F. - Usuario"
                                            variant="outlined"
                                            type="text"
                                            onBlur={this.buscaCpf}
                                            onChange={e => this.setState({ username: e.target.value })}
                                        />
                                        <TextField
                                            required
                                            className="mb-3 ml-3 col-md-11 required"
                                            id="nivel"
                                            select
                                            fullWidth
                                            name="nivelAcesso"
                                            SelectProps={{
                                                native: true,
                                            }}
                                            value={this.state.role}
                                            onChange={e => this.setState({ role: e.target.value })}
                                            label="Nivel de Acesso"
                                            variant="outlined"
                                        >
                                            <option key={0} value=''></option>
                                            {valores.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-11"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            fullWidth
                                            name="password"
                                            label="Senha Padrão"
                                            value="123456"
                                            variant="outlined"
                                            type="text"
                                            onChange={e => this.setState({ senha: e.target.value })}
                                        />
                                    </div>
                                </fieldset>
                                <button
                                    className="btn-pv col-md-12 text-center btn-primary"
                                    type="submit"
                                >
                                    Cadastrar
                </button>
                            </form>
                        </section>
                    </div>
                    <Footer />
                </div >
            </div >
        );
    }
}
export default CadastroUsuarios;
