import { Button } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiNova } from "../services/api";

export default function Vacinacao({ dados }) {
  const [aplicacoes, setAplicacoes] = useState([]);

  console.log(aplicacoes);

  const history = useHistory();
  useEffect(() => {
    async function fetchAplicacoes() {
      const response = await Axios.get(
        `${apiNova}/api/v1/vacinados/${dados.id}`
      );
      const aplicacoes = response.data.result.sort(
        (a, b) => a.dose_vacina - b.dose_vacina
      );
      setAplicacoes(aplicacoes);
    }
    if (dados.id) {
      fetchAplicacoes();
    }
  }, [dados.id]);

  const handleClick = (id) => {
    const idCriptografadoPorAlgumMotivo = window.btoa(id);
    history.push(`/RemoverVacina?${idCriptografadoPorAlgumMotivo}`);
  };

  return (
    <div className="row">
      {aplicacoes.map((aplicacao, id) => {
        const dataAplicacao = new Date(aplicacao.dt_aplicacao);
        const dataFormatada = dataAplicacao.toLocaleDateString("en-GB", {
          timeZone: "UTC",
        });
        return (
          <div className={`col-md-3 table`}>
            <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
              <b>{id + 1}º APLICAÇÃO</b>
            </div>
            <div className="col-md-12 mt-3">
              Lote Vacina: <b>{aplicacao.lote}</b>
            </div>
            <div className="col-md-12 mt-3">
              Nome Vacina: <b>{aplicacao.nome_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Data de Aplicação: <b>{dataFormatada}</b>
            </div>
            <div className="col-md-12 mt-3">
              Aplicador: <b>{aplicacao.nome_aplicador}</b>
            </div>
            <div className="col-md-12 mt-3">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClick(aplicacao.id)}
              >
                Atualizar Dose
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
