import React, { Component } from "react";
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';

/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */
// all available props
const theme = {
    background: '#f5f8fb',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#004680',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#004680',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
};

class Review extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            cpf: '',
            dt_nasc: '',
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { name, cpf, dt_nasc } = steps;

        this.setState({ name, cpf, dt_nasc });
    }

    render() {
        const { name, cpf, dt_nasc } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <h3>Detalhes</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>Nome</td>
                            <td>{name.value}</td>
                        </tr>
                        <tr>
                            <td>C.P.F.</td>
                            <td>{cpf.value}</td>
                        </tr>
                        <tr>
                            <td>Data de Nascimento</td>
                            <td>{dt_nasc.value}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

Review.propTypes = {
    steps: PropTypes.object,
};

Review.defaultProps = {
    steps: undefined,
};

function calculaIdade(dataNasc) {
    var dataAtual = new Date(dataNasc);
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
    if (mesAtual < mesNasc) {
        idade--;
    } else {
        //Se estiver no mes do nascimento, verificar o dia
        if (mesAtual == mesNasc) {
            if (new Date().getDate() < diaNasc) {
                //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                idade--;
            }
        }
    }
    console.log(dataAtual)
    return idade;

}

const steps =
    [
        {
            id: '1',
            message: 'Olá, eu sou o BOT da PMA, vou realizar o seu cadastro, vamos lá?!',
            trigger: '2',
        },
        {
            id: '2',
            message: 'Qual o seu nome completo?',
            trigger: 'name',
        },
        {
            id: 'name',
            user: true,
            trigger: '3',
        },
        {
            id: '3',
            message: 'Olá {previousValue}, é um prazer te conhecer! Poderia me Informar o seu CPF?',
            trigger: 'cpf',
        },
        {
            id: 'cpf',
            user: true,
            trigger: '5',
        },
        {
            id: '5',
            message: 'Agora, Qual a sua data de Nascimento?',
            trigger: 'dt_nasc',
        },
        {
            id: 'dt_nasc',
            user: true,
            validator: (value) => {
                if (calculaIdade(value) < 80) {
                    return 'false';
                }
                return true;
            },
            trigger: 'end-message',
        },
        {
            id: '7',
            message: 'Que Legal! Verifique suas Informações',
            trigger: 'review',
        },
        {
            id: 'review',
            component: <Review />,
            asMessage: true,
            trigger: 'update',
        },
        {
            id: 'update',
            message: 'Seus dados estão todos corretos?',
            trigger: 'update-question',
        },
        {
            id: 'update-question',
            options: [
                { value: 'yes', label: 'SIM', trigger: 'end-message' },
                { value: 'no', label: 'NÃO', trigger: 'end-message' },
            ],
        },
        {
            id: 'end-message',
            message: 'Obrigado! Seu cadastro foi realizado com Sucesso ',
            end: true,
        },
    ]

class SimpleForm extends Component {
    render() {
        return (
            <div >
                <div style={{ margin: '20px', float: 'right' }}>

                    <ThemeProvider theme={theme}>
                        <ChatBot
                            headerTitle="Prefeitura Municipal de Anápolis"
                            speechSynthesis={{ enable: true, lang: 'pt-BR' }}
                            steps={steps} />
                    </ThemeProvider>
                </div>
            </div>
        );
    }
}

export default SimpleForm;