import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { getEditaCadastrosNome, getUsers } from "../services/dados"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alertin severity={classe}>
                    {param.texto}
                </Alertin>
            </Snackbar>
        </div>
    );
}
export function Alertin(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function apareceAlert() {
    setTimeout(() => {
        return (window.location = "/ListaMotoristas");
    }, 1 * 1000)
}

const columns = [
    {
        title: 'Nome',
        width: "auto",
        data: 'nome'
    },
    {
        title: 'C.P.F.',
        width: "auto",
        data: 'cpf'
    }
];

function DataTable(props) {
    var table = $('#table').DataTable({
        dom: 'Bfrtip',
        responsive: {
            details: {
                renderer: function (api, rowIdx, columns) {
                    var data = $.map(columns, function (col, i) {
                        if (col.columnIndex === 0) {
                            table.on('click', 'td', function () {
                                $("#entrega" + col.data).click(function () {
                                    // if ($(this).find('#entrega').length > 0) {
                                    props.entregaConf(col.data);
                                })
                                // }
                            });
                            if (columns[4].data === "PENDENTE" || columns[4].data === "ENTREGUE") {
                                return ('')
                            }
                            return (
                                '<tr data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<td>' + col.title + ': </td> ' +
                                '<td id="entrega' + col.data + '"><i class="fas fa-check"></i></td>' +
                                '</tr>'
                            )
                        }
                        if (col.columnIndex === 9) {
                            table.on('click', 'td', function () {
                                var id = columns[8].data
                                $("#obsentrega" + id).click(function () {
                                    // if ($(this).find('#entrega').length > 0) {
                                    props.inserirObs(id);
                                })
                                // }
                            });
                            if (columns[4].data === "PENDENTE" || columns[4].data === "ENTREGUE") {
                                return ('')
                            }
                            return (
                                '<tr data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<td>' + col.title + ': </td> ' +
                                '<td id="obsentrega' + columns[8].data + '"><i class="fas fa-plus"></i></td>' +
                                '</tr>'
                            )
                        }
                        return col.hidden ?
                            '<tr data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                            '<td>' + col.title + ': </td> ' +
                            '<td>' + col.data + '</td>' +
                            '</tr>' :
                            '';
                    }).join('');

                    return data ?
                        $('<table/>').append(data) :
                        false;
                }
            }

        },
        buttons: [
            {
                extend: 'pdf', className: 'btn-pdf', download: 'open', text: 'PDF',
                orientation: 'landscape',
                pageSize: 'A4',
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1]
                },
                customize: function (doc) {
                    var custom = doc.content[1].table
                    custom.widths = ['70%', '30%']
                    var objLayout = {};
                    objLayout['hLineWidth'] = function (i) { return .5; };
                    objLayout['vLineWidth'] = function (i) { return .5; };
                    objLayout['hLineColor'] = function (i) { return '#aaa'; };
                    objLayout['vLineColor'] = function (i) { return '#aaa'; };
                    objLayout['paddingLeft'] = function (i) { return 4; };
                    objLayout['paddingRight'] = function (i) { return 4; };
                    doc.content[1].layout = objLayout;
                }
            },
            {
                extend: 'excel', className: 'btn-excel', download: 'open', text: 'Excel',
                orientation: 'landscape',
                pageSize: 'A4',
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1]
                },
            },
            {
                extend: 'print', className: 'btn-print', text: 'Imprimir',
                orientation: 'landscape',
                pageSize: 'A4',
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1]
                },
            }
        ],

        data: props.cadastros,
        columns,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {
            $('td:eq(0)', row).click(
                function () {
                    props.buscaCPF(data.cpf);
                })
            $('td:eq(1)', row).click(
                function () {
                    props.buscaCPF(data.cpf);
                })


            $('td:eq(3)', row).css('display', '')
            $('td:eq(1)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
        },
        "headerCallback": function (thead, data, display) {

            $('th:eq(0)', thead).css('display', '')
            $('th:eq(1)', thead).css('display', '')
            $('th:eq(3)', thead).css('display', '')
            $('td:eq(4)', thead).css('white-space', 'normal')
        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}



class CadastroNomeBusca extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
        this.buscaCPF = this.buscaCPF.bind(this)
        this.obsInserir = this.obsInserir.bind(this)
    }

    getEventos() {
        var idBusca = this.props.location.search
        var id = idBusca.replace('?', '')
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data })
        });
        getEditaCadastrosNome(id).then(response2 => {
            this.setState({ dados: response2.data })
        });
    }

    obsInserir(id) {
        var teste = window.btoa(id)
        localStorage.setItem('edit', teste);
        return (window.location = "/EntregaObs");
    }

    async buscaCPF(teste) {
        var e = window.btoa(teste)
        return (window.location = "/CadastroCpf?" + e)
    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Resultados
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <Alerta
                                setOpen={this.state.setOpen}
                                texto={alertMsg.message}
                                classe={alertMsg.status} />

                            <DataTable
                                cadastros={this.state.dados}
                                inserirObs={this.obsInserir}
                                buscaCPF={this.buscaCPF} />
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}
export default CadastroNomeBusca