import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import {
  getRelatoriosDiario,
  getUsers,
  getDatas,
  getRelatoriosDiarioAPLI,
  getRelatoriosDiarioRapido,
} from "../services/dados";
import { TextField } from "@material-ui/core";
import { logoExport } from "../services/logo";
import { dateHoje } from "./ListaNaoVacinados2";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
require("jquery");
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-responsive-bs4");

var logo = logoExport;

const columns = [
  {
    title: "Nome",
    width: "auto",
    data: "nome",
  },
  {
    title: "C.P.F.",
    width: "auto",
    data: "cpf",
  },
  {
    title: "Tipo Cadastro",
    width: "auto",
    data: "tipo",
  },
  {
    title: "Idade",
    width: "auto",
    data: "idade",
  },
  {
    title: "Endereço",
    width: "auto",
    data: "rua",
  },
  {
    title: "Lote",
    width: "auto",
    data: "lote_vacina",
  },
  {
    title: "Vacina",
    width: "auto",
    data: "vacina",
  },
  {
    title: "Aplicador",
    width: "auto",
    data: "aplicador",
  },
  {
    title: "Data Cadastro",
    width: "auto",
    data: "dt_cadastro",
  },
  {
    title: "Dt. da Aplicação",
    width: "auto",
    data: "dt_aplicacao",
  },
  {
    title: "Dose Vacina",
    width: "auto",
    data: "dose_vacina",
  },
];

function DataTable(props) {
  $("#table").DataTable({
    dom: "Bfrtip",
    responsive: true,
    buttons: [
      {
        extend: "pdf",
        className: "btn-pdf",
        text: "PDF",
        orientation: "landscape",
        pageSize: "A4",
        filename:
          "Lista_Vacinados" + "_" + props.vacinador + "_" + props.data_br,
        layout: "border solid 1px",
        exportOptions: {
          columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        footer: {
          columns: ["Left part", { text: "Right part", alignment: "right" }],
        },
        customize: function(doc) {
          var custom = doc.content[1].table;
          custom.widths = [
            "15%",
            "10%",
            "16%",
            "5%",
            "10%",
            "10%",
            "10%",
            "5%",
            "9%",
            "5%",
            "5%",
          ];
          var objLayout = {};
          objLayout["hLineWidth"] = function(i) {
            return 0.5;
          };
          objLayout["vLineWidth"] = function(i) {
            return 0.5;
          };
          objLayout["hLineColor"] = function(i) {
            return "#aaa";
          };
          objLayout["vLineColor"] = function(i) {
            return "#aaa";
          };
          objLayout["paddingLeft"] = function(i) {
            return 4;
          };
          objLayout["paddingRight"] = function(i) {
            return 4;
          };
          doc.content[1].layout = objLayout;
          doc["header"] = function() {
            return {
              columns: [
                {
                  alignment: "center",
                  image: logo,
                  width: 220,
                },
              ],
              padding: 30,
            };
          };
          doc["footer"] = function(page, pages) {
            // console.log(usuario)
            return {
              columns: [
                {
                  alignment: "left",
                  text: [
                    "Data Relatório: " +
                      dateHoje() +
                      " - Solicitado por: " +
                      props.usuario +
                      " - " +
                      props.username,
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    "Vacinador(a): " +
                      props.vacinador +
                      " - Data de Aplicação: " +
                      props.data_br,
                  ],
                },
              ],
              margin: 20,
            };
          };
        },
      },
      {
        extend: "excel",
        className: "btn-excel",
        download: "open",
        text: "Excel",
        orientation: "landscape",
        filename: "Lista_Vacinados" + props.vacinador + props.data_br,
        pageSize: "A4",
        layout: "border solid 1px",
        exportOptions: {
          columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
      },
      // {
      //     extend: 'print', className: 'btn-print', text: 'Imprimir',
      //     orientation: 'landscape',
      //     pageSize: 'A4',
      //     layout: 'border solid 1px',
      //     exportOptions: {
      //         columns: [0, 1, 2, 3]
      //     },
      // }
    ],

    data: props.cadastros,
    columns,
    destroy: true,
    ordering: false,
    language: {
      lengthMenu: "Mostar _MENU_ Registro por Pagina",
      zeroRecords: "Nada foi encontrado",
      info: "Mostrando _PAGE_ de _PAGES_",
      infoEmpty: "Não foram encontrados Registros",
      infoFiltered: "(Filtrados de _MAX_ do Total)",
      search: "Buscar:",
      paginate: {
        first: "Primeiro",
        last: "Ultimo",
        next: "Proximo",
        previous: "Anterior",
      },
    },
    rowCallback: function(row, data) {
      $("td:eq(0)", row).click(function() {
        props.buscaCPF(data.cpf);
      });
      $("td:eq(1)", row).click(function() {
        // props.buscaCPF(data.cpf);
      });

      $("td:eq(3)", row).css("display", "");
      $("td:eq(1)", row).css("display", "");
      $("td:eq(0)", row).css("cursor", "pointer");
      $("td:eq(0)", row).css("display", "");
    },
    headerCallback: function(thead, data, display) {
      $("th:eq(0)", thead).css("display", "");
      $("th:eq(1)", thead).css("display", "");
      $("th:eq(3)", thead).css("display", "");
      $("td:eq(4)", thead).css("white-space", "normal");
    },
  });
  return (
    <div>
      <table
        id="table"
        className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer"
        style={{ width: 100 + "%" }}
        refs="main"
      ></table>
    </div>
  );
}

class RelatorioDiario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      dadosUser: [],
      alert: {
        status: "",
        message: "",
      },
      datas: [],
      uDia: "",
      total: "",
    };
    this.getEventos = this.getEventos.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.buscaCPF = this.buscaCPF.bind(this);
  }

  async getEventos() {
    await getDatas().then((response2) => {
      getRelatoriosDiario(response2.data[0].data_aplicacao).then((response) => {
        this.setState({
          dados2: response.data,
          style: "show",
          total: response.data[0],
        });
      });
      this.setState({
        datas: response2.data,
        uDia: response2.data[0].data_aplicacao,
      });
      getRelatoriosDiarioRapido(response2.data[0].data_aplicacao).then(
        (response) => {
          this.setState({ dados: response.data });
        }
      );
    });

    await getUsers().then((response2) => {
      this.setState({ dadosUser: response2.data });
    });
  }

  selectChange = (event) => {
    const id = event.target.value;
    getRelatoriosDiario(id).then((response) => {
      this.setState({ uDia: id, total: response.data[0] });
    });
    getRelatoriosDiarioRapido(id).then((response) => {
      this.setState({ dados: response.data });
    });
  };

  buscaCPF(teste) {
    let a = this.state.uDia;
    var e = window.btoa(teste + "/" + a);
    return (window.location = "/ListaVacinadosAplicador?" + e);
  }

  componentDidMount() {
    this.getEventos();
  }

  render() {
    var dados = this.state.uDia;
    var total = this.state.total;
    var date = dados.split("-");
    console.log(total);
    var data_br = date[2] + "/" + date[1] + "/" + date[0];
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dadosUser} />
          <Sidebar dados={this.state.dadosUser} />
          <br />
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <h1>Relatorio Diarios</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="/Social">
                    <i className="fa fa-dashboard" />
                    Início
                  </a>
                </li>
              </ol>
            </section>
            {/* Main content */}
            <div>
              <TextField
                required
                className="mb-3 ml-3 col-md-11 required"
                id="nivel"
                select
                fullWidth
                name="nivelAcesso"
                SelectProps={{
                  native: true,
                }}
                value={this.state.nivel}
                onChange={this.selectChange}
                label="Datas"
                variant="outlined"
              >
                <option key={0} value=""></option>
                {this.state.datas.map((option) => (
                  <option
                    key={option.data_aplicacao}
                    value={option.data_aplicacao}
                  >
                    {option.data_br}
                  </option>
                ))}
              </TextField>
            </div>
            <section className="content">
              <span>RELATORIO DO DIA {data_br}</span>
              <br />
              <span style={{ fontWeight: "bold" }}>
                {"Total: " + total?.Total}
                <br />
                {"Total 1º Dose: " + total?.Total1Dose}
                <br />
                {"Total 2º Dose: " + total?.Total2Dose}
                <br />
                {"Total 3º Dose: " + total?.Total3Dose}
                <br />
                {"Total 4º Dose: " + total?.Total4Dose}
                <br />
              </span>
              <DataTable
                style={this.state.style}
                cadastros={this.state.dados}
                buscaCPF={this.buscaCPF}
              />
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default RelatorioDiario;
