import React, { Component } from "react";
import Sidebar from "../Vacina/Sidebar";
import NavTop from "../Vacina/Navtop";
import Footer from "../components/Footer";
import { getListasTipos, getUsers, listaTipos } from "../services/dados"
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { logoExport } from '../services/logo'
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */
const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

export function dateHoje() {
    var data = new Date();
    var mes
    // Guarda cada pedaço em uma variável
    var dia = data.getDate();           // 1-31
    var mesn = data.getMonth();          // 0-11 (zero=janeiro)
    var ano4 = data.getFullYear();       // 4 dígitos
    var hora = data.getHours();          // 0-23
    //var hora = 11
    var min = data.getMinutes();        // 0-59
    if (mesn < 9) { mes = '0' + (mesn + 1) }
    else { mes = (mesn + 1) }
    if (hora < 9) { hora = '0' + hora }
    if (min < 9) { min = '0' + min }

    // Formata a data e a hora (note o mês + 1)
    var str_dataFormat = dia + '/' + mes + '/' + ano4 + ' ' + hora + ':' + min;
    return str_dataFormat
}

export class ListaTipo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            tipos: [],
            tipo: '',
            equipes: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getEventos() {
        listaTipos().then(response => {
            this.setState({ tipos: response.data })
        })
        getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    handleSubmit(event) {
        event.preventDefault();
        const { tipo } = this.state
        var tipoCIF = window.btoa(tipo)
        return (window.location = "ListaTiposData?" + tipoCIF)
    }

    render() {
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Busca Tipos de Cadastros
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início
              </a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <fieldset className="card">
                                    <div className="col-md-12 campos-pma card-body">
                                        <div className="col-md-12">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.tipos}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(option, value) => {

                                                    if (value != null) {
                                                        this.setState({
                                                            tipo: value.tipo,
                                                        })
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        id="tipo"
                                                        label="Tipo Cadastro"
                                                        required
                                                        name="tipo"
                                                        variant="outlined" />}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <button
                                    className="btn-pv col-md-12 text-center btn-primary"
                                    type="submit"
                                >
                                    BUSCAR
                </button>
                            </form>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>

        )
    }
}

var logo = logoExport

export const columns = [
    {
        title: 'Nome',
        width: "auto",
        data: 'nome'
    },
    {
        title: 'C.P.F.',
        width: "auto",
        data: 'cpf'
    },
    {
        title: 'Data de Nascimento',
        width: "auto",
        data: 'dt_nascimento'
    },
    {
        title: 'Instituição/Lotação/Bairro',
        width: "auto",
        data: 'bairro'
    },
];

export function DataTable(props) {
    var table = $('#table').DataTable({
        dom: 'Bfrtip',
        responsive: true,
        buttons: [
            {
                extend: 'pdf', className: 'btn-pdf', text: 'PDF',
                orientation: 'landscape',
                pageSize: 'A4',
                filename: props.title + dateHoje(),
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1, 2, 3],

                },
                footer: {
                    columns: [
                        'Left part',
                        { text: 'Right part', alignment: 'right' }
                    ]
                },
                customize: function (doc) {
                    var custom = doc.content[1].table
                    custom.widths = ['25%', '25%', '25%', '25%']
                    var objLayout = {};
                    objLayout['hLineWidth'] = function (i) { return .5; };
                    objLayout['vLineWidth'] = function (i) { return .5; };
                    objLayout['hLineColor'] = function (i) { return '#aaa'; };
                    objLayout['vLineColor'] = function (i) { return '#aaa'; };
                    objLayout['paddingLeft'] = function (i) { return 4; };
                    objLayout['paddingRight'] = function (i) { return 4; };
                    doc.content[1].layout = objLayout;
                    doc['header'] = (function () {
                        return {
                            columns: [
                                {
                                    alignment: 'center',
                                    image: logo,
                                    width: 220
                                },
                            ],
                            padding: 30
                        }
                    });
                    doc['footer'] = (function (page, pages,) {
                        // console.log(usuario)
                        return {
                            columns: [
                                {
                                    alignment: 'left',
                                    text: [dateHoje()]
                                },
                            ],
                            margin: 20
                        }
                    });
                }
            },
            {
                extend: 'excel', className: 'btn-excel', download: 'open', text: 'Excel',
                orientation: 'landscape',
                filename: "Lista_Geral" + dateHoje(),
                pageSize: 'A4',
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1, 2, 3]
                },
            },
            // {
            //     extend: 'print', className: 'btn-print', text: 'Imprimir',
            //     orientation: 'landscape',
            //     pageSize: 'A4',
            //     layout: 'border solid 1px',
            //     exportOptions: {
            //         columns: [0, 1, 2, 3]
            //     },
            // }
        ],

        data: props.cadastros,
        columns,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {
            $('td:eq(0)', row).click(
                function () {
                    props.buscaCPF(data.cpf);
                })
            $('td:eq(1)', row).click(
                function () {
                    props.buscaCPF(data.cpf);
                })


            $('td:eq(3)', row).css('display', '')
            $('td:eq(1)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
        },
        "headerCallback": function (thead, data, display) {

            $('th:eq(0)', thead).css('display', '')
            $('th:eq(1)', thead).css('display', '')
            $('th:eq(3)', thead).css('display', '')
            $('td:eq(4)', thead).css('white-space', 'normal')
        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}

export class ListaTiposData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            cadastros: [],
            usuario: '',
            tipos: '',
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        var idBusca = this.props.location.search
        var busca = idBusca.split('?')
        var id = busca[1]
        var CIFid = window.atob(id)
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data, usuario: response2.data.nome })
        });
        getListasTipos(id).then(response2 => {
            this.setState({ listas: response2.data, tipos: CIFid })
        });
    }

    async buscaCPF(cpf) {
        //var bairros = window.btoa(bairro)
        var cpfc = Buffer.from(cpf).toString('base64')
        return (window.location = "/CadastroCPF?" + cpfc)

    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Lista Entregas
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <DataTable
                                cadastros={this.state.listas}
                                title={this.state.tipos}
                                usuario={this.state.usuario}
                                buscaCPF={this.buscaCPF}
                            />
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}