import { TextField } from "@material-ui/core";
import React, { Component } from "react";
import { apareceAlertAgendamento, Toast } from "../components/Alert";
import { apiBusca } from "../services/api";
import { buscaCadastrosDose, buscaLocais, getHorariosDisponiveis, human2ts, token } from "../services/dados"
import { dateHoje, dateHojeEN } from "../Vacina/ListaNaoVacinados2";
const { cpf } = require('cpf-cnpj-validator');
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            horario: [],
            horarios: [],
            local: [],
            locais: [],
            data: '',
            alert: {
                status: '',
                message: ''
            },
            termo: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
        this.busca = this.busca.bind(this)
        this.buscaCPF = this.buscaCPF.bind(this)
    }

    async getEventos() {

    }

    busca(e) {
        let data = e.split('-')
        let str_dataFormat = data[2] + '-' + data[1] + '-' + data[0]
        let unidade = this.state.local
        getHorariosDisponiveis(str_dataFormat, unidade).then(response => {
            this.setState({ horarios: response.data })
        })
        this.setState({ data: e })
    }

    buscaCPF = event => {
        var id = event.target.value;
        let validCPF = cpf.isValid(id)
        if (validCPF === false) {
            alert("Verifique o CPF digitado ele está inválido")
            this.setState({ cpf: '' })
        }
        else {
            buscaCadastrosDose(id).then(response => {

                if (response.data.agendado === 1) {
                    alert("Você já possue um agendamento, nao é possivel realizar outro.")
                    let caminho = window.btoa(id)
                    return (window.location = "/ProtocoloAgendamento?" + caminho);
                }
                else if (human2ts(response.data.dose3dtEN) <= human2ts(dateHojeEN())) {
                    buscaLocais().then(response => {
                        this.setState({ locais: response.data })
                    })
                }
                else if (human2ts(response.data.dose3dtEN) > human2ts(dateHojeEN())) {
                    alert("3º Dose ainda não liberada ou 2º dose ainda não Aplicada - Data provável de aplicação: " + response.data.datadose3)
                    this.setState({ locais: [] })
                }
            })

        }
    }


    handleSubmit(event) {
        event.preventDefault();
        const { cpf, local, data, horario } = this.state
        var raw = { cpf, local, data, horario }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        apiBusca.post("/cadastros/postAgendamento", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                let propis = this.props

                if (this.state.alert.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: this.state.alert.message
                    }).then(function () {
                        let caminho = window.btoa(cpf)
                        apareceAlertAgendamento(caminho)
                    })

                }
                else if (this.state.alert.status === 201) {
                    Toast.fire({
                        icon: 'error',
                        title: this.state.alert.message
                    })
                }
            })

            .catch(error => {
                this.setState({
                    alert: {
                        status: 201,
                        message: "Contate o Desenvolvedor do Sistema! insertUsuarios()->BAD_CONFIG"
                    }
                })
            })
        event.target.reset();
    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        return (
            <div className="home">
                <div className='col-md-12'>
                    <h1 className="text-center">
                        AGENDAMENTO #IMUNIZAANÁPOLIS
                    </h1>
                    <hr />
                    <div className="pma-text-center mb-3">
                        <form id="wizard" onSubmit={this.handleSubmit}>
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Informações importantes
                                </legend>
                                <div className='btn-danger text-center'>
                                    <h2>Agendamento dose de reforço (3ª dose)</h2>

                                    Este sistema é específico para realizar o agendamento da dose de reforço (3ª dose). A dose de reforço só poderá ser feita 6 meses <b>após a APLICAÇÃO DA 2ª DOSE</b>.
                                    O agendamento só estará disponível após esse período. Tenha em mãos o número do seu CPF.
                                </div>
                                <div className="col-md-4 alert-danger" id="resposta"></div>
                                <div className="col-md-12 campos-pma card-body">
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-11"
                                        id="cpf"
                                        fullWidth
                                        name="cpf"
                                        label="C.P.F."
                                        variant="outlined"
                                        type="text"
                                        onBlur={this.buscaCPF}
                                        onChange={e => this.setState({ cpf: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        InputProps={{
                                            disabled: this.state.cpf === '' || this.state.cpf === undefined ? true : false
                                        }}
                                        className="mb-3 ml-3 col-md-11 required"
                                        id="local"
                                        select
                                        fullWidth
                                        name="local"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={e => this.setState({ local: e.target.value })}
                                        label="Local de Agendamento"
                                        variant="outlined"
                                    >
                                        <option key={0} value=''></option>
                                        {this.state.locais.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.unidade}
                                            </option>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        InputProps={{
                                            disabled: this.state.local[0] === '' || this.state.local[0] === undefined ? true : false
                                        }}
                                        className="ml-3 mb-3 col-md-11"
                                        id="dt_agend"
                                        fullWidth
                                        name="dt_agend"
                                        label="Data Agendamento"
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onBlur={e => this.busca(e.target.value)}
                                        onChange={e => this.setState({ data: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        InputProps={{
                                            disabled: this.state.data === '' || this.state.data === undefined ? true : false
                                        }}
                                        className="mb-3 ml-3 col-md-11 required"
                                        id="horario"
                                        select
                                        fullWidth
                                        name="horario"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={e => this.setState({ horario: e.target.value })}
                                        label="Horario"
                                        variant="outlined"
                                    >
                                        <option key={0} value=''></option>
                                        {this.state.horarios.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.horarios}
                                            </option>
                                        ))}
                                    </TextField>

                                </div>
                            </fieldset>
                            <button
                                className="btn-pv col-md-12 text-center btn-success"
                                type="submit"
                            >
                                Agendar
                            </button>
                        </form>
                    </div>
                </div >
            </div >
        );
    }
}
export default Content;
