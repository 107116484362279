import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import {
  getUsers,
  getAtualizaVAC,
  getUnidades,
  token,
  removeVacina,
} from "../services/dados";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

function apareceAlert() {
  setTimeout(() => {
    return window.history.back();
  }, 1 * 1000);
}

class Reacao extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: "",
        message: "",
      },
      nome: "",
      cpf: "",
      ocorrencia: "",
      dadosVac: "",
      equipe: "",
      lote: "",
      id_repasse: "",
      equipes: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
    this.removerVacina = this.removerVacina.bind(this);
  }

  getEventos() {
    var id = this.props.location.search;
    getAtualizaVAC(id).then((response) => {
      this.setState({
        dadosVac: response.data,
        cpf: response.data.cpf,
        nome: response.data.nome,
        repasseANTG: response.data.fk_repasse,
        qtdANTG: response.data.qtd,
        equipeANTG: response.data.fk_vacina,
        idVacina: response.data.id,
      });
    });
    getUsers().then((response2) => {
      this.setState({ dados: response2.data });
    });
    getUnidades().then((response2) => {
      this.setState({ equipes: response2.data });
    });
  }

  componentDidMount() {
    this.getEventos();
  }

  removerVacina() {
    var cpf = this.state.cpf;
    var nome = this.state.nome;
    var id = this.state.idVacina;
    const MySwal = Swal.fire({
      title: "Tem certeza?",
      text:
        "Tem certeza que deseja remover a vacina para: " +
        nome +
        " - " +
        cpf +
        " Essa operação nao poderá ser desfeita, vai ser gravado em LOG",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeVacina(id).then((response2) => {
          this.setState({
            alert: {
              status: response2.data.response.status,
              message: response2.data.response.result,
            },
          });
          //this.setState({ setOpen: true });
          let propis = this.props;
          if (this.state.alert.status === 200) {
            Toast.fire({
              icon: "success",
              title: this.state.alert.message,
            }).then(function() {
              apareceAlert(propis);
            });
          } else if (this.state.alert.status === 201) {
            Toast.fire({
              icon: "error",
              title: this.state.alert.message,
            });
          }
        });
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      idVacina,
      cpf,
      nome,
      equipe,
      id_repasse,
      lote,
      repasseANTG,
      equipeANTG,
      qtdANTG,
    } = this.state;
    var raw = {
      idVacina,
      cpf,
      nome,
      equipe,
      id_repasse,
      lote,
      repasseANTG,
      equipeANTG,
      qtdANTG,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // apiBusca.post("/cadastros/trocaVacina", requestOptions)
    //     .then(response => {
    //         this.setState({
    //             alert: {
    //                 status: response.data.response.status,
    //                 message: response.data.response.result
    //             }
    //         })
    //         let propis = this.props
    //         if (this.state.alert.status === 200) {
    //             Toast.fire({
    //                 icon: 'success',
    //                 title: this.state.alert.message
    //             }).then(function () {
    //                 apareceAlert(propis)
    //             })

    //         }
    //         else if (this.state.alert.status === 201) {
    //             Toast.fire({
    //                 icon: 'error',
    //                 title: this.state.alert.message
    //             })
    //         }
    //     })
  }

  render() {
    var dados = this.state.dadosVac;
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <fieldset className="card">
                  <legend className="card-header text-left legend-pma faq-btn-link">
                    Informações importantes
                  </legend>
                  <div className="text-center card btn-warning">
                    <b style={{ color: "red" }}>
                      ATENÇÃO
                      <br />
                    </b>
                    Só remova a vacina se você tiver a certeza de que ela esteja
                    errada.
                    <br />
                    No log do sistema será adcionado que você removei essa
                    vacina.
                    <br />
                    ESSA ALTERAÇÃO SERÁ REGISTRADA EM LOG E SERÁ LANÇADA <br />
                    UMA OCORRENCIA AO APLICADOR E AO USUARIO QUE FEZ A ALTERAÇÃO
                    <br />
                    <b>SOMENTE REMOVA SE FOR REALMENTE NECESSARIO</b>
                  </div>
                  <legend className="card-header text-left legend-pma faq-btn-link mt-3">
                    Dados da Vacina:
                  </legend>
                  <div className="col-md-12 card-body">
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        CPF: <b>{dados.cpf}</b>
                      </div>
                      <div className="col-md-6 mt-3">
                        NOME: <b>{dados.nome}</b>
                      </div>
                      <div className="col-md-12 mt-3">
                        APLICADOR: <b>{dados.aplicador}</b>
                      </div>
                      <div className="col-md-6 mt-3">
                        VACINA: <b>{dados.vacina}</b>
                      </div>
                      <div className="col-md-6 mt-3">
                        BAIXA: <b>{dados.lote}</b>
                      </div>
                      <div className="col-md-12 mt-3">
                        USUARIO BAIXA: <b>{dados.usuario_baixa}</b>
                      </div>
                      <legend className="card-header text-left legend-pma faq-btn-link mt-3">
                        Dados para alteração Vacina:
                      </legend>
                      <div className="col-md-12">
                        <Autocomplete
                          id="combo-box-demo"
                          options={this.state.equipes}
                          getOptionLabel={(option) =>
                            option.nome +
                            " - " +
                            option.qtd +
                            " doses - " +
                            option.vacina +
                            " - " +
                            option.lote
                          }
                          onChange={(option, value) => {
                            if (value != null) {
                              this.setState({
                                equipe: value.id,
                                lote: value.lote,
                                id_repasse: value.id_repasse,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              id="equipe"
                              label="Aplicador"
                              required
                              name="equipe"
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      id="cadastro_vacina"
                      className="btn-pv col-md-4 ml-3 text-center pv-btn-primary"
                      type="submit"
                    >
                      Atualizar Vacina
                    </button>
                    <Button
                      onClick={this.removerVacina}
                      className="btn-pv col-md-4 ml-3 text-center btn-danger"
                      style={{ color: "white", fontWeight: "600" }}
                    >
                      Remover Vacina
                    </Button>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Reacao;
