import React, { Component } from "react";
import Sidebar from "../Vacina/Sidebar";
import NavTop from "../Vacina/Navtop";
import Footer from "../components/Footer";
import { buscaIDMotorista, getUsers } from "../services/dados"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField } from "@material-ui/core";

export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alertin severity={classe}>
                    {param.texto}
                </Alertin>
            </Snackbar>
        </div>
    );
}
export function Alertin(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function apareceAlert() {
    setTimeout(() => {
        return (window.location = "/ListaMotoristas");
    }, 1 * 1000)
}

class EntregaMotorista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        buscaIDMotorista().then(response2 => {
            this.setState({ dados: response2.data })
        });
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);
        let id = data.get('aplicador')
        return (window.location = "/ListaBairroAplicador?" + id)

    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Lista Entregas
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <TextField
                                    required
                                    className="mb-3 ml-3 col-md-11"
                                    id="aplicador"
                                    select
                                    fullWidth
                                    name="aplicador"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={this.state.motorista}
                                    onChange={this.motoristaChange}
                                    label="Aplicador"
                                    variant="outlined"
                                >
                                    <option key={0} value=''></option>
                                    {this.state.dados.map((option, a) => (
                                        <option key={a} value={option.id}>
                                            {option.nome} - {option.agendados} Vacinas para Aplicar
                                        </option>
                                    ))}
                                </TextField>
                                <button
                                    className="btn-pv col-md-12 text-center btn-success"
                                    type="submit"
                                >
                                    Consultar Lista
                </button>
                            </form>
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}
export default EntregaMotorista