import React, { Component } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { Toast, apareceAlert } from "../components/Alert"
import { getUsers, token, getCadastrados, getBairros, getVagas, getEditaCadastrosCPF } from "../services/dados"
/* eslint eqeqeq: "off", curly: "error" */
const { cpf } = require('cpf-cnpj-validator');
const $ = require('jquery');



export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe
  var classe
  if (status === 200) {
    classe = "success"
  } else if (status === 201 || status === 110) {
    classe = "error"
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>
          {param.texto}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Cadastro extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: '',
        message: ''
      },
      nome: '',
      telefone: '',
      cpf: '',
      dt_nascimento: '',
      idade: '',
      rg: '',
      nome_mae: '',
      sexod: '',
      sexo: { label: '', value: '' },
      cns: '',
      pne: { value: '' },
      tipo_pne: '',
      cep: '',
      rua: '',
      numero: '',
      complemento: '',
      quadra: '',
      lote: '',
      ponto_referencia: '',
      bairro: '',
      dtNascimento: '',
      bairros: [],
      vagos: '',
      limite: '',
      idadeCad: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  async getEventos() {
    getBairros().then(response => {
      this.setState({ bairros: response.data })
    })
    getUsers().then(response2 => {
      this.setState({ dados: response2.data })
    });
    var id = this.props.location.search
    await getEditaCadastrosCPF(id).then(response3 => {
      this.setState({
        dadosCad: response3.data,
        nome: response3.data.nome,
        telefone: response3.data.telefone,
        cpf: response3.data.cpf,
        dt_nascimento: response3.data.dt_nascimento,
        idade: response3.data.idade,
        rg: response3.data.rg,
        nome_mae: response3.data.nome_mae,
        sexo: { label: response3.data.sexoMin, value: response3.data.sexoMin },
        cns: response3.data.cns,
        pne: { value: response3.data.pne },
        pned: response3.data.pne,
        sexod: response3.data.sexoMin,
        tipo_pne: response3.data.tipo_pne,
        cep: response3.data.cep,
        rua: response3.data.rua,
        numero: response3.data.numero,
        complemento: response3.data.complemento,
        quadra: response3.data.quadra,
        lote: response3.data.lote,
        pontoreferencia: response3.data.ponto_referencia,
        bairro: response3.data.bairro,
        dtNascimento: response3.data.dtNascimento

      })
    })
    await getVagas().then(response2 => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1],
        idadeCad: response2.data[3]
      })
    });
  }

  validPhone(phone) {
    const isCelular = phone.length >= 14;
    if (isCelular === false) {
      $('#telefone').addClass('telefoneErr');
      $('#cadastro_vacina').attr('disabled', true);
      $('#cadastro_vacina').addClass('btn-danger');
      $('#cadastro_vacina').removeClass('btn-primary');
    } else {
      $('#telefone').removeClass('telefoneErr')
      $('#cadastro_vacina').removeAttr('disabled', false);
      $('#cadastro_vacina').removeClass('btn-danger');
      $('#cadastro_vacina').addClass('btn-primary');
    }
  }

  componentDidMount() {
    this.getEventos();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false })
      var cpf = this.state.cpf
      var e = window.btoa(cpf)
      return (window.location = "/CadastroCpf?" + e);
    }, 1 * 1000)
  }

  calculaIdade(dataNasc) {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
    if (mesAtual < mesNasc) {
      idade--;
    } else {
      //Se estiver no mes do nascimento, verificar o dia
      if (mesAtual == mesNasc) {
        if (new Date().getDate() < diaNasc) {
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
          idade--;
        }
      }
    }
    return idade;
  }
  buscadtNascimento = event => {
    var data
    let idadeCad = this.state.idadeCad.qtd
    if (event.target === undefined) {
      data = event
    } else {
      data = event.target.value
    }
    var date1 = data.split("-")
    date1 = date1[2] + "/" + date1[1] + "/" + date1[0]
    var teste = this.calculaIdade(date1)
    this.setState({ idade: teste })
    if (teste >= idadeCad) { }
    else {
      alert("Idade de " + teste + " anos, menor que o permitido!")
      setTimeout(() => {
        return (window.location = "/Cadastro");
      }, 100)
    }
  }

  buscaCpf = event => {
    var id = event.target.value;
    let validCPF = cpf.isValid(id)
    if (validCPF === false) {
      alert("Verifique o CPF digitado ele está inválido")
      this.setState({ cpf: '' })
      setTimeout(() => {
        $("#resposta").html('');
      }, 1 * 100)
    }
    else {
      getCadastrados(id).then(response => {
        console.log(response.data.data_nasc != null)
        if (response.data.data_nasc != null) {
          this.setState({ nome: response.data.nome, dtNascimento: response.data.data_nasc })
          this.buscadtNascimento(this.state.dtNascimento)
          $('#telefone').focus()
          $('#nome').attr('readonly', true);
          $('#dataNasc').attr('readonly', true);
        }
        if (response.data.cadastrado >= 1) {
          alert("Usuario já Cadastrado, Você será redirecionado para o perfil do cadastrado")
          var e = window.btoa(id)
          this.setState({ cpf: '' })
          setTimeout(() => {
            $("#resposta").html('');
          }, 1 * 1000)
          return (window.location = "/CadastroCpf?" + e)
        } else {
          setTimeout(() => {
            $("#resposta").html('');
          }, 1 * 100)
        }
      })
    }
  }

  valueCPF = (event) => {
    this.setState({ cpf: event.target.value })
  };

  async handleSubmit(event) {
    event.preventDefault();
    const { nome, telefone, pned, rg, cep, numero, complemento, quadra, lote, pontoreferencia, bairro, sexod, cns, nome_mae } = this.state
    const logradouro = this.state.rua
    var cpfedit = this.state.cpf
    //const pne = this.state.pne.value
    //const sexod = this.state.sexo.value
    var raw = { nome, telefone, rg, pned, cep, logradouro, numero, complemento, quadra, lote, pontoreferencia, bairro, sexod, cns, nome_mae }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token)

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    await apiBusca.put("/cadastros/formCadAltera/" + this.state.cpf, requestOptions)
      .then(response => {
        this.setState({
          alert: {
            status: response.data.response.status,
            message: response.data.response.result
          }
        })
        let propis = this.props
        if (this.state.alert.status === 200) {
          Toast.fire({
            icon: 'success',
            title: this.state.alert.message
          }).then(function () {
            apareceAlert(propis, cpfedit)
          })

        }
        else if (this.state.alert.status === 201) {
          Toast.fire({
            icon: 'error',
            title: this.state.alert.message
          })
        }
      })

      .catch(error => {
        this.setState({
          alert: {
            status: 201,
            message: "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG"
          }
        })
      })

  }

  render() {
    var maximo = this.state.limite.qtd
    var totalAtual = this.state.vagos.qtd
    var alertMsg = this.state.alert
    const sexo = [
      {
        value: 'masculino',
        label: 'Masculino',
      },
      {
        value: 'feminino',
        label: 'Feminino',
      },
      {
        value: 'outros',
        label: 'Outros',
      },
    ];
    const valores = [
      {
        value: 'true',
        label: 'Sim',
      },
      {
        value: 'false',
        label: 'Não',
      },
    ];

    if (totalAtual > maximo) {
      return (
        <div className="skin-blue bg-light sidebar-mini" id="panel">
          <div className="wrapper">
            <NavTop dados={this.state.dados} />
            <Sidebar dados={this.state.dados} />
            <br />
            <div className="content-wrapper">
              {/* Main content */}
              <section className="content btn-danger col-md-12">
                <div style={{ fontWeight: "bolder", fontSize: "70px" }} className="text-center">
                  Sistema Indisponível
                </div>
              </section>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status} />
                <fieldset className="card">
                  <legend className="card-header text-left legend-pma faq-btn-link">
                    Informações importantes
              </legend>
                  <div id='resposta'></div>
                  <div className="col-md-12 campos-pma card-body">
                    <TextField
                      disabled
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="cpf"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="cpf"
                      label="C.P.F."
                      variant="outlined"
                      value={this.state.cpf}
                      type="text"
                      // onBlur={this.buscaCpf}
                      onChange={e => this.setState({ cpf: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="nome"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="nome"
                      label="Nome"
                      variant="outlined"
                      type="text"
                      value={this.state.nome}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={e => this.setState({ nome: e.target.value })}
                    />
                    <TextField
                      required
                      disabled
                      className="mb-3 ml-3 col-md-5"
                      id="dataNasc"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="dtNascimento"
                      label="Data de Nascimento"
                      variant="outlined"
                      type="date"
                      value={this.state.dtNascimento}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={e => this.setState({ dtNascimento: e.target.value })}
                    />
                    <TextField
                      required
                      disabled
                      className="ml-3 mb-3 col-md-6"
                      id="idade"
                      fullWidth
                      name="idade"
                      label="Idade"
                      variant="outlined"
                      type="number"
                      value={this.state.idade}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <Tooltip
                      title="Digitar DDD e numero com 9 Ex. (62) 9 9999-9999"
                      placement="top"
                    >
                      <TextField
                        placeholder="Ex. (62) 9 9999-9999"
                        required
                        className="mb-3 ml-3 col-md-5"
                        id="telefone"
                        InputProps={{
                          readOnly: false
                        }}
                        fullWidth
                        name="telefone"
                        label="Telefone"
                        variant="outlined"
                        type="text"
                        value={this.state.telefone}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                        onKeyDown={e => this.validPhone(e.target.value)}
                        onChange={e => this.setState({ telefone: e.target.value })}
                      />
                    </Tooltip>

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="rg"
                      fullWidth
                      name="rg"
                      label="RG"
                      variant="outlined"
                      type="text"
                      value={this.state.rg}
                      onChange={e => this.setState({ rg: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="nome_mae"
                      fullWidth
                      name="nome_mae"
                      label="Nome da Mãe"
                      variant="outlined"
                      type="text"
                      value={this.state.nome_mae}
                      onChange={e => this.setState({ nome_mae: e.target.value })}
                    />

                    <TextField

                      className="ml-3 mb-3 col-md-6"
                      id="cns"
                      fullWidth
                      name="cns"
                      label="Nº CNS"
                      variant="outlined"
                      type="text"
                      value={this.state.cns}
                      onChange={e => this.setState({ cns: e.target.value })}
                    />

                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5 required"
                      id="sexo"
                      select
                      fullWidth
                      name="sexo"
                      value={this.state.sexo.value}
                      SelectProps={{
                        native: true,
                      }}
                      onChange={e => this.setState({ sexod: e.target.value, sexo: { value: e.target.value } })}
                      label="Sexo"
                      variant="outlined"
                    >
                      <option key={0} value=''></option>
                      {sexo.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      required
                      className="mb-3 ml-3 col-md-6 required"
                      id="pne"
                      select
                      fullWidth
                      name="pne"
                      value={this.state.pne.value}
                      SelectProps={{
                        native: true,
                      }}
                      onChange={e => this.setState({ pned: e.target.value, pne: { value: e.target.value } })}
                      label="Acamado"
                      variant="outlined"
                    >
                      <option key={0} value=''></option>
                      {valores.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>

                    {/* 
                    <TextField
                      required={this.state.pne === 'sim'}
                      className="ml-3 mb-3 col-md-6"
                      id="pnetipo_pne"
                      fullWidth
                      name="tipo_pne"
                      label="Descrição PNE"
                      variant="outlined"
                      type="text"
                      onChange={e => this.setState({ tipo_pne: e.target.value })}
                    /> */}

                  </div>
                </fieldset>
                <fieldset className="card ">
                  <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                    Endereço
              </legend>
                  <br />
                  <div className="col-md-12 campos-pma">
                    <div className="col-md-6">
                      <TextField
                        id="cep"
                        fullWidth
                        required
                        name="cep"
                        label="CEP"
                        variant="outlined" type="text"
                        value={this.state.cep}
                        onChange={e => this.setState({ cep: e.target.value })}
                        onBlur={this.cepBlur}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Digitar a Rua, Av., Trav. não deixar em branco ou com 0"
                        placement="top"
                      >
                        <TextField
                          id="rua"
                          required
                          fullWidth
                          placeholder="Ex: Rua, Av, Trav."
                          name="logradouro"
                          label="Logradouro"
                          variant="outlined" type="text"
                          value={this.state.rua}
                          onChange={e => this.setState({ rua: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Se não Possuir número digite 0"
                        placement="top"
                      >
                        <TextField
                          id="numero"
                          required
                          fullWidth
                          name="numero"
                          placeholder="Apenas número, se não possuir deixe com 0"
                          label="Numero"
                          variant="outlined" type="number"
                          value={this.state.numero}
                          onChange={e => this.setState({ numero: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não Digitar nome de rua, quadra, lote, bairro"
                        placement="top"
                      >
                        <TextField
                          id="complemento"
                          fullWidth
                          name="complemento"
                          placeholder="Bl. Apt. Casa"
                          label="Complemento"
                          variant="outlined" type="text"
                          value={this.state.complemento}
                          onChange={e => this.setState({ complemento: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não digitar QD. ,Q. ,Quadra, etc"
                        placement="top"
                      >
                        <TextField
                          id="quadra"
                          fullWidth
                          {...this.state.numero == 0 ? { required: true } : { required: false }}
                          name="quadra"
                          placeholder="Não digitar QD. ,Q. ,Quadra, etc"
                          label="Quadra"
                          variant="outlined" type="text"
                          value={this.state.quadra}
                          onChange={e => this.setState({ quadra: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não digitar Lt., L., Lote, etc"
                        placement="top"
                      >
                        <TextField
                          id="lote"
                          fullWidth

                          {...this.state.numero == 0 ? { required: true } : { required: false }}
                          name="lote"
                          placeholder="Não digitar Lt., L., Lote, etc"
                          label="Lote"
                          variant="outlined" type="text"
                          value={this.state.lote}
                          onChange={e => this.setState({ lote: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Digitar apenas ponto de referência, se existir"
                        placement="top"
                      >
                        <TextField
                          id="pontoreferencia"
                          fullWidth
                          name="pontoreferencia"
                          placeholder="Digitar apenas ponto de referência, se existir"
                          label="Ponto de Referência"
                          variant="outlined" type="text"
                          value={this.state.pontoreferencia}
                          onChange={e => this.setState({ pontoreferencia: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <TextField
                        id="bairro"
                        required
                        fullWidth
                        name="bairro"
                        placeholder="Bairro"
                        label="Bairro"
                        variant="outlined" type="text"
                        value={this.state.bairro}
                        onChange={e => this.setState({ bairro: e.target.value })}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                    <button
                      id="cadastro_vacina"
                      className="btn-pv col-md-12 text-center pv-btn-success"
                      type="submit"
                    >
                      Atualizar
                </button>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Cadastro;
