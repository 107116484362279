import React from "react";
import { cdnURL } from "../services/api"
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

export default function Content() {
  return (
    <div className="home">
      <div className="col-md-12 text-center mt-2">
        <a href="https://www.anapolis.go.gov.br/vacinacao-em-anapolis/" target="_blank" rel="noopener noreferrer">
          <img alt='vacina' src={cdnURL + 'bannervacina.jpg'} className="img img-responsive" width='100%' />
        </a>
      </div>
    </div >
  );
}
