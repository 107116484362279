import React, { Component } from "react";
import { TextField, Tooltip, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Footer from "../components/Footer";
import api, { apiBusca } from "../services/api";
import { token, getCadastrados, getBairros, getVagasCidadao } from "../services/dados"
import NavTop from "../Cadastro/Navtop";
import { apareceAlertProtocolo, Toast } from "../components/Alert";
// import ImageUploading from "react-images-uploading";
/* eslint eqeqeq: "off", curly: "error" */
const { cpf } = require('cpf-cnpj-validator');
const $ = require('jquery');

export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe
  var classe
  if (status === 200) {
    classe = "success"
  } else if (status === 201 || status === 110) {
    classe = "error"
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>
          {param.texto}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Cadastro extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: '',
        message: ''
      },
      cpf: '',
      tipRes: '',
      quadra: '',
      lote: '',
      pontoreferencia: '',
      bairros: [],
      idade: '',
      nome: '',
      dtNascimento: '',
      complemento: '',
      cep: '',
      tipo_pne: '',
      numero: '',
      vagos: '',
      limite: '',
      idadeCad: '',
      cnh: true,
      nacionalidade: '',
      vacinado: false,
      imgcpf: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      imgrgFrente: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      imgrgVerso: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      imgcompresid: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      imgsus: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      imgcnhV: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      imgcnh: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
    this.uploadFile = this.uploadFile.bind(this)
    this.deleteArquivos = this.deleteArquivos.bind(this)
    this.validAnexos = this.validAnexos.bind(this)
    this.buscadtNascimento = this.buscadtNascimento.bind(this)
  }



  async getEventos() {
    getBairros().then(response => {
      this.setState({ bairros: response.data })
    })
    await getVagasCidadao().then(response2 => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1],
        idadeCad: response2.data[3]
      })
    });
  }

  validPhone(phone) {
    const isCelular = phone.length >= 14;
    if (isCelular === false) {
      $('#telefone').addClass('telefoneErr');
      $('#cadastro_vacina').attr('disabled', true);
      $('#cadastro_vacina').addClass('btn-danger');
      $('#cadastro_vacina').removeClass('btn-primary');
    } else {
      $('#telefone').removeClass('telefoneErr')
      $('#cadastro_vacina').removeAttr('disabled', false);
      $('#cadastro_vacina').removeClass('btn-danger');
      $('#cadastro_vacina').addClass('btn-primary');
    }
  }

  componentDidMount() {
    this.getEventos();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false })
      var cpf = this.state.cpf
      var e = window.btoa(cpf)
      return (window.location = "/CadastroCpf?" + e);
    }, 1 * 1000)
  }

  calculaIdade(dataNasc) {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
    if (mesAtual < mesNasc) {
      idade--;
    } else {
      //Se estiver no mes do nascimento, verificar o dia
      if (mesAtual == mesNasc) {
        if (new Date().getDate() < diaNasc) {
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
          idade--;
        }
      }
    }
    return idade;
  }
  buscadtNascimento = event => {
    var data
    if (event.target === undefined) {
      data = event
    } else {
      data = event.target.value
    }
    var date1 = data.split("-")
    date1 = date1[2] + "/" + date1[1] + "/" + date1[0]
    var teste = this.calculaIdade(date1)
    this.setState({ idade: teste })
    // if (teste >= idadeCad) { }
    // else {
    //   alert("Idade de " + teste + " anos, menor que o permitido!")
    //   this.limpaState()
    //   setTimeout(() => {
    //     return (window.location = "/Cadastrar");
    //   }, 100)
    // }
  }

  cepBlur = event => {
    let cep = event.target.value
    if (cep > '75999-999' || cep < '74999-999') {
      alert("Cidade não permitida para Cadastro! Procure a unidade do seu Municipio")
      setTimeout(() => {
        return (window.location = "/Cadastrar");
      }, 100)
    }
  }

  buscaCpf = event => {
    var id = event.target.value;
    let validCPF = cpf.isValid(id)
    if (validCPF === false) {
      this.limpaState()
      alert("Verifique o CPF digitado ele está inválido")
      this.setState({ cpf: '' })
    }
    else {
      getCadastrados(id).then(response => {
        if (response.data.data_nasc != null) {
          this.setState({ nome: response.data.nome, dtNascimento: response.data.data_nasc })
          this.buscadtNascimento(this.state.dtNascimento)
          $('#telefone').on('focus')
          $('#nome').attr('readonly', true);
          $('#dataNasc').attr('readonly', true);
        }
        if (response.data.cadastrado >= 1) {
          alert("Usuario já Cadastrado, não é possivel cadastrar novamente!")
          this.setState({ cpf: '' })
          setTimeout(() => {
            $("#resposta").html('');
          }, 1 * 1000)
          return (window.location = "/Cadastrar")
        } else {
          setTimeout(() => {
            $("#anexos").css('display', "block");
          }, 1 * 100)
        }
      })
    }
  }

  valueCPF = (event) => {
    this.setState({ cpf: event.target.value })
  };
  validAnexos() {
    if (this.state.cnh === false) {
      if ($('#cpfUp').val() === '' || $('#cpfUp').val() === null) { $('#cpfUp').focus(); return false } else {
        if ($('#rgFrente').val() === '' || $('#rgFrente').val() === null) { $('#rgFrente').focus(); return false } else {
          if ($('#rgVerso').val() === '' || $('#rgVerso').val() === null) { $('#rgVerso').focus(); return false } else {
            if ($('#compresid').val() === '' || $('#compresid').val() === null) { $('#compresid').focus(); return false } else {
              return true
            }
          }
        }
      }
    }
    else {
      if ($('#cnh').val() === '' || $('#cnh').val() === null) { $('#cnh').focus(); return false } else {
        if ($('#cnhV').val() === '' || $('#cnhV').val() === null) { $('#cnhV').focus(); return false } else {
          if ($('#compresid').val() === '' || $('#compresid').val() === null) { $('#compresid').focus(); return false } else {
            return true
          }
        }
      }
    }
  }

  mCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
  }

  limpaState() {
    this.setState({
      cpf: '',
      // tipRes: '',
      // quadra: '',
      // lote: '',
      // pontoreferencia: '',
      // idade: '',
      // nome: '',
      // dtNascimento: '',
      // complemento: '',
      // cep: '',
      // tipo_pne: '',
      // numero: '',
      // vagos: '',
      // limite: '',
      // cnh: false,
      // nacionalidade: '',
      // vacinado: false,
      // imgcpf: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgrgFrente: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgrgVerso: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgcompresid: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgsus: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgcnhV: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
    })
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.validAnexos() === false) {
      alert("ANEXOS NÃO PODEM FICAR SEM ARQUIVO")
    } else {
      const { cpf, nome, telefone, dtNascimento, idade, rg, pne, tipo_pne, cep, numero, complemento, quadra, lote, pontoreferencia, bairro, sexo, cns, nome_mae, nacionalidade } = this.state
      const logradouro = $("#rua").val()
      var raw = { cpf, nome, telefone, dtNascimento, idade, rg, pne, tipo_pne, cep, logradouro, numero, complemento, quadra, lote, pontoreferencia, bairro, sexo, cns, nome_mae, nacionalidade }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token)

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      await apiBusca.post("/cadastros/formCadCaminhoneiros", requestOptions)
        .then(response => {
          this.setState({
            alert: {
              status: response.data.response.status,
              message: response.data.response.result
            }
          })
          let propis = this.props
          if (this.state.alert.status === 200) {
            this.limpaState()
            Toast.fire({
              icon: 'success',
              title: this.state.alert.message
            }).then(function () {
              apareceAlertProtocolo(cpf)
            })

          }
          else if (this.state.alert.status === 201) {
            Toast.fire({
              icon: 'error',
              title: this.state.alert.message
            })
          }
        })

        .catch(error => {
          this.setState({
            alert: {
              status: 201,
              message: "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG"
            }
          })
        })

    }
  }

  //uploads de Arquivos
  handleOnChange = e => {
    const file = e.target.files;
    const tipo = e.target.name
    if (file.length === 0) {
      return; // se não selecionar nenhum file
    }
    //funcao de resize
    this.uploadFile(file[0], tipo);
    // resize(image, callback);
    e.target.files = null;
  };

  async uploadFile(file, tipo) {
    var cpf = this.state.cpf
    const formData = new FormData();
    formData.append('fileCHEFE', file)
    formData.append('tipo', tipo)
    var imgUpload = "img" + tipo
    return await api.post('/upload/files/' + cpf, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      this.setState({ [imgUpload]: response.data.response.result })
    })
  }

  deleteArquivos = ev => {
    ev.preventDefault();
    var teste = ev.target.value.split("https://cdn.anapolis.go.gov.br/img/vacina-tst/")

    var deletar = window.btoa(teste[1])
    let img = ev.target.name
    return api.post('/upload/deleteFiles/' + deletar, {
    }).then(response => {

      this.setState({
        [img]: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png'
      })
    })
  }

  render() {
    var alertMsg = this.state.alert
    const sexo = [
      {
        value: 'masculino',
        label: 'Masculino',
      },
      {
        value: 'feminino',
        label: 'Feminino',
      },
      {
        value: 'outros',
        label: 'Outros',
      },
    ];
    const valores = [
      {
        value: 'sim',
        label: 'Sim',
      },
      {
        value: 'nao',
        label: 'Não',
      },
    ];
    var busca, buscaimgcnhV, buscaimgcpf, buscaimgrgFrente,
      buscaimgrgVerso, buscaimgcompresid, buscaimgsus
    if (this.state.imgcnh === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { busca = true }
    if (this.state.imgcnhV === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { buscaimgcnhV = true }
    if (this.state.imgcpf === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { buscaimgcpf = true }
    if (this.state.imgrgFrente === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { buscaimgrgFrente = true }
    if (this.state.imgrgVerso === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { buscaimgrgVerso = true }
    if (this.state.imgcompresid === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { buscaimgcompresid = true }
    if (this.state.imgsus === 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png') { buscaimgsus = true }

    if ($('#cpfUp').val() == '' || $('#cpfUp').val() == null || buscaimgcpf === true) {
      $('#btn-cpf').css("color", "white");
      $('#btn-cpf').css("background-color", "red")
    } else {
      $('#btn-cpf').css("color", "white");
      $('#btn-cpf').css("background-color", "green")
    }
    if ($('#cnh').val() === '' || $('#cnh').val() === null || busca === true) {
      $('#btn-cnh').css("color", "white");
      $('#btn-cnh').css("background-color", "red")
    } else {
      $('#btn-cnh').css("color", "white");
      $('#btn-cnh').css("background-color", "green")
    }
    if ($('#cnhV').val() === '' || $('#cnhV').val() === null || buscaimgcnhV === true) {
      $('#btn-cnhV').css("color", "white");
      $('#btn-cnhV').css("background-color", "red")
    } else {
      $('#btn-cnhV').css("color", "white");
      $('#btn-cnhV').css("background-color", "green")
    }

    if ($('#rgFrente').val() === '' || $('#rgFrente').val() === null || buscaimgrgFrente === true) {
      $('#btn-rgFrente').css("color", "white");
      $('#btn-rgFrente').css("background-color", "red")
    } else {
      $('#btn-rgFrente').css("color", "white");
      $('#btn-rgFrente').css("background-color", "green")
    }

    if ($('#rgVerso').val() === '' || $('#rgVerso').val() === null || buscaimgrgVerso === true) {
      $('#btn-rgVerso').css("color", "white");
      $('#btn-rgVerso').css("background-color", "red")
    } else {
      $('#btn-rgVerso').css("color", "white");
      $('#btn-rgVerso').css("background-color", "green")
    }

    if ($('#compresid').val() === '' || $('#compresid').val() === null || buscaimgcompresid === true) {
      $('#btn-compresid').css("color", "white");
      $('#btn-compresid').css("background-color", "red")
    } else {
      $('#btn-compresid').css("color", "white");
      $('#btn-compresid').css("background-color", "green")
    }

    if ($('#sus').val() === '' || $('#sus').val() === null || buscaimgsus === true) {
      $('#btn-sus').css("color", "white");
      $('#btn-sus').css("background-color", "red")
    } else {
      $('#btn-sus').css("color", "white");
      $('#btn-sus').css("background-color", "green")
    }

    var maximo = this.state.limite.qtd
    var totalAtual = this.state.vagos.qtd

    if (totalAtual > maximo) {
      return (
        <div className="conteudo">
          <NavTop />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content btn-danger col-md-12">
              <div style={{ fontWeight: "bolder", fontSize: "70px" }} className="text-center">
                Sistema Indisponível
              </div>
            </section>
          </div>
          <Footer />
        </div>

      )
    }
    else {

      return (
        <div className="conteudo">
          <NavTop />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content">
              <form id="wizard" autoComplete="off" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status} />
                <fieldset className="card">
                  <legend className="card-header text-left legend-pma faq-btn-link">
                    Informações importantes
                  </legend>
                  <span className="text-center">Está com problemas para se cadastrar?
                    <a href="/Contato"> Clique Aqui</a></span>
                  <div id='resposta'></div>
                  <div style={{ color: "white" }} className="pv-btn-danger text-center col-md-12">
                    ATENÇÃO!!!!<br /> Para finalizar o seu cadastro é necessário<br /> preencher todos os campos obrigatórios e anexar todos os documentos.<br />
                    Caso esteja fazendo o cadastro pelo celular é necessario tirar as fotos dos documentos, antes de começar o cadastro!
                    <br />Por segurança dos celulares você não pode anexar uma foto usando a camêra!
                    <br />Se estiver Anexando um aquivo PDF ele não mostrar o arquivo para você, somente se você anexar uma FOTO
                  </div>
                  <div className="col-md-12 campos-pma card-body">
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="2"
                      InputProps={{
                        readOnly: false
                      }}
                      inputProps={{
                        maxlength: 14
                      }}
                      fullWidth
                      name="cpf"
                      label="C.P.F."
                      variant="outlined"
                      value={this.mCPF(this.state.cpf)}
                      type="text"
                      onBlur={this.buscaCpf}
                      onChange={e => this.setState({ cpf: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="nome"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="nome"
                      label="Nome"
                      variant="outlined"
                      type="text"
                      value={this.state.nome}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={e => this.setState({ nome: e.target.value })}
                    />
                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5"
                      id="dataNascimento"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="dtNascimento"
                      label="Data de Nascimento"
                      variant="outlined"
                      type="date"
                      value={this.state.dtNascimento}
                      onBlur={this.buscadtNascimento}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={e => this.setState({ dtNascimento: e.target.value })}
                    />
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="idade"
                      fullWidth
                      name="idade"
                      label="Idade"
                      variant="outlined"
                      type="number"
                      value={this.state.idade}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <Tooltip
                      title="Digitar DDD e numero com 9 Ex. (62) 9 9999-9999"
                      placement="top"
                    >
                      <TextField
                        placeholder="Ex. (62) 9 9999-9999"
                        required
                        className="mb-3 ml-3 col-md-5"
                        id="telefone"
                        InputProps={{
                          readOnly: false
                        }}
                        fullWidth
                        name="telefone"
                        label="Telefone"
                        variant="outlined"
                        type="text"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                        onKeyDown={e => this.validPhone(e.target.value)}
                        onChange={e => this.setState({ telefone: e.target.value })}
                      />
                    </Tooltip>

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="rg"
                      fullWidth
                      name="rg"
                      label="RG"
                      variant="outlined"
                      type="text"
                      onChange={e => this.setState({ rg: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="nome_mae"
                      fullWidth
                      name="nome_mae"
                      label="Nome da Mãe"
                      variant="outlined"
                      type="text"
                      onChange={e => this.setState({ nome_mae: e.target.value })}
                    />

                    <TextField

                      className="ml-3 mb-3 col-md-6"
                      id="cns"
                      fullWidth
                      name="cns"
                      label="Nº Cartão do SUS(Não Obrigatório)"
                      variant="outlined"
                      type="text"
                      onChange={e => this.setState({ cns: e.target.value })}
                    />

                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5 required"
                      id="sexo"
                      select
                      fullWidth
                      name="sexo"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={e => this.setState({ sexo: e.target.value })}
                      label="Sexo"
                      variant="outlined"
                    >
                      <option key={0} value=''></option>
                      {sexo.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      required
                      className="mb-3 ml-3 col-md-6 required"
                      id="pne"
                      select
                      fullWidth
                      name="pne"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={e => this.setState({ pne: e.target.value })}
                      label="Acamado"
                      variant="outlined"
                    >
                      <option key={0} value=''></option>
                      {valores.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5 required"
                      id="nacionalidade"
                      fullWidth
                      name="nacionalidade"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={e => this.setState({ nacionalidade: e.target.value })}
                      label="Nacionalidade"
                      variant="outlined"
                    />
                  </div>
                </fieldset>
                <fieldset className="card ">
                  <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                    Upload de documentos
                  </legend>
                  <br />
                  {/* <ImageUploading
                    multiple
                    value={this.state.images}
                    onChange=''
                    maxNumber='1'
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          CNH
                        </button>
                      </div>
                    )}
                  </ImageUploading> */}
                  <div id="anexos" style={{ display: 'none' }} className="row">
                    <div className="col-md-6 campos-pma">
                      <div hidden className="col-md-12">
                        <FormControlLabel control={
                          <Checkbox
                            label="termo"
                            name='termo'
                            checked={this.state.cnh}
                            onChange={e => this.setState({ cnh: e.target.checked })}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        } label="Upload de CNH?" />
                      </div>
                      <div className="">
                        <div hidden={this.state.cnh === false}>
                          <Button
                            id='btn-cnh'
                            name="cnh"
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            CNH - FRENTE
                            <input
                              onChange={this.handleOnChange}
                              name="cnh"
                              id='cnh'
                              className="ml-3 mb-3 col-md-6"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                              style={{ display: "none" }}
                            />
                          </Button>
                          <Button
                            id='btn-cnhV'
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            CNH - VERSO
                            <input
                              onChange={this.handleOnChange}
                              name="cnhV"
                              id='cnhV'
                              className="ml-3 mb-3 col-md-6"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                              style={{ display: "none" }}
                            />
                          </Button>
                        </div>
                        <div hidden={this.state.cnh === true} >
                          <Button
                            id='btn-cpf'
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            C.P.F.
                            <input
                              onChange={this.handleOnChange}
                              name="cpf"
                              id='cpfUp'
                              hidden
                              className="ml-3 mb-3 col-md-12"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                            />
                          </Button>
                          <Button
                            id='btn-rgFrente'
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            RG - FRENTE
                            <input
                              onChange={this.handleOnChange}
                              name="rgFrente"
                              id="rgFrente"
                              className="ml-3 mb-3 col-md-6"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                              style={{ display: "none" }}
                            />
                          </Button>
                          <Button
                            id="btn-rgVerso"
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            RG - VERSO
                            <input
                              onChange={this.handleOnChange}
                              name="rgVerso"
                              id="rgVerso"
                              className="ml-3 mb-3 col-md-6"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                              style={{ display: "none" }}
                            />
                          </Button>
                        </div>
                        <Button
                          className="ml-3 mb-3 col-md-6"
                          variant="contained"
                          component="label"
                          id="btn-compresid"
                        >
                          Documento Comprobatório
                          <input
                            onChange={this.handleOnChange}
                            name="compresid"
                            id='compresid'
                            className="ml-3 mb-3 col-md-6"
                            type="file"
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            style={{ display: "none" }}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="col-md-5 row">
                      <div hidden={this.state.cnh === false} className="col-md-6">
                        <button
                          className="btn-danger-img"
                          name="imgcnh"
                          hidden={busca}
                          value={this.state.imgcnh}
                          onClick={this.deleteArquivos}
                        >
                          X
                        </button>
                        <img alt="logopma" width="100px" height="100px"
                          src={this.state.imgcnh} />
                      </div>

                      <div hidden={this.state.cnh === false} className="col-md-6">
                        <button
                          className="btn-danger-img"
                          name="imgcnhV"
                          hidden={buscaimgcnhV}
                          value={this.state.imgcnhV}
                          onClick={this.deleteArquivos}
                        >
                          X
                        </button>
                        <img alt="logopma" width="100px" height="100px"
                          src={this.state.imgcnhV} />
                      </div>

                      <div hidden={this.state.cnh === true} className="col-md-6">
                        <button
                          className="btn-danger-img"
                          name="imgcpf"
                          hidden={buscaimgcpf}
                          value={this.state.imgcpf}
                          onClick={this.deleteArquivos}
                        >
                          X
                        </button>
                        <img alt="imgcpf" width="100px" height="100px"
                          src={this.state.imgcpf} />
                      </div>

                      <div hidden={this.state.cnh === true} className="col-md-6">
                        <button
                          className="btn-danger-img"
                          name="imgrgFrente"
                          hidden={buscaimgrgFrente}
                          value={this.state.imgrgFrente}
                          onClick={this.deleteArquivos}
                        >
                          X
                        </button>
                        <img alt="imgrgfrente" width="100px" height="100px"
                          src={this.state.imgrgFrente} />
                      </div>

                      <div hidden={this.state.cnh === true} className="col-md-6">
                        <button
                          className="btn-danger-img"
                          name="imgrgVerso"
                          hidden={buscaimgrgVerso}
                          value={this.state.imgrgVerso}
                          onClick={this.deleteArquivos}
                        >
                          X
                        </button>
                        <img alt="imgrgverso" width="100px" height="100px"
                          src={this.state.imgrgVerso} />
                      </div>

                      <div className="col-md-6">
                        <button
                          className="btn-danger-img"
                          name="imgcompresid"
                          hidden={buscaimgcompresid}
                          value={this.state.imgcompresid}
                          onClick={this.deleteArquivos}
                        >
                          X
                        </button>
                        <img alt="compresid" width="100px" height="100px"
                          src={this.state.imgcompresid} />
                      </div>

                    </div>
                  </div>
                </fieldset>
                <fieldset className="card ">
                  <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                    Endereço
                  </legend>
                  <br />
                  <div className="col-md-12 campos-pma">
                    <div className="col-md-6">
                      <TextField
                        id="cep"
                        fullWidth
                        required
                        name="cep"
                        label="CEP"
                        variant="outlined" type="text"
                        value={this.state.cep}
                        onChange={e => this.setState({ cep: e.target.value })}
                        onBlur={this.cepBlur}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Digitar a Rua, Av., Trav. não deixar em branco ou com 0"
                        placement="top"
                      >
                        <TextField
                          id="rua"
                          required
                          fullWidth
                          placeholder="Ex: Rua, Av, Trav."
                          name="logradouro"
                          label="Logradouro"
                          variant="outlined" type="text"
                          value={this.state.logradouro}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Se não Possuir número digite 0"
                        placement="top"
                      >
                        <TextField
                          id="numero"
                          required
                          fullWidth
                          name="numero"
                          placeholder="Apenas número, se não possuir deixe com 0"
                          label="Numero"
                          variant="outlined" type="number"
                          value={this.state.numero}
                          onChange={e => this.setState({ numero: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não Digitar nome de rua, quadra, lote, bairro"
                        placement="top"
                      >
                        <TextField
                          id="complemento"
                          fullWidth
                          name="complemento"
                          placeholder="Bl. Apt. Casa"
                          label="Complemento"
                          variant="outlined" type="text"
                          value={this.state.complemento}
                          onChange={e => this.setState({ complemento: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não digitar QD. ,Q. ,Quadra, etc"
                        placement="top"
                      >
                        <TextField
                          id="quadra"
                          fullWidth
                          {...this.state.numero == 0 ? { required: true } : { required: false }}
                          name="quadra"
                          placeholder="Não digitar QD. ,Q. ,Quadra, etc"
                          label="Quadra"
                          variant="outlined" type="text"
                          value={this.state.quadra}
                          onChange={e => this.setState({ quadra: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não digitar Lt., L., Lote, etc"
                        placement="top"
                      >
                        <TextField
                          id="lote"
                          fullWidth

                          {...this.state.numero == 0 ? { required: true } : { required: false }}
                          name="lote"
                          placeholder="Não digitar Lt., L., Lote, etc"
                          label="Lote"
                          variant="outlined" type="text"
                          value={this.state.lote}
                          onChange={e => this.setState({ lote: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Digitar apenas ponto de referência, se existir"
                        placement="top"
                      >
                        <TextField
                          id="pontoreferencia"
                          fullWidth
                          name="pontoreferencia"
                          placeholder="Digitar apenas ponto de referência, se existir"
                          label="Ponto de Referência"
                          variant="outlined" type="text"
                          onChange={e => this.setState({ pontoreferencia: e.target.value })}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Autocomplete
                        id="bairro2"
                        options={this.state.bairros}
                        getOptionLabel={(option) => option.nome}
                        onChange={(option, value) => {
                          if (value != null) {
                            this.setState({ bairro: value.nome })
                          }
                        }}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            fullWidth
                            id="bairro"
                            label="Bairro"
                            required
                            name="bairro"
                            variant="outlined" />}
                      />
                    </div>
                    <button
                      id="cadastro_vacina"
                      className="btn-pv col-md-12 text-center pv-btn-primary"
                      type="submit"
                    >
                      Cadastrar
                    </button>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
          <Footer />
        </div >

      )
    }
  }
}
export default Cadastro;