import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import {
  getReacoes,
  getUsers,
  getLogsCPF,
  getEditaCadastrosCPF,
  ConfEntrega,
  LiberaSolicitacao,
  RecusaVacina,
  AceitaVacina,
  getUnidadesAPLI,
  getVagas,
  getIdadesVacina,
  getLaudos,
  BloqueioVacina,
  DesbloqueioVacina,
  Libera3Solicitacao,
  LiberaSolicitacao4,
} from "../services/dados";
import { dateHoje } from "./ListaNaoVacinados";
import Swal from "sweetalert2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PainelADM } from "../components/PainelAdm";
import Vacinacao from "../components/Vacinacao";
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */
export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe;
  var classe;
  if (status === 200) {
    classe = "success";
  } else if (status === 201) {
    classe = "error";
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alertin severity={classe}>{param.texto}</Alertin>
      </Snackbar>
    </div>
  );
}
export function Alertin(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function removeAcento(text) {
  text = text.toLowerCase();
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "%");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "%");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "%");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "%");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "%");
  text = text.replace(new RegExp("[Ç]", "gi"), "%");
  return text;
}

export class BuscaCadastroCPF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      bairros: [],
      dadosUser: [],
      alert: {
        status: "",
        message: "",
      },
    };
    this.getEventos = this.getEventos.bind(this);
  }

  getEventos() {
    getUsers().then((response2) => {
      this.setState({ dadosUser: response2.data });
    });
  }

  componentDidMount() {
    this.getEventos();
  }

  handleSubmit(event) {
    event.preventDefault();
    var e;
    const form = event.target;
    const data = new FormData(form);
    let cpf = data.get("busca");
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    let teste = isNumber(cpf);
    if (teste === true) {
      let cpfForm = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      e = window.btoa(cpfForm);
      return (window.location = "/CadastroCpf?" + e);
    } else {
      var t = removeAcento(cpf);
      e = window.btoa(t);
      return (window.location = "/CadastroNomeBusca?" + e);
    }
  }

  render() {
    var alertMsg = this.state.alert;
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dadosUser} />
          <Sidebar dados={this.state.dadosUser} />
          <br />
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <h1>Busca Cadastros por Numero CPF - Tablets e Celulares</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="/Vacina">
                    <i className="fa fa-dashboard" />
                    Início
                  </a>
                </li>
              </ol>
            </section>
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status}
                />
                <TextField
                  required
                  className="mb-3 ml-3 col-md-11"
                  id="busca"
                  type="number"
                  fullWidth
                  name="busca"
                  value={this.state.qtd}
                  onChange={this.qtdChange}
                  label="CPF (Sem pontos ou traços)"
                  variant="outlined"
                />
                <button
                  className="btn-pv mb-3 ml-3 col-md-11 text-center pv-btn-primary"
                  type="submit"
                >
                  Buscar Cadastro
                </button>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export class BuscaCadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      bairros: [],
      dadosUser: [],
      alert: {
        status: "",
        message: "",
      },
    };
    this.getEventos = this.getEventos.bind(this);
  }

  getEventos() {
    getUsers().then((response2) => {
      this.setState({ dadosUser: response2.data });
    });
  }

  componentDidMount() {
    this.getEventos();
  }

  handleSubmit(event) {
    event.preventDefault();
    var e;
    const form = event.target;
    const data = new FormData(form);
    let cpf = data.get("busca");
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    let teste = isNumber(cpf);
    if (teste === true) {
      if (cpf.length > 11) {
        e = window.btoa(cpf);
        return (window.location = "/CadastroCpf?" + e);
      } else {
        let cpfForm = cpf.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
        e = window.btoa(cpfForm);
        return (window.location = "/CadastroCpf?" + e);
      }
    } else {
      var t = removeAcento(cpf);
      e = window.btoa(t);
      return (window.location = "/CadastroNomeBusca?" + e);
    }
  }

  render() {
    var alertMsg = this.state.alert;
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dadosUser} />
          <Sidebar dados={this.state.dadosUser} />
          <br />
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <h1>Busca Cadastros</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="/Vacina">
                    <i className="fa fa-dashboard" />
                    Início
                  </a>
                </li>
              </ol>
            </section>
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status}
                />
                <TextField
                  required
                  className="mb-3 ml-3 col-md-11"
                  id="busca"
                  fullWidth
                  name="busca"
                  value={this.state.qtd}
                  onChange={this.qtdChange}
                  label="Busca Nome ou CPF(sem traços e pontos) ou Cartão do SUS(Sem espaços)"
                  variant="outlined"
                />
                <button
                  className="btn-pv mb-3 ml-3 col-md-11 text-center pv-btn-primary"
                  type="submit"
                >
                  Buscar Cadastro
                </button>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export function apareceAlert(props) {
  var url = props.location.pathname;
  var path = props.location.search;
  var caminho = url + path;
  return (window.location = caminho);
}

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export class CadastroCpf extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      dadosCad: [],
      unidades: [],
      idadeVac: "",
      alert: {
        status: "",
        message: "",
      },
      listAdd: [],
      listLogs: [],
      idadeVacina: {},
      idade3dose: {},
      idade4dose: {},
      laudo: {
        nome: "",
        ext: "",
      },
      tipo_cadastro: "",
    };

    this.getEventos = this.getEventos.bind(this);
    this.EntregaConf = this.EntregaConf.bind(this);
    this.EntregaConf2 = this.EntregaConf2.bind(this);
    this.EntregaConf3 = this.EntregaConf3.bind(this);
    this.EntregaConf4 = this.EntregaConf4.bind(this);
    this.LiberaConf = this.LiberaConf.bind(this);
    this.LiberaConf2 = this.LiberaConf2.bind(this);
    this.LiberaConf3 = this.LiberaConf3.bind(this);
    this.LiberaConf4 = this.LiberaConf4.bind(this);
    this.MarcaReacao = this.MarcaReacao.bind(this);
    this.vacinaRecusada = this.vacinaRecusada.bind(this);
    this.ReaceitarVacina = this.ReaceitarVacina.bind(this);
    this.inserirAplicador = this.inserirAplicador.bind(this);
    this.anteciparDose = this.anteciparDose.bind(this);
    this.CPFEdita = this.CPFEdita.bind(this);
    this.CPFEditaSuperAdm = this.CPFEditaSuperAdm.bind(this);
    this.Remover1Vacina = this.Remover1Vacina.bind(this);
    this.Remover2Vacina = this.Remover2Vacina.bind(this);
    this.Remover3Vacina = this.Remover3Vacina.bind(this);
    this.Remover4Vacina = this.Remover4Vacina.bind(this);
    this.bloquearVacina = this.bloquearVacina.bind(this);
    this.desbloquearVacina = this.desbloquearVacina.bind(this);
  }

  async getEventos() {
    await getIdadesVacina().then((response2) => {
      this.setState({
        idadeVacina: response2.data,
      });
    });
    await getUsers().then((response2) => {
      this.setState({ dados: response2.data });
    });
    var id = this.props.location.search;

    await getEditaCadastrosCPF(id).then((response3) => {
      this.setState({
        dadosCad: response3.data,
        tipo_cadastro: response3.data.tipo_cadastro,
      });
    });
    await getVagas().then((response2) => {
      this.setState({
        idadeVac: response2.data[2],
        idade3dose: response2.data[4],
        idade4dose: response2.data[5],
      });
    });

    await getLaudos(id).then((response2) => {
      this.setState({
        laudo: response2.data,
      });
    });
    await getReacoes(id).then((response3) => {
      this.setState({
        listAdd: response3.data,
      });
    });
    await getLogsCPF(id).then((response3) => {
      this.setState({
        listLogs: response3.data,
      });
    });
    await getUnidadesAPLI().then((response2) => {
      this.setState({ unidades: response2.data });
    });
  }

  MarcaReacao() {
    var e = this.state.dadosCad.id;
    var teste = window.btoa(e);
    localStorage.setItem("edit", teste);
    return (window.location = "/InserirReacao");
  }

  inserirAplicador() {
    var e = this.state.dadosCad.id;
    var teste = window.btoa(e);
    localStorage.setItem("inserirAplicador", teste);
    return (window.location = "/InserirAplicador?" + teste);
  }

  anteciparDose() {
    var e = this.state.dadosCad.id;
    var teste = window.btoa(e);
    return (window.location = "/AnteciparDose/?" + teste);
  }

  async CPFEdita() {
    let teste = this.state.dadosCad.cpf;
    var e = window.btoa(teste);
    return (window.location = "/CadastroEdita?" + e);
  }

  async CPFEditaSuperAdm() {
    let teste = this.state.dadosCad.cpf;
    var e = window.btoa(teste);
    return (window.location = "/CadastroEditaADM?" + e);
  }

  async Remover1Vacina() {
    let teste = this.state.dadosCad.idVacina;
    var e = window.btoa(teste);
    return (window.location = "/RemoverVacina?" + e);
  }

  async Remover2Vacina() {
    let teste = this.state.dadosCad.idVacina2;
    var e = window.btoa(teste);
    return (window.location = "/RemoverVacina?" + e);
  }

  async Remover3Vacina() {
    let teste = this.state.dadosCad.idVacina3;
    var e = window.btoa(teste);
    return (window.location = "/RemoverVacina?" + e);
  }
  async Remover4Vacina() {
    let teste = this.state.dadosCad.idVacina4;
    var e = window.btoa(teste);
    return (window.location = "/RemoverVacina?" + e);
  }

  async ReaceitarVacina() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    const MySwal = Swal.fire({
      title: "Tem certeza?",
      text:
        "Tem certeza que deseja Aceitar a vacina para: " + nome + " - " + cpf,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        AceitaVacina(cpf).then((response2) => {
          this.setState({
            alert: {
              status: response2.data.response.status,
              message: response2.data.response.result,
            },
          });
          //this.setState({ setOpen: true });
          let propis = this.props;
          if (this.state.alert.status === 200) {
            Toast.fire({
              icon: "success",
              title: this.state.alert.message,
            }).then(function() {
              apareceAlert(propis);
            });
          } else if (this.state.alert.status === 201) {
            Toast.fire({
              icon: "error",
              title: this.state.alert.message,
            });
          }
        });
      }
    });
  }

  async vacinaRecusada() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    const MySwal = Swal.fire({
      title: "Tem certeza?",
      text:
        "Tem certeza que deseja Recusar a vacina para: " + nome + " - " + cpf,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        RecusaVacina(cpf).then((response2) => {
          this.setState({
            alert: {
              status: response2.data.response.status,
              message: response2.data.response.result,
            },
          });
          //this.setState({ setOpen: true });
          let propis = this.props;
          if (this.state.alert.status === 200) {
            Toast.fire({
              icon: "success",
              title: this.state.alert.message,
            }).then(function() {
              apareceAlert(propis);
            });
          } else if (this.state.alert.status === 201) {
            Toast.fire({
              icon: "error",
              title: this.state.alert.message,
            });
          }
        });
      }
    });
  }

  async bloquearVacina() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    const MySwal = Swal.fire({
      title: "Tem certeza?",
      text:
        "Tem certeza que deseja Bloquear a vacina para: " + nome + " - " + cpf,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        BloqueioVacina(cpf).then((response2) => {
          this.setState({
            alert: {
              status: response2.data.response.status,
              message: response2.data.response.result,
            },
          });
          //this.setState({ setOpen: true });
          let propis = this.props;
          if (this.state.alert.status === 200) {
            Toast.fire({
              icon: "success",
              title: this.state.alert.message,
            }).then(function() {
              apareceAlert(propis);
            });
          } else if (this.state.alert.status === 201) {
            Toast.fire({
              icon: "error",
              title: this.state.alert.message,
            });
          }
        });
      }
    });
  }

  async desbloquearVacina() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    const MySwal = Swal.fire({
      title: "Tem certeza?",
      text:
        "Tem certeza que deseja Desbloquear a vacina para: " +
        nome +
        " - " +
        cpf,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        DesbloqueioVacina(cpf).then((response2) => {
          this.setState({
            alert: {
              status: response2.data.response.status,
              message: response2.data.response.result,
            },
          });
          //this.setState({ setOpen: true });
          let propis = this.props;
          if (this.state.alert.status === 200) {
            Toast.fire({
              icon: "success",
              title: this.state.alert.message,
            }).then(function() {
              apareceAlert(propis);
            });
          } else if (this.state.alert.status === 201) {
            Toast.fire({
              icon: "error",
              title: this.state.alert.message,
            });
          }
        });
      }
    });
  }

  async EntregaConf() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    var e = this.state.dadosCad.idVacina;
    var date = dateHoje();
    var data = date.split("/");
    var data2 = data[2].split(" ");
    var datahj = data2[0] + "-" + data[1] + "-" + data[0];
    //var datahj = '2021-02-07'
    const { value: formValue } = await Swal.fire({
      title: "Selecione a Data da Aplicação",
      html:
        '<input type="date" id="swal-input" value="' +
        datahj +
        '" class="swal2-input">',
      focusConfirm: false,
      allowOutsideClick: false,
      preConfirm: () => {
        return document.getElementById("swal-input").value;
      },
    });
    if (formValue != null) {
      // var r = window.confirm("Tem certeza que deseja confirmar a vacinação para o CPF " + cpf + " na data: " + formValue + "?");
      const MySwal = Swal.fire({
        title: "Tem certeza?",
        text:
          "Tem certeza que deseja Confirmar a vacina para: " +
          nome +
          " - " +
          cpf,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ConfEntrega(e, formValue).then((response2) => {
            this.setState({
              alert: {
                status: response2.data.response.status,
                message: response2.data.response.result,
              },
            });

            let propis = this.props;
            if (this.state.alert.status === 200) {
              Toast.fire({
                icon: "success",
                title: this.state.alert.message,
              }).then(function() {
                apareceAlert(propis);
              });
            } else if (this.state.alert.status === 201) {
              Toast.fire({
                icon: "error",
                title: this.state.alert.message,
              });
            }
          });
        }
      });
    } else {
      this.setState({ setOpen: false });
    }
  }

  async EntregaConf2() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    var e = this.state.dadosCad.idVacina2;
    var date = dateHoje();
    var data = date.split("/");
    var data2 = data[2].split(" ");
    var datahj = data2[0] + "-" + data[1] + "-" + data[0];
    //var datahj = '2021-02-07'
    const { value: formValue } = await Swal.fire({
      title: "Selecione a Data da Aplicação",
      html:
        '<input type="date" id="swal-input" value="' +
        datahj +
        '" class="swal2-input">',
      focusConfirm: false,
      allowOutsideClick: false,
      preConfirm: () => {
        return document.getElementById("swal-input").value;
      },
    });
    if (formValue != null) {
      // var r = window.confirm("Tem certeza que deseja confirmar a vacinação para o CPF " + cpf + " na data: " + formValue + "?");
      const MySwal = Swal.fire({
        title: "Tem certeza?",
        text:
          "Tem certeza que deseja Confirmar a 2º dose da vacina para: " +
          nome +
          " - " +
          cpf,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ConfEntrega(e, formValue).then((response2) => {
            this.setState({
              alert: {
                status: response2.data.response.status,
                message: response2.data.response.result,
              },
            });

            let propis = this.props;
            if (this.state.alert.status === 200) {
              Toast.fire({
                icon: "success",
                title: this.state.alert.message,
              }).then(function() {
                apareceAlert(propis);
              });
            } else if (this.state.alert.status === 201) {
              Toast.fire({
                icon: "error",
                title: this.state.alert.message,
              });
            }
          });
        }
      });
    } else {
      this.setState({ setOpen: false });
    }
  }

  async EntregaConf3() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    var e = this.state.dadosCad.idVacina3;
    var date = dateHoje();
    var data = date.split("/");
    var data2 = data[2].split(" ");
    var datahj = data2[0] + "-" + data[1] + "-" + data[0];
    //var datahj = '2021-02-07'
    const { value: formValue } = await Swal.fire({
      title: "Selecione a Data da Aplicação",
      html:
        '<input type="date" id="swal-input" value="' +
        datahj +
        '" class="swal2-input">',
      focusConfirm: false,
      allowOutsideClick: false,
      preConfirm: () => {
        return document.getElementById("swal-input").value;
      },
    });
    if (formValue != null) {
      // var r = window.confirm("Tem certeza que deseja confirmar a vacinação para o CPF " + cpf + " na data: " + formValue + "?");
      const MySwal = Swal.fire({
        title: "Tem certeza?",
        text:
          "Tem certeza que deseja Confirmar a 3º dose da vacina para: " +
          nome +
          " - " +
          cpf,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ConfEntrega(e, formValue).then((response2) => {
            this.setState({
              alert: {
                status: response2.data.response.status,
                message: response2.data.response.result,
              },
            });

            let propis = this.props;
            if (this.state.alert.status === 200) {
              Toast.fire({
                icon: "success",
                title: this.state.alert.message,
              }).then(function() {
                apareceAlert(propis);
              });
            } else if (this.state.alert.status === 201) {
              Toast.fire({
                icon: "error",
                title: this.state.alert.message,
              });
            }
          });
        }
      });
    } else {
      this.setState({ setOpen: false });
    }
  }

  async EntregaConf4() {
    var cpf = this.state.dadosCad.cpf;
    var nome = this.state.dadosCad.nome;
    var e = this.state.dadosCad.idVacina4;
    var date = dateHoje();
    var data = date.split("/");
    var data2 = data[2].split(" ");
    var datahj = data2[0] + "-" + data[1] + "-" + data[0];
    //var datahj = '2021-02-07'
    const { value: formValue } = await Swal.fire({
      title: "Selecione a Data da Aplicação",
      html:
        '<input type="date" id="swal-input" value="' +
        datahj +
        '" class="swal2-input">',
      focusConfirm: false,
      allowOutsideClick: false,
      preConfirm: () => {
        return document.getElementById("swal-input").value;
      },
    });
    if (formValue != null) {
      // var r = window.confirm("Tem certeza que deseja confirmar a vacinação para o CPF " + cpf + " na data: " + formValue + "?");
      const MySwal = Swal.fire({
        title: "Tem certeza?",
        text:
          "Tem certeza que deseja Confirmar a 4º dose da vacina para: " +
          nome +
          " - " +
          cpf,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ConfEntrega(e, formValue).then((response2) => {
            this.setState({
              alert: {
                status: response2.data.response.status,
                message: response2.data.response.result,
              },
            });

            let propis = this.props;
            if (this.state.alert.status === 200) {
              Toast.fire({
                icon: "success",
                title: this.state.alert.message,
              }).then(function() {
                apareceAlert(propis);
              });
            } else if (this.state.alert.status === 201) {
              Toast.fire({
                icon: "error",
                title: this.state.alert.message,
              });
            }
          });
        }
      });
    } else {
      this.setState({ setOpen: false });
    }
  }

  // async EntregaConf2() {
  //     var cpf = this.state.dadosCad.cpf
  //     var e = this.state.dadosCad.idEntrega2
  //     var r = window.confirm("Tem certeza que deseja confirmar a 2 dose para o CPF " + cpf + "?");
  //     if (r === true) {
  //         await ConfEntrega(e).then(response2 => {
  //             this.setState({
  //                 alert: {
  //                     status: response2.data.error.status,
  //                     message: response2.data.error.message
  //                 }
  //             })
  //         })
  //         this.setState({ setOpen: true });
  //         apareceAlert(this.props)
  //     }
  //     else {
  //         this.setState({ setOpen: false });
  //     }
  // }

  async LiberaConf() {
    var e = this.state.dadosCad.id;
    var teste = window.btoa(e);
    return (window.location = "/InserirAplicador2Dose?" + teste);
  }

  async LiberaConf2() {
    var cpf = this.state.dadosCad.cpf;
    var e = this.state.dadosCad.id;
    var r = window.confirm(
      "Tem certeza que deseja solicitação a entrega para o CPF " + cpf + "?"
    );
    if (r === true) {
      await LiberaSolicitacao(e).then((response2) => {
        this.setState({
          alert: {
            status: response2.data.response.status,
            message: response2.data.response.result,
          },
        });
      });
      this.setState({ setOpen: true });
      apareceAlert(this.props);
    } else {
      this.setState({ setOpen: false });
    }
  }

  async LiberaConf4() {
    var cpf = this.state.dadosCad.cpf;
    var e = this.state.dadosCad.id;
    var r = window.confirm(
      "Tem certeza que deseja solicitação a entrega para o CPF " + cpf + "?"
    );
    if (r === true) {
      var teste = window.btoa(e);
      return (window.location = "/InserirAplicador4Dose?" + teste);
    } else {
      this.setState({ setOpen: false });
    }
  }

  async LiberaConf3() {
    var e = this.state.dadosCad.id;
    var teste = window.btoa(e);
    return (window.location = "/InserirAplicador3Dose?" + teste);
  }

  // async LiberaConf4() {
  //     var e = this.state.dadosCad.id
  //     var teste = window.btoa(e)
  //     return (window.location = "/InserirAplicador4Dose?" + teste);
  // }

  confirmExit(e) {
    if (e.vacinado == "Não" && e.idVacina !== null) {
      // window.addEventListener("beforeunload", function (event) {
      //     return event.returnValue = Toast.fire({
      //         icon: 'success',
      //         title: this.state.alert.message
      //     }).then(function () {

      //     })
      // });
      window.onbeforeunload = function(e) {
        if (e) {
          return "There is unsaved data.";
        }
        return undefined;
      };
    }
  }

  componentDidMount() {
    this.getEventos();
  }

  render() {
    var role;
    var alertMsg = this.state.alert;
    var cadastro = this.state.dadosCad;
    var dadosUsuario = this.state.dados;
    var laudo, vacinado, pne, edita, button1, button2, button4, editaRole;

    if (cadastro.cpf === null) {
      return (
        <div className="skin-blue bg-light sidebar-mini" id="panel">
          <div className="wrapper">
            <NavTop dados={this.state.dados} />
            <Sidebar dados={this.state.dados} />
            <br />
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <section className="content-header">
                <h1>Dados Cadastrais</h1>
                <ol className="breadcrumb">
                  <li>
                    <a href="/Vacina">
                      <i className="fa fa-dashboard" />
                      Início/Dados Cadastrais
                    </a>
                  </li>
                </ol>
              </section>
              <div className="col-md-12 mt-3">
                <legend className="text-center btn-danger">
                  PACIENTE NÃO CADASTRADO
                </legend>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (dadosUsuario.role != "ROLE_CAD") {
      if (
        this.state.tipo_cadastro.match("comorbidade") ||
        this.state.tipo_cadastro.match("lactantes")
      ) {
        if (
          this.state.laudo == "" ||
          this.state.laudo == null ||
          this.state.laudo == undefined
        ) {
          laudo = (
            <div className="ml-3 mr-3 col-md-12 table mt-3">
              Paciente não possui laudo anexado no sistema!
            </div>
          );
        } else {
          if (this.state.laudo.ext === "pdf") {
            laudo = (
              <div className="ml-3 mr-3 col-md-11 table mt-3">
                <object
                  data={this.state.laudo.nome}
                  type="application/pdf"
                  height="500px"
                  width="100%"
                >
                  <p>
                    Leia o pdf <a href={this.state.laudo.nome}>aqui!</a>
                  </p>
                </object>
                <br />
              </div>
            );
          } else {
            laudo = (
              <div className="ml-3 mr-3 col-md-12 table mt-3">
                <img alt="laudo" width="50%" src={this.state.laudo.nome} />
              </div>
            );
          }
        }
      } else {
        laudo = (
          <div className="ml-3 mr-3 col-md-12 table mt-3">
            Cadastro Dispensa Laudo Comprobatório
          </div>
        );
      }
    } else {
      laudo = "";
    }
    let button3, button5;
    if (dadosUsuario.role === "MEGA_ADM" || dadosUsuario.role === "ROLE_ADM") {
      editaRole = (
        <Button
          onClick={this.CPFEditaSuperAdm}
          className="btn btn-danger ml-3"
          style={{ color: "white", fontWeight: "600" }}
        >
          Editar Cadastro Master
        </Button>
      );
    } else {
      editaRole = (
        <Button
          onClick={this.CPFEdita}
          className="btn btn-danger ml-3"
          style={{ color: "white", fontWeight: "600" }}
        >
          Editar Cadastro
        </Button>
      );
    }

    if (
      dadosUsuario.role === "MEGA_ADM" ||
      dadosUsuario.role === "ROLE_ADM" ||
      dadosUsuario.role === "SUPER_ADM"
    ) {
      if (cadastro.idVacina2 === null) {
        button2 = "";
      } else {
        button2 = (
          <Button
            onClick={this.Remover2Vacina}
            className="btn btn-2dose ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Atualizar 2º Dose
          </Button>
        );
      }
      if (cadastro.idVacina3 === null) {
        button4 = "";
      } else {
        button4 = (
          <Button
            onClick={this.Remover3Vacina}
            className="btn btn-3dose ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Atualizar 3º Dose
          </Button>
        );
      }
      if (cadastro.idVacina4 === null) {
        button5 = "";
      } else {
        button5 = (
          <Button
            onClick={this.Remover4Vacina}
            className="btn btn-4dose ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Atualizar 4º Dose
          </Button>
        );
      }
      if (cadastro.idVacina === null) {
        button1 = "";
      } else {
        button1 = (
          <Button
            onClick={this.Remover1Vacina}
            className="btn btn-1dose ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Atualizar 1º Dose
          </Button>
        );
      }
      if (
        cadastro.bloqueado === 1 &&
        (dadosUsuario.role === "MEGA_ADM" || dadosUsuario.role === "ROLE_ADM")
      ) {
        button3 = (
          <Button
            onClick={this.desbloquearVacina}
            className="btn btn-1block ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Desbloquear Vacina
          </Button>
        );
      }
      if (cadastro.bloqueado === 0 && dadosUsuario.role != "ROLE_CAD") {
        button3 = (
          <Button
            onClick={this.bloquearVacina}
            className="btn btn-1block ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Bloquear Vacina
          </Button>
        );
      }
      edita = (
        <div className="col-md-12">
          <br />
          <h1 className="card-header text-left legend-pma faq-btn-link">
            Editar
          </h1>
          <div className="row mt-3">
            {editaRole}
            {button3}
          </div>
        </div>
      );
    } else {
      if (cadastro.bloqueado === 0 && dadosUsuario.role != "ROLE_CAD") {
        button3 = (
          <Button
            onClick={this.bloquearVacina}
            className="btn btn-1block ml-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            Bloquear Vacina
          </Button>
        );
      }
      edita = (
        <div className="col-md-12">
          <br />
          <h1 className="card-header text-left legend-pma faq-btn-link">
            Editar
          </h1>
          <div className="row mt-3">{button3}</div>
        </div>
      );
    }
    if (cadastro.pne === false) {
      pne = "Não";
    } else {
      pne = "Sim";
    }
    let dataFormatada = cadastro.dt_nascimento;
    //let dataFormatada = ((data.getDate())) + "/" + ((data.getMonth() + 1)) + "/" + data.getFullYear();
    var logs;
    if (
      this.state.dados.role === "MEGA_ADM" ||
      this.state.dados.role === "SUPER_ADM"
    ) {
      logs = (
        <div className="col-md-12 mt-3">
          <legend>Registro de Log's: </legend>
          <div className="col-md-12 mt-3">
            <ul className="list-group">
              <table
                style={{ width: 100 + "%" }}
                className="display table-bordered table"
              >
                <thead>
                  <tr>
                    <th style={{ fontWeight: "bolder" }}>Ação</th>
                    <th style={{ fontWeight: "bolder" }}>Data</th>
                    <th style={{ fontWeight: "bolder" }}>Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.listLogs.map((res) => (
                    <tr key={res.id}>
                      <td>{res.acao} </td>
                      <td> {res.data}</td>
                      <td> {res.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ul>
          </div>
        </div>
      );
    }
    if (this.state.dados.role === "ROLE_CAD") {
      role = <div className="col-md-12 mt-3 mb-3"></div>;
    } else if (cadastro.bloqueado === 1) {
      role = (
        <div className="col-md-12 mt-3">
          <legend className="text-center btn-danger">VACINA BLOQUEADA</legend>
        </div>
      );
    }
    // else if (cadastro.tipo_cadastro === 'aluno' && cadastro.alunos === '0') {
    //     role =
    //         <div className="col-md-12 mt-3">
    //             <legend className='text-center btn-danger'>ALUNO NÃO CONFIRMADO NA ESCOLA SOLICITAR COMPROVANTE</legend>
    //         </div>
    // }
    else {
      role = (
        <div className="col-md-12">
          <br />
          <h1 className="card-header text-left legend-pma faq-btn-link">
            Painel Administrativo
          </h1>
          <PainelADM
            perfil={cadastro.perfil}
            dados={cadastro}
            dadosUser={this.state.dados}
            EntregaConf={this.EntregaConf}
            EntregaConf2={this.EntregaConf2}
            EntregaConf3={this.EntregaConf3}
            EntregaConf4={this.EntregaConf4}
            LiberaConf={this.LiberaConf}
            LiberaConf3={this.LiberaConf3}
            LiberaConf4={this.LiberaConf4}
            MarcaReacao={this.MarcaReacao}
            vacinaRecusada={this.vacinaRecusada}
            aceitarVacina={this.ReaceitarVacina}
            aplicador={this.inserirAplicador}
            antecipar={this.anteciparDose}
            idadeVac={this.state.idadeVac}
            vacinaIdade={this.state.idadeVacina}
            vacina3dose={this.state.idade3dose}
            vacina4dose={this.state.idade4dose}
          />
        </div>
      );
    }
    let vacinacao, dose4, class1, class2;
    if (cadastro.idade >= 18) {
      class1 = "col-md-5";
      class2 = "col-md-6";

      dose4 = (
        <div className={`ml-3 ${class2} table mt-3`}>
          <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
            <b>4º DOSE</b>
          </div>
          <div className="col-md-12 mt-3">
            Vacinado: <b>{cadastro.vacinado4}</b>
          </div>
          <div className="col-md-12 mt-3">
            Dose: <b>{cadastro.dose_vacina4}</b>
          </div>
          <div className="col-md-12 mt-3">
            Lote Vacina: <b>{cadastro.lote_vacina4}</b>
          </div>
          <div className="col-md-12 mt-3">
            Nome Vacina: <b>{cadastro.nome_vacina4}</b>
          </div>
          <div className="col-md-12 mt-3">
            Aplicador: <b>{cadastro.aplicador4}</b>
          </div>
        </div>
      );
    } else {
      class1 = "col-md-3";
      class2 = "col-md-4";
      dose4 = "";
    }

    if (cadastro.nome_vacina === "JANSSEN") {
      vacinacao = (
        <div className="row">
          <div className="ml-3 mr-3 col-md-5 table mt-3">
            <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
              <b>DOSE UNICA</b>
            </div>
            <div className="col-md-12 mt-3">
              Vacinado: <b>{cadastro.vacinado}</b>
            </div>
            <div className="col-md-12 mt-3">
              Dose: <b>{cadastro.dose_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Lote Vacina: <b>{cadastro.lote_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Nome Vacina: <b>{cadastro.nome_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Aplicador: <b>{cadastro.aplicador}</b>
            </div>
          </div>
          <div className="ml-3 mr-3 col-md-6 table mt-3">
            <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
              <b>DOSE REFORÇO</b>
            </div>
            <div className="col-md-12 mt-3">
              Vacinado: <b>{cadastro.vacinado3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Dose: <b>{cadastro.dose_vacina3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Lote Vacina: <b>{cadastro.lote_vacina3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Nome Vacina: <b>{cadastro.nome_vacina3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Aplicador: <b>{cadastro.aplicador3}</b>
            </div>
          </div>
        </div>
      );
    } else {
      vacinacao = (
        <div className="row">
          <div className={`ml-3 mr-3 ${class1} table mt-3`}>
            <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
              <b>1º DOSE</b>
            </div>
            <div className="col-md-12 mt-3">
              Vacinado: <b>{cadastro.vacinado}</b>
            </div>
            <div className="col-md-12 mt-3">
              Dose: <b>{cadastro.dose_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Lote Vacina: <b>{cadastro.lote_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Nome Vacina: <b>{cadastro.nome_vacina}</b>
            </div>
            <div className="col-md-12 mt-3">
              Aplicador: <b>{cadastro.aplicador}</b>
            </div>
          </div>
          <div className={`ml-3 ${class2} table mt-3`}>
            <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
              <b>2º DOSE</b>
            </div>
            <div className="col-md-12 mt-3">
              Vacinado: <b>{cadastro.vacinado2}</b>
            </div>
            <div className="col-md-12 mt-3">
              Dose: <b>{cadastro.dose_vacina2}</b>
            </div>
            <div className="col-md-12 mt-3">
              Lote Vacina: <b>{cadastro.lote_vacina2}</b>
            </div>
            <div className="col-md-12 mt-3">
              Nome Vacina: <b>{cadastro.nome_vacina2}</b>
            </div>
            <div className="col-md-12 mt-3">
              Aplicador: <b>{cadastro.aplicador2}</b>
            </div>
          </div>
          <div className={`ml-3 mr-3 ${class1} table mt-3`}>
            <div className="col-md-12 mt-3 bg-primary MuiSvgIcon-root">
              <b>3º DOSE</b>
            </div>
            <div className="col-md-12 mt-3">
              Vacinado: <b>{cadastro.vacinado3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Dose: <b>{cadastro.dose_vacina3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Lote Vacina: <b>{cadastro.lote_vacina3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Nome Vacina: <b>{cadastro.nome_vacina3}</b>
            </div>
            <div className="col-md-12 mt-3">
              Aplicador: <b>{cadastro.aplicador3}</b>
            </div>
          </div>
          {dose4}
        </div>
      );
    }
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <br />
          <div id="printable" className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <h1>Dados Cadastrais</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="/Vacina">
                    <i className="fa fa-dashboard" />
                    Início/Dados Cadastrais
                  </a>
                </li>
              </ol>
            </section>
            {/* Main content */}
            <section className="content">
              <Alerta
                setOpen={this.state.setOpen}
                texto={alertMsg.message}
                classe={alertMsg.status}
              />
              <div style={{ background: "#FFF" }} className="col-md-12 row">
                <div style={{ background: "#FFF" }} className="col-md-12 row">
                  <div className="col-md-10 text-center">
                    <span className="h1">{cadastro.nome}</span>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <h1 className="card-header text-left legend-pma faq-btn-link">
                      Dados Pessoais
                    </h1>
                  </div>
                  <div className="col-md-4 mt-3">
                    CPF: <b>{cadastro.cpf}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Data de Nascimento: <b>{dataFormatada}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Telefone: <b>{cadastro.telefone}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Idade: <b>{cadastro.idade}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    RG: <b>{cadastro.rg}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Nome da Mãe: <b>{cadastro.nome_mae}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    CNS: <b>{cadastro.cns}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Sexo: <b>{cadastro.sexo}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Nacionalidade: <b>{cadastro.nacionalidade}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Tipo cadastro: <b>{cadastro.tipo_cadastro}</b>
                  </div>
                  <div
                    hidden={dadosUsuario.role !== "MEGA_ADM"}
                    className="col-md-4 mt-3"
                  >
                    Profissão: <b>{cadastro.profissao}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Acamado: <b>{pne}</b>
                  </div>

                  <div className="col-md-12">
                    <br />
                    <h1 className="card-header text-left legend-pma faq-btn-link">
                      Endereço
                    </h1>
                  </div>
                  <div className="col-md-4 mt-3">
                    CEP: <b>{cadastro.cep}</b>
                  </div>
                  <div className="col-md-6 mt-3">
                    Logradouro: <b>{cadastro.rua}</b>
                  </div>
                  <div className="col-md-2 mt-3">
                    Número: <b>{cadastro.numero}</b>
                  </div>
                  <div className="col-md-6 mt-3">
                    Complemento: <b>{cadastro.complemento}</b>
                  </div>
                  <div className="col-md-3 mt-3">
                    Quadra: <b>{cadastro.quadra}</b>
                  </div>
                  <div className="col-md-3 mt-3">
                    Lote: <b>{cadastro.lote}</b>
                  </div>
                  <div className="col-md-4 mt-3">
                    Bairro: <b>{cadastro.bairro}</b>
                  </div>
                  <div className="col-md-5 mt-3">
                    Cidade: <b>ANÁPOLIS</b>
                  </div>
                  <div className="col-md-2 mt-3">
                    UF: <b>GOIÁS</b>
                  </div>

                  <div className="col-md-12">
                    <br />
                    <h1 className="card-header text-left legend-pma faq-btn-link">
                      Histórico
                    </h1>
                  </div>
                  <div className="col-md-12 mt-3">
                    <legend>Observações: </legend>
                    <div className="col-md-12 mt-3">
                      <ul className="list-group">
                        <table
                          style={{ width: 100 + "%" }}
                          className="display table-bordered table"
                        >
                          <thead>
                            <tr>
                              <th style={{ fontWeight: "bolder" }}>
                                Observação
                              </th>
                              <th style={{ fontWeight: "bolder" }}>Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listAdd.map((res) => (
                              <tr key={res.id}>
                                <td>{res.reacao} </td>
                                <td> {res.data}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </ul>
                    </div>
                  </div>
                  {logs}
                  <div className="col-md-12">
                    <br />
                    <h1 className="card-header text-left legend-pma faq-btn-link">
                      Laudo
                    </h1>
                    <div className="row">{laudo}</div>
                  </div>
                  <div className="col-md-12">
                    <br />
                    <h1 className="card-header text-left legend-pma faq-btn-link">
                      Vacinação
                    </h1>
                    <Vacinacao dados={cadastro} />
                  </div>
                  {edita}
                </div>
                {role}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
