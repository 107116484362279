import React, { Component } from "react";
import Footer from "../components/Footer";
import { getUsers, getPainelVacinas, getEstoque } from "../services/dados";

function TableFaixaEtaria(props) {
    var dados = props.vacinas
    var style, style2, style3, style4
    return dados.map(function (item, i) {
        if (item.imunizados_n > 50) {
            style = "percentGreen"
        } else {
            style = "percentRed"
        }
        if (item.imunizados_n2dose > 50) {
            style2 = "percentGreen"
        } else {
            style2 = "percentRed"
        }
        if (item.imunizados_n3dose > 50) {
            style3 = "percentGreen"
        } else {
            style3 = "percentRed"
        }
        if (item.imunizados_n4dose > 50) {
            style4 = "percentGreen"
        } else {
            style4 = "percentRed"
        }
        let estilo
        if (item.faixa_etaria === 'TOTAIS') {
            estilo = 'pma-bold'
        }
        else {
            estilo = ''
        }
        return (
            <tr className={estilo} key={i}>
                <td>{item.faixa_etaria}</td>
                <td>{item.populacao_cadastrada}</td>
                <td>{item.doses_aplicadas1dose}</td>
                <td className={style}>{item.imunizados}</td>
                <td>{item.doses_aplicadas2dose}</td>
                <td className={style2}>{item.imunizados2dose}</td>
                <td>{item.doses_aplicadas3dose}</td>
                <td className={style3}>{item.imunizados3dose}</td>
                <td>{item.doses_aplicadas4dose}</td>
                <td className={style4}>{item.imunizados4dose}</td>
            </tr>
        )
    })
}

function TableEstoque(props) {
    if (props.vacinas === null || props.vacinas === '') {
        return '';
    }
    var dados = props.vacinas
    return dados.map(function (item, i) {
        return (
            <tr key={i}>
                <td>{item.nome}</td>
                <td>{item.qtd_doses}</td>
                <td>{item.qtd_dose_apli}</td>
                <td>{item.incompletas}</td>
                <td>{item.estoque}</td>
            </tr>
        )
    })
}

function PainelEstoque(props) {
    var dados = props.teste
    if (dados.role == 'ROLE_PROCV') {
        return '';
    }
    if (props.estoque == null || props.estoque2 == '' || props.estoque2 == null || props.estoque3 == '' || props.estoque3 == null || props.estoque == '') {
        return '';
    }
    return (
        <section className="content">
            <div className="box-header with-border">
                <h3 className="box-title">Vacinas</h3>
            </div>
            <section className="content">
                <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }}
                    refs="main">
                    <thead>
                        <tr className="title_table">
                            <th>Vacina 1º Dose</th>
                            <th>Quantidade de Doses Recebidas 1º Dose</th>
                            <th>Quantidade de Doses Utilizadas 1º Dose</th>
                            <th>Quantidade de Doses Incompletas/Excedentes</th>
                            <th>Quantidade de Doses em Estoque 1º Dose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableEstoque vacinas={props.estoque} />
                    </tbody>
                </table>
                <hr />
                <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }}
                    refs="main">
                    <thead>
                        <tr className="title_table">
                            <th>Vacina 2º Dose</th>
                            <th>Quantidade de Doses Recebidas 2º Dose</th>
                            <th>Quantidade de Doses Utilizadas 2º Dose</th>
                            <th>Quantidade de Doses Incompletas/Excedentes</th>
                            <th>Quantidade de Doses em Estoque 2º Dose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableEstoque vacinas={props.estoque2} />
                    </tbody>
                </table>
                <hr />
                <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }}
                    refs="main">
                    <thead>
                        <tr className="title_table">
                            <th>Vacina 3º Dose</th>
                            <th>Quantidade de Doses Recebidas 3º Dose</th>
                            <th>Quantidade de Doses Utilizadas 3º Dose</th>
                            <th>Quantidade de Doses Incompletas/Excedentes</th>
                            <th>Quantidade de Doses em Estoque 3º Dose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableEstoque vacinas={props.estoque3} />
                    </tbody>
                </table>
            </section>
        </section>
    )
}


export class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            alert: {
                status: '',
                message: ''
            },
            Vacinas: [],
            estoque: [],
            estoque2: []
        }
        this.getEventos = this.getEventos.bind(this);
    }

    async getEventos() {
        let dose = 1
        let dose2 = 2
        let dose3 = 3
        await getUsers().then(response2 => {
            this.setState({
                dados: response2.data
            })
            if (response2.data.acesso === 0) {
                return (window.location = "/TrocarSenha");
            }
        });
        await getPainelVacinas().then(response2 => {
            this.setState({
                Vacinas: response2.data
            })
        })
        await getEstoque(dose).then(response2 => {
            this.setState({
                estoque: response2.data
            })
        })
        await getEstoque(dose2).then(response2 => {
            this.setState({
                estoque2: response2.data
            })
        })
        await getEstoque(dose3).then(response2 => {
            this.setState({
                estoque3: response2.data
            })
        })
    }

    backBusca() {
        window.location = "/Vacina"
    }

    newRequests() {
        getPainelVacinas().then(response2 => {
            this.setState({
                Vacinas: response2.data
            })
        })
    }
    componentDidMount() {
        this.getEventos();
    }
    render() {
        // setTimeout(this.newRequests(), 10000);
        return (
            <div
            // className="bg-light sidebar-mini" id="panel"
            >
                <div className="wrapper">
                    {/* <NavTop dados={this.state.dados} /> */}
                    {/* <Sidebar dados={this.state.dados} /> */}
                    <div className="content-wrapper">
                        <button onClick={this.backBusca}>Voltar</button>
                        <PainelEstoque estoque={this.state.estoque} estoque2={this.state.estoque2} estoque3={this.state.estoque3} teste={this.state.dados} />
                        <hr />
                        <section className="content">
                            <div className="box-header with-border">
                                <h3 className="box-title">Vacinas por Faixa Etária</h3>
                            </div>
                            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }}
                                refs="main">
                                <thead>
                                    <tr className="title_table">
                                        <th>FAIXA ETÁRIA</th>
                                        <th>POPULAÇÃO CADASTRADA</th>
                                        <th>DOSES APLICADAS 1º Dose</th>
                                        <th>% IMUNIZADA 1º Dose</th>
                                        <th>DOSES APLICADAS 2º Dose</th>
                                        <th>% IMUNIZADA 2º Dose</th>
                                        <th>DOSES APLICADAS 3º Dose</th>
                                        <th>% IMUNIZADA 3º Dose</th>
                                        <th>DOSES APLICADAS 4º Dose</th>
                                        <th>% IMUNIZADA 4º Dose</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableFaixaEtaria vacinas={this.state.Vacinas} />
                                </tbody>
                            </table>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}