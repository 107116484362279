import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Aplicar from "./Aplicar";
import ConfirmarAplicacao from "./ConfirmarAplicacao";

export default function ModalAplicarVacina(props) {
  const [unidade, setUnidade] = useState({});
  const [aplicacao, setAplicacao] = useState({});
  const [step, setStep] = useState(0);

  const getComponent = (step) => {
    switch (step) {
      case 0:
        return (
          <Aplicar
            props={props}
            setStep={setStep}
            unidade={unidade}
            setUnidade={setUnidade}
            setAplicacao={setAplicacao}
          />
        );
      case 1:
        return (
          <ConfirmarAplicacao
            props={props}
            setStep={setStep}
            aplicacao={aplicacao}
          />
        );
      default:
        return <div>Error</div>;
    }
  };

  return getComponent(step);
}
