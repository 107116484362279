import React, { Component } from "react";
import Sidebar from "../Vacina/Sidebar";
import NavTop from "../Vacina/Navtop";
import Footer from "../components/Footer";
import { getBuscaAplicadorBairros, getUsers } from "../services/dados"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alertin severity={classe}>
                    {param.texto}
                </Alertin>
            </Snackbar>
        </div>
    );
}
export function Alertin(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function apareceAlert() {
    setTimeout(() => {
        return (window.location = "/ListaMotoristas");
    }, 1 * 1000)
}
const columns = [
    {
        title: 'Bairro',
        width: "auto",
        data: 'bairro'
    },
    {
        title: 'Quantidade',
        width: "auto",
        data: 'qtd'
    },

];

function DataTable(props) {
    var table = $('#table').DataTable({
        dom: 'rtip',
        responsive: true,
        data: props.cadastros,
        columns,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {

            $('td:eq(0)', row).click(
                function () {
                    props.buscaCPF(data.id, data.bairro);
                })
            $('td:eq(1)', row).click(
                function () {
                    props.buscaCPF(data.id, data.bairro);
                })


            $('td:eq(3)', row).css('display', '')
            $('td:eq(1)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
        },
        "headerCallback": function (thead, data, display) {

            $('th:eq(0)', thead).css('display', '')
            $('th:eq(1)', thead).css('display', '')
            $('th:eq(3)', thead).css('display', '')
            $('td:eq(4)', thead).css('white-space', 'normal')
        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}



class EntregaMotorista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
        this.buscaCPF = this.buscaCPF.bind(this)
        this.obsInserir = this.obsInserir.bind(this)
    }

    getEventos() {
        var idBusca = this.props.location.search
        var busca = idBusca.split('?')
        var id = busca[1]
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data })
        });
        getBuscaAplicadorBairros(id).then(response2 => {
            this.setState({ dados: response2.data, usuario: response2.data[0].usuario })
        });
    }

    obsInserir(id) {
        var teste = window.btoa(id)
        localStorage.setItem('edit', teste);
        return (window.location = "/EntregaObs");
    }

    async buscaCPF(id, bairro) {
        //var bairros = window.btoa(bairro)
        var bairros = Buffer.from(bairro).toString('base64')
        return (window.location = "/ListaConfAplicador?" + id + "?" + bairros)

    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Lista Aplicação
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <Alerta
                                setOpen={this.state.setOpen}
                                texto={alertMsg.message}
                                classe={alertMsg.status} />
                            <DataTable
                                cadastros={this.state.dados}
                                inserirObs={this.obsInserir}
                                buscaCPF={this.buscaCPF}
                                usuario={this.state.usuario} />
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}
export default EntregaMotorista