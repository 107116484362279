import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Aplicar({
  props,
  setStep,
  unidade,
  setUnidade,
  setAplicacao,
}) {
  const { unidades, dados, dadosUser, aplicacoes } = props.aplicadorDTO;

  const arrayDoses = [
    { value: 1, name: "D1" },
    { value: 2, name: "D2" },
    { value: 8, name: "D3" },
    { value: 3, name: "R1" },
    { value: 4, name: "R2" },
    { value: 5, name: "R3" },
    { value: 6, name: "DA" },
    { value: 7, name: "DU" },
  ];

  let yourDate = new Date();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  const dataHoje = yourDate.toISOString().split("T")[0];

  const dataInicioVacinacao = "2021-01-22";

  const [data, setData] = useState(dataHoje);
  const [dose, setDose] = useState("");

  console.log(data);

  const handleSubmit = async () => {
    setAplicacao({
      fk_cadastro: dados.id,
      fk_vacina: unidade.id_vacina,
      fk_repasse: unidade.id_repasse,
      fk_usuario: dadosUser.id,
      dt_aplicacao: data,
      dose_vacina: dose,
      nome_aplicador: unidade.nome_aplicador,
      qtd: unidade.qtd,
    });
    if (
      Date.parse(data) > Date.parse(dataHoje) ||
      Date.parse(data) < Date.parse(dataInicioVacinacao)
    ) {
      alert("data inválida");
    } else if (!unidade.nome_aplicador) {
      alert("Por favor, seleciona um aplicador");
    } else {
      setStep(1);
    }
  };
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>APLICAR VACINA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Typography variant="h4">Nome: {dados.nome}</Typography>
        <Typography variant="h4">CPF: {dados.cpf}</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Dose</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dose}
            onChange={(e) => setDose(e.target.value)}
          >
            {arrayDoses.map((item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          id="combo-box-demo"
          options={unidades}
          getOptionLabel={(option) =>
            option.nome +
            " - " +
            option.qtd +
            " doses - " +
            option.vacina +
            " - " +
            option.lote
          }
          onChange={(option, value) => {
            if (value != null) {
              setUnidade({
                id_vacina: value.id,
                lote_vacina: value.lote,
                id_repasse: value.id_repasse,
                nome_aplicador: value.nome,
                qtd: value.qtd,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="aplicador"
              label="Aplicador"
              required
              name="aplicador"
            />
          )}
        />
        <TextField
          fullWidth
          id="date"
          label="Data de Aplicação"
          type="date"
          value={data}
          onChange={(e) => setData(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Aplicar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
