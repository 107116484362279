import React, { Component } from "react";
// import { Bar } from "react-chartjs-2"
import { getDadosGrafico, getAvisos } from "../services/dados"
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

function Graficos(props) {
  var teste = []
  var entregas = []
  var pendentes = []

  if (props.data === null || props.data === []) {
    return '';
  } else {
    props.data.map(function (dados, a) {

      dados.entregues.map(function (dados2, b) {
        teste.push(dados2.data)
        return '';
      })
      dados.entregues.map(function (dados3, a) {
        entregas.push(dados3.entregas)
        return '';
      })
      dados.pendentes.map(function (dados3, a) {
        pendentes.push(dados3.pendentes)
        return '';
      })
      return ''
    })
  }
  const data = {
    labels: teste,
    datasets: [
      {
        label: 'Entregas',
        backgroundColor: 'rgba(60,141,188,0.8)',
        borderColor: 'rgba(60,141,188,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(60,141,188,0.4)',
        hoverBorderColor: 'rgba(60,141,188,1)',
        data: entregas
      },
      // {
      //   label: 'Pendentes',
      //   backgroundColor: 'rgba(250,128,114,0.8)',
      //   borderColor: 'rgba(250,128,114,1)',
      //   borderWidth: 1,
      //   hoverBackgroundColor: 'rgba(250,128,114,0.4)',
      //   hoverBorderColor: 'rgba(250,128,114,1)',
      //   data: pendentes
      // }
    ]
  }
  return (
    ''
    // <Bar
    //   data={data}
    //   width={props.width}
    //   height={props.height}
    //   options={{
    //     maintainAspectRatio: false
    //   }}
    // />
  )
};

function GraficosCount(props) {
  if (props.user.role === 'MEGA_ADM' || props.user.role === 'ROLE_ADM') {
    return props.dados.map(function (dadosCount, a) {
      if (a < 2) {
        return (
          <div key={a} className=" text-center col-md-4 mt-2">
            <div className="description-block ">
              <h5 style={{ fontWeight: 'bolder' }} className="description-header">{dadosCount.qtd}</h5>
              <span className="description-text">TOTAL {dadosCount.status}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div key={a} className=" text-center col-md-4 mt-2">
            <div className="description-block ">
              <h5 style={{ fontWeight: 'bolder' }} className="description-header">{dadosCount.qtd}</h5>
              <span className="description-text">{dadosCount.status}</span>
            </div>
          </div>
        )
      }
    })
  } else {
    return ''
  }

}

function Avisos(props) {
  let dados = props.user
  let role = dados.role
  let apiDados = props.avisos
  let excluido = apiDados.excluido
  let regra = apiDados.regra
  let bg = apiDados.fundo
  let aviso = apiDados.aviso
  let tituloAviso = apiDados.titulo

  if (excluido === true) {
    return ''
  }
  else {
    if (regra === 'todos') {
      if (dados.role === 'MEGA_ADM' || dados.role === 'ROLE_ADM' || dados.role === 'ROLE_UNI' || dados.role === 'ROLE_COD') {
        return (
          <div className={"MuiSvgIcon-root  card " + bg}>
            <h4 className="mt-2 ml-1 mr-1 mb-2">
              <b>{tituloAviso}</b>
              <br />
              <hr />
            </h4>
            {aviso}

          </div>
        )
      }
      else {
        return ''
      }
    }
    else if (regra === "administrador") {
      if (dados.role === 'MEGA_ADM' || dados.role === 'ROLE_ADM') {
        return (
          <div className={"MuiSvgIcon-root  card " + bg}>
            <h4 className="mt-2 ml-1 mr-1 mb-2">
              <b>{tituloAviso}</b>
              <br />
              <hr />
            </h4>
            {aviso}

          </div>
        )
      }
      else {
        return ''
      }
    }
    else {
      return ''
    }
  }

}

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dadosEntregues: [],
      dadosAvisos: [],
      dadosCount: [],
      alert: {
        status: '',
        message: ''
      }
    };
    this.getEventos = this.getEventos.bind(this);
  }

  async getEventos() {
    await getDadosGrafico().then(response => {
      this.setState({ dadosCount: response.data })
    });
    await getAvisos().then(response => {
      this.setState({ dadosAvisos: response.data })
    });
  }

  componentDidMount() {
    this.getEventos();
  }
  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">Avisos</h3>
                </div>
                <Avisos user={this.props.dados} avisos={this.state.dadosAvisos} />
              </div>
            </div >
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">Dados Gerais</h3>
                </div>
                <div className='row'>
                  <GraficosCount user={this.props.dados} dados={this.state.dadosCount} />
                </div>
              </div>
            </div >
          </div>
        </section>
      </div>
    );
  }
}
export default Content;
