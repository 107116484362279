import React, { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { getUsers } from "./services/dados";




class Covid19 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dadosUser: ''
        };
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        getUsers().then(response2 => {
            this.setState({
                dadosUser: response2.data
            })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    render() {

        return (
            <div style={{ width: "100%" }} >
                <Header />
                <div className="wrapper">
                    <div style={{ backgroundColor: 'aliceblue' }} className="content-wrapper">
                        <section className="content">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <img alt='404' className="img-responsive pma-center" width="60%" src="https://cdn.anapolis.go.gov.br/img/404.svg" />
                                    </div>
                                </div >
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Covid19;