import React, { Component } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import {
  getUsers,
  token,
  getCadastrados,
  getBairros,
  getVagas,
} from "../services/dados";
/* eslint eqeqeq: "off", curly: "error" */
const { cpf } = require("cpf-cnpj-validator");
const $ = require("jquery");

export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe;
  var classe;
  if (status === 200) {
    classe = "success";
  } else if (status === 201 || status === 110) {
    classe = "error";
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>{param.texto}</Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Cadastro extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: "",
        message: "",
      },
      cpf: "",
      tipRes: "",
      quadra: "",
      lote: "",
      pontoreferencia: "",
      bairros: [],
      idade: "",
      nome: "",
      dtNascimento: "",
      complemento: "",
      cep: "",
      tipo_pne: "",
      numero: "",
      vagos: "",
      limite: "",
      idadeCad: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  async getEventos() {
    getBairros().then((response) => {
      this.setState({ bairros: response.data });
    });
    getUsers().then((response2) => {
      this.setState({ dados: response2.data });
    });
    await getVagas().then((response2) => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1],
        idadeCad: response2.data[3],
      });
    });
  }

  mCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  };

  validPhone(phone) {
    const isCelular = phone.length >= 14;
    if (isCelular === false) {
      $("#telefone").addClass("telefoneErr");
      $("#cadastro_vacina").attr("disabled", true);
      $("#cadastro_vacina").addClass("btn-danger");
      $("#cadastro_vacina").removeClass("btn-primary");
    } else {
      $("#telefone").removeClass("telefoneErr");
      $("#cadastro_vacina").removeAttr("disabled", false);
      $("#cadastro_vacina").removeClass("btn-danger");
      $("#cadastro_vacina").addClass("btn-primary");
    }
  }

  componentDidMount() {
    this.getEventos();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false });
      var cpf = this.state.cpf;
      var e = window.btoa(cpf);
      return (window.location = "/CadastroCpf?" + e);
    }, 1 * 1000);
  }

  calculaIdade(dataNasc) {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split("/");
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;
    if (mesAtual < mesNasc) {
      idade--;
    } else {
      //Se estiver no mes do nascimento, verificar o dia
      if (mesAtual == mesNasc) {
        if (new Date().getDate() < diaNasc) {
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
          idade--;
        }
      }
    }
    return idade;
  }

  buscadtNascimento = (event) => {
    var data;
    let idadeCad = this.state.idadeCad.qtd;
    if (event.target === undefined) {
      data = event;
    } else {
      data = event.target.value;
    }
    var date1 = data.split("-");
    date1 = date1[2] + "/" + date1[1] + "/" + date1[0];
    var teste = this.calculaIdade(date1);
    this.setState({ idade: teste });
    // if (teste >= idadeCad) { }
    // else {
    //   alert("Idade de " + teste + " anos, menor que o permitido!")
    //   setTimeout(() => {
    //     return (window.location = "/Cadastro");
    //   }, 100)
    // }
  };

  buscaCpf = (event) => {
    var id = event.target.value;
    let validCPF = cpf.isValid(id);
    if (validCPF === false) {
      alert("Verifique o CPF digitado ele está inválido");
      this.setState({ cpf: "" });
      setTimeout(() => {
        $("#resposta").html("");
      }, 1 * 100);
    } else {
      getCadastrados(id).then((response) => {
        console.log(response.data.data_nasc != null);
        if (response.data.data_nasc != null) {
          this.setState({
            nome: response.data.nome,
            dtNascimento: response.data.data_nasc,
          });
          this.buscadtNascimento(this.state.dtNascimento);
          $("#telefone").focus();
          $("#nome").attr("readonly", true);
          $("#dataNasc").attr("readonly", true);
        }
        if (response.data.cadastrado >= 1) {
          alert(
            "Usuario já Cadastrado, Você será redirecionado para o perfil do cadastrado"
          );
          var e = window.btoa(id);
          this.setState({ cpf: "" });
          setTimeout(() => {
            $("#resposta").html("");
          }, 1 * 1000);
          return (window.location = "/CadastroCpf?" + e);
        } else {
          setTimeout(() => {
            $("#resposta").html("");
          }, 1 * 100);
        }
      });
    }
  };

  valueCPF = (event) => {
    this.setState({ cpf: event.target.value });
  };

  async handleSubmit(event) {
    event.preventDefault();
    const {
      cpf,
      nome,
      telefone,
      dtNascimento,
      idade,
      rg,
      pne,
      tipo_pne,
      cep,
      numero,
      complemento,
      quadra,
      lote,
      pontoreferencia,
      bairro,
      sexo,
      cns,
      nome_mae,
    } = this.state;
    const logradouro = $("#rua").val();
    var raw = {
      cpf,
      nome,
      telefone,
      dtNascimento,
      idade,
      rg,
      pne,
      tipo_pne,
      cep,
      logradouro,
      numero,
      complemento,
      quadra,
      lote,
      pontoreferencia,
      bairro,
      sexo,
      cns,
      nome_mae,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    await apiBusca
      .post("/cadastros/formCad", requestOptions)
      .then((response) => {
        this.setState({
          alert: {
            status: response.data.response.status,
            message: response.data.response.result,
          },
        });
        this.setState({ setOpen: true });
        if (this.state.alert.status === 200) {
          this.apareceAlert();
        }
      })
      .catch((error) => {
        this.setState({});
        this.setState({ setOpen: true });
      });
    //event.target.reset();
  }

  render() {
    var maximo = this.state.limite.qtd;
    var totalAtual = this.state.vagos.qtd;
    var alertMsg = this.state.alert;
    const sexo = [
      {
        value: "masculino",
        label: "Masculino",
      },
      {
        value: "feminino",
        label: "Feminino",
      },
      {
        value: "outros",
        label: "Outros",
      },
    ];
    const valores = [
      {
        value: "sim",
        label: "Sim",
      },
      {
        value: "nao",
        label: "Não",
      },
    ];
    if (totalAtual > maximo) {
      return (
        <div className="skin-blue bg-light sidebar-mini" id="panel">
          <div className="wrapper">
            <NavTop dados={this.state.dados} />
            <Sidebar dados={this.state.dados} />
            <br />
            <div className="content-wrapper">
              {/* Main content */}
              <section className="content btn-danger col-md-12">
                <div
                  style={{ fontWeight: "bolder", fontSize: "70px" }}
                  className="text-center"
                >
                  Sistema Indisponível
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status}
                />
                <fieldset className="card">
                  <legend className="card-header text-left legend-pma faq-btn-link">
                    Informações importantes
                  </legend>
                  <div id="resposta"></div>
                  <div className="col-md-12 campos-pma card-body">
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="2"
                      InputProps={{
                        readOnly: false,
                      }}
                      fullWidth
                      name="cpf"
                      label="C.P.F."
                      variant="outlined"
                      value={this.mCPF(this.state.cpf)}
                      type="text"
                      onBlur={this.buscaCpf}
                      onChange={(e) => this.setState({ cpf: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="nome"
                      InputProps={{
                        readOnly: false,
                      }}
                      fullWidth
                      name="nome"
                      label="Nome"
                      variant="outlined"
                      type="text"
                      value={this.state.nome}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(e) => this.setState({ nome: e.target.value })}
                    />
                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5"
                      id="dataNasc"
                      InputProps={{
                        readOnly: false,
                      }}
                      fullWidth
                      name="dtNascimento"
                      label="Data de Nascimento"
                      variant="outlined"
                      type="date"
                      value={this.state.dtNascimento}
                      onBlur={this.buscadtNascimento}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        this.setState({ dtNascimento: e.target.value })
                      }
                    />
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="idade"
                      fullWidth
                      name="idade"
                      label="Idade"
                      variant="outlined"
                      type="number"
                      value={this.state.idade}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Tooltip
                      title="Digitar DDD e numero com 9 Ex. (62) 9 9999-9999"
                      placement="top"
                    >
                      <TextField
                        placeholder="Ex. (62) 9 9999-9999"
                        required
                        className="mb-3 ml-3 col-md-5"
                        id="telefone"
                        InputProps={{
                          readOnly: false,
                        }}
                        fullWidth
                        name="telefone"
                        label="Telefone"
                        variant="outlined"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                        onKeyDown={(e) => this.validPhone(e.target.value)}
                        onChange={(e) =>
                          this.setState({ telefone: e.target.value })
                        }
                      />
                    </Tooltip>

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="rg"
                      fullWidth
                      name="rg"
                      label="RG"
                      variant="outlined"
                      type="text"
                      onChange={(e) => this.setState({ rg: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="nome_mae"
                      fullWidth
                      name="nome_mae"
                      label="Nome da Mãe"
                      variant="outlined"
                      type="text"
                      onChange={(e) =>
                        this.setState({ nome_mae: e.target.value })
                      }
                    />

                    <TextField
                      className="ml-3 mb-3 col-md-6"
                      id="cns"
                      fullWidth
                      name="cns"
                      label="Nº CNS"
                      variant="outlined"
                      type="text"
                      onChange={(e) => this.setState({ cns: e.target.value })}
                    />

                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5 required"
                      id="sexo"
                      select
                      fullWidth
                      name="sexo"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={(e) => this.setState({ sexo: e.target.value })}
                      label="Sexo"
                      variant="outlined"
                    >
                      <option key={0} value=""></option>
                      {sexo.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      required
                      className="mb-3 ml-3 col-md-6 required"
                      id="pne"
                      select
                      fullWidth
                      name="pne"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={(e) => this.setState({ pne: e.target.value })}
                      label="Acamado"
                      variant="outlined"
                    >
                      <option key={0} value=""></option>
                      {valores.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>

                    {/* 
                    <TextField
                      required={this.state.pne === 'sim'}
                      className="ml-3 mb-3 col-md-6"
                      id="pnetipo_pne"
                      fullWidth
                      name="tipo_pne"
                      label="Descrição PNE"
                      variant="outlined"
                      type="text"
                      onChange={e => this.setState({ tipo_pne: e.target.value })}
                    /> */}
                  </div>
                </fieldset>
                <fieldset className="card ">
                  <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                    Endereço
                  </legend>
                  <br />
                  <div className="col-md-12 campos-pma">
                    <div className="col-md-6">
                      <TextField
                        id="cep"
                        fullWidth
                        required
                        name="cep"
                        label="CEP"
                        variant="outlined"
                        type="text"
                        value={this.state.cep}
                        onChange={(e) => this.setState({ cep: e.target.value })}
                        onBlur={this.cepBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Digitar a Rua, Av., Trav. não deixar em branco ou com 0"
                        placement="top"
                      >
                        <TextField
                          id="rua"
                          required
                          fullWidth
                          placeholder="Ex: Rua, Av, Trav."
                          name="logradouro"
                          label="Logradouro"
                          variant="outlined"
                          type="text"
                          value={this.state.logradouro}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Se não Possuir número digite 0"
                        placement="top"
                      >
                        <TextField
                          id="numero"
                          required
                          fullWidth
                          name="numero"
                          placeholder="Apenas número, se não possuir deixe com 0"
                          label="Numero"
                          variant="outlined"
                          type="number"
                          value={this.state.numero}
                          onChange={(e) =>
                            this.setState({ numero: e.target.value })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não Digitar nome de rua, quadra, lote, bairro"
                        placement="top"
                      >
                        <TextField
                          id="complemento"
                          fullWidth
                          name="complemento"
                          placeholder="Bl. Apt. Casa"
                          label="Complemento"
                          variant="outlined"
                          type="text"
                          value={this.state.complemento}
                          onChange={(e) =>
                            this.setState({ complemento: e.target.value })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não digitar QD. ,Q. ,Quadra, etc"
                        placement="top"
                      >
                        <TextField
                          id="quadra"
                          fullWidth
                          {...(this.state.numero == 0
                            ? { required: true }
                            : { required: false })}
                          name="quadra"
                          placeholder="Não digitar QD. ,Q. ,Quadra, etc"
                          label="Quadra"
                          variant="outlined"
                          type="text"
                          value={this.state.quadra}
                          onChange={(e) =>
                            this.setState({ quadra: e.target.value })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Não digitar Lt., L., Lote, etc"
                        placement="top"
                      >
                        <TextField
                          id="lote"
                          fullWidth
                          {...(this.state.numero == 0
                            ? { required: true }
                            : { required: false })}
                          name="lote"
                          placeholder="Não digitar Lt., L., Lote, etc"
                          label="Lote"
                          variant="outlined"
                          type="text"
                          value={this.state.lote}
                          onChange={(e) =>
                            this.setState({ lote: e.target.value })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Tooltip
                        title="Digitar apenas ponto de referência, se existir"
                        placement="top"
                      >
                        <TextField
                          id="pontoreferencia"
                          fullWidth
                          name="pontoreferencia"
                          placeholder="Digitar apenas ponto de referência, se existir"
                          label="Ponto de Referência"
                          variant="outlined"
                          type="text"
                          onChange={(e) =>
                            this.setState({ pontoreferencia: e.target.value })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-md-6">
                      <Autocomplete
                        id="bairro2"
                        options={this.state.bairros}
                        getOptionLabel={(option) => option.nome}
                        onChange={(option, value) => {
                          if (value != null) {
                            this.setState({ bairro: value.nome });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            id="bairro"
                            label="Bairro"
                            required
                            name="bairro"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                    <button
                      id="cadastro_vacina"
                      className="btn-pv col-md-12 text-center pv-btn-primary"
                      type="submit"
                    >
                      Cadastrar
                    </button>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Cadastro;
