import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import Footer from "../components/Footer";
// import { Bar } from "react-chartjs-2"
import { getVagasCidadao } from "../services/dados"
import NavTop from "./Navtop";
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dadosEntregues: [],
            dadosAvisos: [],
            dadosCount: [],
            vagos: '',
            limite: '',
            alert: {
                status: '',
                message: ''
            },
            termo: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
    }

    async getEventos() {
        await getVagasCidadao().then(response2 => {
            this.setState({
                vagos: response2.data[0],
                limite: response2.data[1],
                idadeCad: response2.data[3]
            })
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        return (window.location = "/CadastroLactantes");
    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        let button
        if (this.state.termo === true) { button = "btn-pv col-md-12 text-center pv-btn-primary" }
        else { button = "btn-pv col-md-12 text-center pv-btn-danger" }

        var maximo = 900000
        var totalAtual = this.state.vagos.qtd

        if (totalAtual > maximo) {
            return (
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content btn-danger col-md-12">
                        <div style={{ fontWeight: "bolder", fontSize: "70px" }} className="text-center">
                            Sistema Indisponível
                        </div>
                    </section>
                </div>
            )
        } else {

            return (
                <div className="home">
                    <NavTop />
                    <div className='col-md-12'>
                        <h1 className="text-center">
                            Cadastro #IMUNIZAANÁPOLIS
                        </h1>
                        <hr />
                        <div className="pma-text-center mb-3">
                            <h3>Para efetuar o seu cadastro será necessário anexar:</h3><br />
                            <b>- CPF<br /></b>
                            <b>- RG<br /></b>
                            <b>- ou CNH<br /></b>
                            <b>- Comprovante de Residência (Domicílio em Anápolis)<br /></b>
                            <b>- Certidão de Nascimento da criança com idade maxima de 6 meses<br /></b>
                            <br />
                            <h4>E estar de acordo com o Termo de Responsabilidade:</h4><br />

                            DECLARO, para os devidos fins de direito, sob as penas da lei,
                            que as informações aqui prestadas e os documentos apresentados nos anexos, são verdadeiros.<br />
                            Fico ciente através deste Termo de Aceite que a falsidade de informações configura crime
                            previsto no Código Penal Brasileiro, passível de apuração na forma da Lei bem como pode
                            ser enquadrada como litigância de Má-Fé.
                            <br />Ciente das responsabilidades das informações prestadas, aceito este Termo de Responsabilidade.
                            <br /><br />
                            <div style={{ color: "white" }} className="pv-btn-danger text-center col-md-12">
                                Atenção: Para evitar erros, utilize preferencialmente<br />
                                os navegadores <b>Google Chrome ou Mozilla Firefox.</b></div>
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <FormControlLabel control={
                                    <Checkbox
                                        label="termo"
                                        name='termo'
                                        checked={this.state.termo}
                                        onChange={e => this.setState({ termo: e.target.checked })}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                } label="Eu li e estou de acordo" />
                                <button
                                    hidden={this.state.termo === false}
                                    disabled={this.state.termo === false}
                                    id="cadastro_vacina"
                                    className={button}
                                    type="submit"
                                >
                                    Cadastrar
                                </button>
                            </form>
                        </div>
                    </div>
                    <Footer />
                </div >
            );
        }
    }
}
export default Content;
