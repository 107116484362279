import axios from "axios";
import { getToken } from "./auth";
//export var apiURL = "https://formsocialapi.anapolis.go.gov.br/vacinaApi";
var apiURLBairros = "https://api.anapolis.go.gov.br/formSocialApi";
export var apiURL = "https://api.anapolis.go.gov.br/vacinaApi";
// export var apiURL = "http://10.10.100.42:5005/vacinaApi";
export var cdnURL = "https://cdn.anapolis.go.gov.br/img/";

export const apiNova = "https://api.anapolis.go.gov.br/apivacina";

const api = axios.create({
  baseURL: apiURL,
});

export const apiBairros = axios.create({
  baseURL: apiURLBairros,
});

export const apiBusca = axios.create({
  baseURL: apiURL,
});

apiBusca.interceptors.request.use(async (config) => {
  const token2 = getToken();
  if (token2) {
    config.headers.Authorization = token2;
  }
  return config;
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
