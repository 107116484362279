import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { token } from "../services/dados"
import NavTop from "../Cadastro/Navtop";
import { apareceAlertContato, Toast } from "../components/Alert";
/* eslint eqeqeq: "off", curly: "error" */

export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201 || status === 110) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alert severity={classe}>
                    {param.texto}
                </Alert>
            </Snackbar>
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Cadastro extends Component {
    constructor() {
        super();
        this.state = {
            dados: [],
            alert: {
                status: '',
                message: ''
            },
            cpf: '', nome: '', telefone: '', dataNasc: '', obs: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();

        const { cpf, nome, telefone, dataNasc, obs } = this.state
        var raw = { cpf, nome, telefone, dataNasc, obs }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        await apiBusca.post("/cadastros/contato", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                let propis = this.props
                if (this.state.alert.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: this.state.alert.message
                    }).then(function () {
                        apareceAlertContato()
                    })

                }
                else if (this.state.alert.status === 201) {
                    Toast.fire({
                        icon: 'error',
                        title: this.state.alert.message
                    })
                }
            })

            .catch(error => {
                this.setState({
                    alert: {
                        status: 201,
                        message: "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG"
                    }
                })
            })
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="conteudo">
                <NavTop />
                <br />
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content">
                        <form id="wizard" onSubmit={this.handleSubmit}>
                            <Alerta
                                setOpen={this.state.setOpen}
                                texto={alertMsg.message}
                                classe={alertMsg.status} />
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Contato
              </legend>
                                <div style={{ color: "white" }} className="pv-btn-danger text-center col-md-12">
                                    ATENÇÃO!!!!<br /> ESSE FORMULARIO E SOMENTE PARA PROBLEMAS NO CADASTRO<br />
                                NÃO PREENCHA SE NÃO ESTIVER COM PROBLEMAS</div>
                                <div className="col-md-12 campos-pma pma-text-center card-body">
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-12"
                                        id="nome"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        fullWidth
                                        name="nome"
                                        label="Nome Completo"
                                        variant="outlined"
                                        type="text"
                                        value={this.state.nome}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        onChange={e => this.setState({ nome: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-12"
                                        id="cpf"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        fullWidth
                                        name="cpf"
                                        label="CPF"
                                        variant="outlined"
                                        type="text"
                                        value={this.state.cpf}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        onChange={e => this.setState({ cpf: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-12"
                                        id="dt_nasc_contato"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        fullWidth
                                        name="dataNasc"
                                        label="Data Nascimento"
                                        variant="outlined"
                                        type="text"
                                        placeholder="dd/mm/aaaa"
                                        value={this.state.dataNasc}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        onChange={e => this.setState({ dataNasc: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-12"
                                        id="telefone"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        fullWidth
                                        name="telefone"
                                        label="Telefone"
                                        variant="outlined"
                                        placeholder="(00)0 0000-0000"
                                        type="text"
                                        value={this.state.telefone}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        onChange={e => this.setState({ telefone: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-12"
                                        id="obs"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        fullWidth
                                        name="obs"
                                        label="Observação"
                                        variant="outlined"
                                        type="text"
                                        value={this.state.obs}
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                        onChange={e => this.setState({ obs: e.target.value })}
                                    />
                                </div>
                                <button
                                    id="cadastro_vacina"
                                    className="btn-pv col-md-12 pma-text-center pv-btn-success"
                                    type="submit"
                                >
                                    Enviar
                </button>
                            </fieldset>
                        </form>
                    </section>
                </div>
                <Footer />
            </div >

        );
    }
}
export default Cadastro;