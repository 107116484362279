import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import {
  getRelatoriosDiario,
  getUsers,
  getDatas,
  getRelatoriosDiarioAPLI,
} from "../services/dados";
import { TextField } from "@material-ui/core";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
require("jquery");
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-responsive-bs4");

const columns = [
  {
    title: "Nome",
    width: "auto",
    data: "nome",
  },
  {
    title: "Qtd. de Vacinados",
    width: "auto",
    data: "qtd",
  },
  {
    title: "Abrangência",
    width: "auto",
    data: "abrangencia",
  },
  {
    title: "1º Dose",
    width: "auto",
    data: "1Dose",
  },
  {
    title: "2º Dose",
    width: "auto",
    data: "2Dose",
  },
  {
    title: "3 Dose",
    width: "auto",
    data: "3Dose",
  },
  {
    title: "4 Dose",
    width: "auto",
    data: "4Dose",
  },
  {
    title: "Data Aplicação",
    width: "auto",
    data: "data_br",
  },
];

function DataTable(props) {
  $("#table").DataTable({
    dom: "Bfrtip",
    responsive: {
      details: true,
    },
    buttons: [
      {
        extend: "pdf",
        className: "btn-pdf",
        download: "open",
        text: "PDF",
      },
      {
        extend: "excel",
        className: "btn-excel",
        download: "open",
        text: "Excel",
      },
      { extend: "print", className: "btn-print", text: "Imprimir" },
    ],
    data: props.cadastros,
    columns,
    destroy: true,
    ordering: false,
    language: {
      lengthMenu: "Mostar _MENU_ Registro por Pagina",
      zeroRecords: "Nada foi encontrado",
      info: "Mostrando _PAGE_ de _PAGES_",
      infoEmpty: "Não foram encontrados Registros",
      infoFiltered: "(Filtrados de _MAX_ do Total)",
      search: "Buscar:",
      paginate: {
        first: "Primeiro",
        last: "Ultimo",
        next: "Proximo",
        previous: "Anterior",
      },
    },
    rowCallback: function(row, data) {
      $("td:eq(0)", row).click(function() {
        props.buscaCPF(data.fk_vacina);
      });

      $("td:eq(0)", row).css("cursor", "pointer");
    },
    // "headerCallback": function (thead, data, display) {

    //     $('th:eq(0)', thead).css('display', '')
    //     $('th:eq(1)', thead).css('display', '')
    //     $('th:eq(3)', thead).css('display', '')
    //     $('td:eq(4)', thead).css('white-space', 'normal')
    // },
  });
  return (
    <div>
      <table
        id="table"
        className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer"
        style={{ width: 100 + "%" }}
        refs="main"
      ></table>
    </div>
  );
}

class RelatorioDiario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      dadosUser: [],
      alert: {
        status: "",
        message: "",
      },
      datas: [],
      uDia: "",
      total: "",
    };
    this.getEventos = this.getEventos.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.buscaCPF = this.buscaCPF.bind(this);
  }

  async getEventos() {
    await getDatas().then((response2) => {
      getRelatoriosDiario(response2.data[0].data_aplicacao).then((response) => {
        this.setState({
          dados: response.data,
          style: "show",
          total: response.data[0],
        });
      });
      this.setState({
        datas: response2.data,
        uDia: response2.data[0].data_aplicacao,
      });
    });

    await getUsers().then((response2) => {
      this.setState({ dadosUser: response2.data });
    });
  }

  selectChange = (event) => {
    const id = event.target.value;
    getRelatoriosDiario(id).then((response) => {
      this.setState({
        dados: response.data,
        uDia: id,
        total: response.data[0],
      });
    });
  };

  buscaCPF(teste) {
    let a = this.state.uDia;
    var e = window.btoa(teste + "/" + a);
    return (window.location = "/ListaVacinadosAplicador?" + e);
  }

  componentDidMount() {
    this.getEventos();
  }

  render() {
    var dados = this.state.uDia;
    var total = this.state.total;
    var date = dados.split("-");
    console.log(total);
    var data_br = date[2] + "/" + date[1] + "/" + date[0];
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dadosUser} />
          <Sidebar dados={this.state.dadosUser} />
          <br />
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <h1>Relatorio Diarios</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="/Social">
                    <i className="fa fa-dashboard" />
                    Início
                  </a>
                </li>
              </ol>
            </section>
            {/* Main content */}
            <div>
              <TextField
                required
                className="mb-3 ml-3 col-md-11 required"
                id="nivel"
                select
                fullWidth
                name="nivelAcesso"
                SelectProps={{
                  native: true,
                }}
                value={this.state.nivel}
                onChange={this.selectChange}
                label="Datas"
                variant="outlined"
              >
                <option key={0} value=""></option>
                {this.state.datas.map((option) => (
                  <option
                    key={option.data_aplicacao}
                    value={option.data_aplicacao}
                  >
                    {option.data_br}
                  </option>
                ))}
              </TextField>
            </div>
            <section className="content">
              <span>RELATORIO DO DIA {data_br}</span>
              <br />
              <span style={{ fontWeight: "bold" }}>
                {"Total: " + total?.Total}
                <br />
                {"Total 1º Dose: " + total?.Total1Dose}
                <br />
                {"Total 2º Dose: " + total?.Total2Dose}
                <br />
                {"Total 3º Dose: " + total?.Total3Dose}
                <br />
                {"Total 4º Dose: " + total?.Total4Dose}
                <br />
              </span>
              <DataTable
                style={this.state.style}
                cadastros={this.state.dados}
                buscaCPF={this.buscaCPF}
              />
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export class RelatorioDiarioAplicador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      dadosUser: [],
      alert: {
        status: "",
        message: "",
      },
      datas: [],
    };
    this.getEventos = this.getEventos.bind(this);
    this.selectChange = this.selectChange.bind(this);
  }

  getEventos() {
    getUsers().then((response2) => {
      getDatas().then((response2) => {
        getRelatoriosDiarioAPLI(response2.data[0].data_aplicacao).then(
          (response) => {
            this.setState({ dados: response.data, style: "show" });
          }
        );
        this.setState({ datas: response2.data, uDia: response2.data[0].data });
      });
      this.setState({ dadosUser: response2.data });
    });
  }

  selectChange = (event) => {
    const id = event.target.value;
    getRelatoriosDiario(id).then((response) => {
      this.setState({ dados: response.data, uDia: id });
    });
  };
  async buscaCPF(teste) {
    var e = window.btoa(teste);
    return (window.location = "/CadastroCpf?" + e);
  }

  componentDidMount() {
    this.getEventos();
  }

  render() {
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dadosUser} />
          <Sidebar dados={this.state.dadosUser} />
          <br />
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
              <h1>Relatorio Diarios Aplicador</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="/Social">
                    <i className="fa fa-dashboard" />
                    Início
                  </a>
                </li>
              </ol>
            </section>
            {/* Main content */}
            <div>
              <TextField
                required
                className="mb-3 ml-3 col-md-11 required"
                id="nivel"
                select
                fullWidth
                name="nivelAcesso"
                SelectProps={{
                  native: true,
                }}
                value={this.state.nivel}
                onChange={this.selectChange}
                label="Datas"
                variant="outlined"
              >
                <option key={0} value=""></option>
                {this.state.datas.map((option) => (
                  <option
                    key={option.data_aplicacao}
                    value={option.data_aplicacao}
                  >
                    {option.data_aplicacao}
                  </option>
                ))}
              </TextField>
            </div>
            <section className="content">
              <span>RELATORIO DO DIA {this.state.uDia}</span>
              <DataTable
                style={this.state.style}
                cadastros={this.state.dados}
                buscaCPF={this.buscaCPF}
              />
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default RelatorioDiario;
