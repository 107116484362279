import { TextField, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { apiNova } from "../services/api";

export default function ConfirmarAplicacao({ props, aplicacao }) {
  const { unidades, dados, dadosUser, aplicacoes } = props.aplicadorDTO;

  const history = useHistory();

  const handleSubmit = async () => {
    await Axios.post(`${apiNova}/api/v1/vacinados/`, aplicacao)
      .then(function(response) {
        if (response.data.success) {
          alert("Vacina Adicionada Com Sucesso");
          history.go(0);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  let yourDate = new Date(aplicacao.dt_aplicacao);
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
  const dataAplicacao = yourDate.toLocaleDateString("pt-BR");

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>CONFIRMAR APLICAÇÃO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Typography variant="h4">Nome: {dados.nome}</Typography>
        <Typography variant="h4">CPF: {dados.cpf}</Typography>
        <Typography variant="h4">Dose: {aplicacao.dose_vacina}</Typography>
        <Typography variant="h4">Data: {dataAplicacao}</Typography>
        <Typography variant="h4">
          Aplicador: {aplicacao.nome_aplicador}
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Confirmar Aplicação
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
