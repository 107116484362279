import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import { Redirect, Route } from "react-router";
import Acesso from "../components/Acessar";
// import { Bar } from "react-chartjs-2"
import { getDadosGrafico, getAvisos, getVagasCidadao } from "../services/dados"
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dadosEntregues: [],
            dadosAvisos: [],
            dadosCount: [],
            vagos: '',
            limite: '',
            alert: {
                status: '',
                message: ''
            },
            termo: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
    }

    async getEventos() {
        await getVagasCidadao().then(response2 => {
            this.setState({
                vagos: response2.data[0],
                limite: response2.data[1],
                idadeCad: response2.data[3]
            })
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        return (window.location = "/CadastrarComorbidades");
    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        let button
        if (this.state.termo === true) { button = "btn-pv col-md-12 text-center pv-btn-primary" }
        else { button = "btn-pv col-md-12 text-center pv-btn-danger" }

        var maximo = 900000
        var totalAtual = this.state.vagos.qtd

        if (totalAtual > maximo) {
            return (
                <div className="content-wrapper">
                    {/* Main content */}
                    <section className="content btn-danger col-md-12">
                        <div style={{ fontWeight: "bolder", fontSize: "70px" }} className="text-center">
                            Sistema Indisponível
          </div>
                    </section>
                </div>
            )
        } else {

            return (
                <div className="home">
                    <div className='col-md-12'>
                        <h1 className="text-center">
                            Cadastro #IMUNIZAANÁPOLIS
          </h1>
                        <hr />
                        <h1 style={{ fontWeight: 'bold', color: 'red' }} className="text-center">LEIA COM ATENÇÃO!</h1>
                        <div className="pma-text-center mb-3">
                            <br />Documento para comprovação de comorbidades: Formulário preenchido ou ou relatório médico que descreva a comorbidade em questão,
<br />que esteja assinado por médico e tenha sido emitido há menos de 12 meses.<br /><br />

                            <b>Cadastro exclusivo para os seguintes grupos:</b><br /><br />

                            <b> Pessoas com Síndrome de Down, a partir de 18 anos.</b> <br />Documentos necessários: CPF, RG ou CNH, comprovante de endereço;<br />
                            <b>Pessoas com doença renal crônica em diálise, a partir de 18 anos.</b> <br />Documentos necessários: CPF, RG ou CNH, comprovante de endereço e laudo médico;<br />
                            <b>Gestantes, a partir de 18 anos.</b><br /> Documentos necessários: CPF, RG ou CNH, comprovante de endereço e exame comprobatório;<br />
                            <b>Puérperas com até 45 dias pós-parto, a partir de 18 anos.</b><br /> Documentos necessários: CPF, RG ou CNH, comprovante de endereço e certidão de nascimento da criança;<br />
                            <b>Pessoas com próteses valvares e dispositivos cardíacos implantados, de 18 a 59 anos.</b><br /> Documentos necessários: CPF, RG ou CNH, comprovante de endereço e documento de identificação de portador de marcapasso;<br />
                            <b>Pessoas com comorbidades, de 18 a 59 anos, (confira a lista abaixo).</b> <br />Documentos necessários: CPF, RG ou CNH, comprovante de endereço e laudo médico.<br />
                            <br />

Lista de comorbidades:<br /><br />
                            <i>
                                DIABETES MELLITUS - Qualquer indivíduo com diabetes.<br />
                                <br />
PNEUMOPATIAS CRÔNICAS GRAVES - Indivíduos compneumopatias graves incluindo doença pulmonar obstrutiva crônica, ﬁbrose cística, ﬁbroses pulmonares, pneumoconioses, displasia broncopulmonar e asma grave (uso recorrente de corticoides sistêmicos, internação prévia por crise asmática).
 <br /><br />
HIPERTENSÃO ARTERIAL RESISTENTE (HAR) - Quando a pressão arterial (PA) permanece acima das metas recomendadas com o uso de três ou mais anti-hipertensivos de diferentes classes, em doses máximas preconizadas e toleradas, administradas com frequência, dosagem apropriada e comprovada adesão ou PA controlada em uso de quatro ou mais fármacos antihipertensivos.
<br /><br />
HIPERTENSÃO ARTERIAL ESTÁGIO 3 - PA sistólica≥180mmHg e/ou diastólica ≥110mmHg independente da presença de lesão em órgão-alvo (LOA) ou comorbidade.
<br /><br />
HIPERTENSÃO ARTERIAL ESTÁGIOS 1 E 2 COMLESÃO EM ÓRGÃO-ALVO E/OU COMORBIDADE - PA sistólica entre 140 e 179mmHg e/ou diastólica entre 90 e 109mmHg na presença de lesão em órgão-alvo e/ou comorbidade.
 <br /><br />
INSUFICIÊNCIA CARDÍACA (IC) - IC com fração deejeção reduzida, intermediária ou preservada; em estágios B, C ou D, <br />
independente de classe funcional da New York HeartAssociation.<br />
                                <br />
COR-PULMONALE E HIPERTENSÃO PULMONAR - Cor-pulmonale crônico, hipertensão pulmonar primária ou secundária.<br />
                                <br />
CARDIOPATIA HIPERTENSIVA - Cardiopatiahipertensiva (hipertroﬁa ventricular esquerda ou dilatação,<br />
sobrecarga atrial e ventricular, disfunção diastólica e/ousistólica, lesões em outros órgãos alvo).<br />
                                <br />
SÍNDROMES CORONARIANAS - Síndromes coronarianascrônicas (Angina Pectoris estável, cardiopatia isquêmica, pós Infarto Agudo do Miocárdio, outras).<br />
                                <br />
VALVOPATIAS - Lesões valvares com repercussãohemodinâmica ou sintomática ou com comprometimento <br />
miocárdico (estenose ou insuﬁciência aórtica; estenose ouinsuﬁciência mitral; estenose ou insuﬁciência pulmonar; <br />
estenose ou insuﬁciência tricúspide, e outras).<br />
                                <br />
MIOCARDIOPATIAS E PERICARDIOPATIAS - Miocardiopatias de quaisquer etiologias ou fenótipos;pericardite crônica; cardiopatia reumática.<br />
                                <br />
DOENÇAS DA AORTA, DOS GRANDES VASOS EFÍSTULAS ARTERIOVENOSAS -<br />
Aneurismas, dissecções, hematomas da aorta e demais grandes vasos.<br />
                                <br />
ARRITMIAS CARDÍACAS- Arritmias cardíacas com importância clínica e/ou cardiopatia associada (ﬁbrilação e ﬂutter atriais; e outras).<br />
                                <br />
CARDIOPATIAS CONGÊNITA NO ADULTO - Cardiopatias congênitas com repercussão hemodinâmica,<br />
crises hipoxêmicas; insuﬁciência cardíaca; arritmias; comprometimento miocárdico.<br />
                                <br />
PRÓTESES VALVARES E DISPOSITIVOS CARDÍACOSIMPLANTADOS - Portadores de  próteses valvares <br />
biológicasou mecânicas; e dispositivos cardíacos implantados (marca-passos, cardio desﬁbriladores, <br />
ressincronizadores, assistência circulatória de média e longa permanência).<br />
                                <br />
DOENÇA CEREBROVASCULAR - Acidente vascular cerebral isquêmico ou hemorrágico; ataque isquêmicotransitório; demência vascular.<br />
                                <br />
DOENÇA RENAL CRÔNICA - Doença renal crônicaestágio 3 ou mais (taxa de ﬁltração glomerular {'<'} 60ml/min/1,73 m2) e/ou síndrome nefrótica.<br />
                                <br />
IMUNOSSUPRIMIDOS - Indivíduos transplantados de órgãosólido ou de medula óssea; pessoas vivendo com HIV; <br />
doençasreumáticas imunomediadas sistêmicas em atividade e em usode dose de prednisona ou equivalente {'>'} 10 mg/dia <br />
ou recebendo pulsoterapia com corticoide e/ou ciclofosfamida; demais indivíduos em uso de imunossupressores ou <br />
comimunodeﬁciências primárias; pacientes oncológicos querealizaram tratamento quimioterápico ou radioterápico <br />
nos últimos 6 meses; neoplasias hematológicas.<br />
                                <br />
HEMOGLOBINOPATIAS GRAVES - Doença falciforme etalassemia maior.<br />
                                <br />
OBESIDADE MÓRBIDA - Índice de massa corpórea (IMC) ≥40 kg/m2.<br />
                                <br />
SÍNDROME DE DOWN - Trissomia do cromossomo 21.<br />
                                <br />
CIRROSE HEPÁTICA - Cirrose hepática Child-Pugh A, Bou C.<br />
                                <br />
GESTANTES OU PUÉRPERAS - com idades entre 18 a 59 anos, até 45 (quarenta e cinco) dias após o parto.
</i>
                            <br /><br />
                            <div style={{ color: "white" }} className="pv-btn-danger text-center col-md-12">
                                <b >                            ATENÇÃO!<br /><br />
                            Ao inserir os laudos ou relatório médico confira se a sua comorbidade está contemplada na lista acima descrita.<br />
                            Lembrando que receitas de medicamentos não são válidas para comprovação de comorbidade.<br />
                            No ato da vacinação serão verificados os dados do laudo ou relatório médico,<br />
                            e a vacinação poderá ser bloqueada no momento, em caso de laudos emitidos há mais 12 meses,<br />
                            relatório médico sem assinatura e carimbo, receitas de medicamentos e comorbidades que não<br />
                            estejam na lista de prioridades estabelecida pelo Ministério da Saúde.<br />
                                </b>  </div>                          <b><br /><br />
                                Antes de iniciar, tenha todos os documentos em arquivo digital (foto ou PDF), pois estes documentos são obrigatórios para a finalização do seu cadastro.
</b><br />
Antes de iniciar, é necessário ler e aceitar o Termo abaixo.
<br />
                            <object style={{ height: '400PX', width: '120%' }} data="https://cdn.anapolis.go.gov.br/img/comorbidades/termo.pdf" type="application/pdf">
                                <p>Leia o pdf <a href="https://cdn.anapolis.go.gov.br/img/comorbidades/termo.pdf">aqui!</a></p>
                            </object><br />
                            <div style={{ color: "white" }} className="pv-btn-danger text-center col-md-12">
                                Atenção: Para evitar erros, utilize preferencialmente<br />
            os navegadores <b>Google Chrome ou Mozilla Firefox em um Computador.</b></div>
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <FormControlLabel control={
                                    <Checkbox
                                        label="termo"
                                        name='termo'
                                        checked={this.state.termo}
                                        onChange={e => this.setState({ termo: e.target.checked })}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                } label="Eu li e estou de acordo" />
                                <button
                                    hidden={this.state.termo === false}
                                    disabled={this.state.termo === false}
                                    id="cadastro_vacina"
                                    className={button}
                                    type="submit"
                                >
                                    Cadastrar
                </button>
                            </form>
                        </div>
                    </div>
                </div >
            );
        }
    }
}
export default Content;
