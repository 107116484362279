import React, { Component } from "react";
import { TextField, Tooltip } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { getUsers, token, getCadastrados, getVagas } from "../services/dados"
/* eslint eqeqeq: "off", curly: "error" */
const { cpf } = require('cpf-cnpj-validator');
const $ = require('jquery');



export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe
  var classe
  if (status === 200) {
    classe = "success"
  } else if (status === 201 || status === 110) {
    classe = "error"
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>
          {param.texto}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Cadastro extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: '',
        message: ''
      },
      cpf: '',
      tipRes: '',
      idade: '',
      nome: '',
      dtNascimento: '',
      tipo_pne: '',
      vagos: '',
      limite: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  getEventos() {
    getUsers().then(response2 => {
      this.setState({ dados: response2.data })
    });
    getVagas().then(response2 => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1]
      })
    });
  }

  validPhone(phone) {
    const isCelular = phone.length >= 14;
    if (isCelular === false) {
      $('#telefone').addClass('telefoneErr');
      $('#cadastro_vacina').attr('disabled', true);
      $('#cadastro_vacina').addClass('btn-danger');
      $('#cadastro_vacina').removeClass('btn-primary');
    } else {
      $('#telefone').removeClass('telefoneErr')
      $('#cadastro_vacina').removeAttr('disabled', false);
      $('#cadastro_vacina').removeClass('btn-danger');
      $('#cadastro_vacina').addClass('btn-primary');
    }
  }

  componentDidMount() {
    this.getEventos();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false })
      var cpf = this.state.cpf
      var e = window.btoa(cpf)
      return (window.location = "/CadastroCpf?" + e);
    }, 1 * 1000)
  }

  calculaIdade(dataNasc) {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
    if (mesAtual < mesNasc) {
      idade--;
    } else {
      //Se estiver no mes do nascimento, verificar o dia
      if (mesAtual == mesNasc) {
        if (new Date().getDate() < diaNasc) {
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
          idade--;
        }
      }
    }
    return idade;
  }
  buscadtNascimento = event => {
    var data
    if (event.target === undefined) {
      data = event
    } else {
      data = event.target.value
    }
    var date1 = data.split("-")
    date1 = date1[2] + "/" + date1[1] + "/" + date1[0]
    var teste = this.calculaIdade(date1)
    this.setState({ idade: teste })
  }

  buscaCpf = event => {
    var id = event.target.value;
    let validCPF = cpf.isValid(id)

    getCadastrados(id).then(response => {
      console.log(response.data.data_nasc != null)
      if (response.data.data_nasc != null) {
        this.setState({ nome: response.data.nome, dtNascimento: response.data.data_nasc })
        this.buscadtNascimento(this.state.dtNascimento)
        $('#telefone').focus()
        $('#nome').attr('readonly', true);
        $('#dataNasc').attr('readonly', true);
      }
      if (response.data.cadastrado >= 1) {
        alert("Usuario já Cadastrado, Você será redirecionado para o perfil do cadastrado")
        var e = window.btoa(id)
        this.setState({ cpf: '' })
        setTimeout(() => {
          $("#resposta").html('');
        }, 1 * 1000)
        return (window.location = "/CadastroCpf?" + e)
      } else {
        setTimeout(() => {
          $("#resposta").html('');
        }, 1 * 100)
      }
    })

  }

  valueCPF = (event) => {
    this.setState({ cpf: event.target.value })
  };

  async handleSubmit(event) {
    event.preventDefault();
    const { cpf, nome, telefone, dtNascimento, idade, bairro } = this.state
    var raw = { cpf, nome, telefone, dtNascimento, idade, bairro }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    await apiBusca.post("/cadastros/formAbrigo", requestOptions)
      .then(response => {
        this.setState({
          alert: {
            status: response.data.response.status,
            message: response.data.response.result
          }
        })
        this.setState({ setOpen: true })
        if (this.state.alert.status === 200) {
          this.apareceAlert();
        }

      })
      .catch(error => {
        this.setState({
        })
        this.setState({ setOpen: true })
      })
    //event.target.reset();
  }

  render() {
    var maximo = this.state.limite.qtd
    var totalAtual = this.state.vagos.qtd
    var alertMsg = this.state.alert
    if (totalAtual > maximo) {
      return (
        <div className="skin-blue bg-light sidebar-mini" id="panel">
          <div className="wrapper">
            <NavTop dados={this.state.dados} />
            <Sidebar dados={this.state.dados} />
            <br />
            <div className="content-wrapper">
              {/* Main content */}
              <section className="content btn-danger col-md-12">
                <div style={{ fontWeight: "bolder", fontSize: "70px" }} className="text-center">
                  Sistema Indisponível
                </div>
              </section>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status} />
                <fieldset className="card">
                  <legend className="card-header text-left legend-pma faq-btn-link">
                    Informações importantes
                  </legend>
                  <div id='resposta'></div>
                  <div className="col-md-12 campos-pma card-body">
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-5"
                      id="cpfAbrigo"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="cpf"
                      label="C.P.F."
                      variant="outlined"
                      value={this.state.cpf}
                      type="text"
                      onBlur={this.buscaCpf}
                      onChange={e => this.setState({ cpf: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="nome"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="nome"
                      label="Nome"
                      variant="outlined"
                      type="text"
                      value={this.state.nome}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={e => this.setState({ nome: e.target.value })}
                    />
                    <TextField
                      required
                      className="mb-3 ml-3 col-md-5"
                      id="dataNasc"
                      InputProps={{
                        readOnly: false
                      }}
                      fullWidth
                      name="dtNascimento"
                      label="Data de Nascimento"
                      variant="outlined"
                      type="date"
                      value={this.state.dtNascimento}
                      onBlur={this.buscadtNascimento}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={e => this.setState({ dtNascimento: e.target.value })}
                    />
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-6"
                      id="idade"
                      fullWidth
                      name="idade"
                      label="Idade"
                      variant="outlined"
                      type="number"
                      value={this.state.idade}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <Tooltip
                      title="Digitar DDD e numero com 9 Ex. (62) 9 9999-9999"
                      placement="top"
                    >
                      <TextField
                        placeholder="Ex. (62) 9 9999-9999"
                        required
                        className="mb-3 ml-3 col-md-5"
                        id="telefone"
                        InputProps={{
                          readOnly: false
                        }}
                        fullWidth
                        name="telefone"
                        label="Telefone"
                        variant="outlined"
                        type="text"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                        onKeyDown={e => this.validPhone(e.target.value)}
                        onChange={e => this.setState({ telefone: e.target.value })}
                      />
                    </Tooltip>
                  </div>
                </fieldset>
                <fieldset className="card ">
                  <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                    BAIRRO
                  </legend>
                  <br />
                  <div className="col-md-12 campos-pma">
                    <div className="col-md-12">
                      <Tooltip
                        title="Ex. HUANA, HMJC, USB vila união, C.I. Norma"
                        placement="top"
                      >
                        <TextField
                          required
                          placeholder="Ex. HUANA, HMJC, USB vila união, C.I. Norma"
                          className="ml-3 mb-3 col-md-12"
                          id="bairro"
                          InputProps={{
                            readOnly: false
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          name="bairro"
                          label="Abrigo"
                          variant="outlined"
                          type="text"
                          value={this.state.bairro}
                          inputProps={{
                            maxLength: 200,
                          }}
                          onChange={e => this.setState({ bairro: e.target.value })}
                        />
                      </Tooltip>
                    </div>
                    <button
                      id="cadastro_vacina"
                      className="btn-pv col-md-12 text-center pv-btn-primary"
                      type="submit"
                    >
                      Cadastrar
                    </button>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Cadastro;
