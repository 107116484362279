import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { getCadastradosListasAcamados4dose, getUsers } from "../services/dados"
import { logoExport } from '../services/logo'
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */
const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

export function dateHoje() {
    var data = new Date();
    var mes
    // Guarda cada pedaço em uma variável
    var dia = data.getDate();           // 1-31
    var mesn = data.getMonth();          // 0-11 (zero=janeiro)
    var ano4 = data.getFullYear();       // 4 dígitos
    var hora = data.getHours();          // 0-23
    //var hora = 11
    var min = data.getMinutes();        // 0-59
    if (mesn < 9) { mes = '0' + (mesn + 1) }
    else { mes = (mesn + 1) }
    if (hora < 9) { hora = '0' + hora }
    if (min < 9) { min = '0' + min }

    // Formata a data e a hora (note o mês + 1)
    var str_dataFormat = dia + '/' + mes + '/' + ano4 + ' ' + hora + ':' + min;
    return str_dataFormat
}
var logo = logoExport

export const columns = [
    {
        title: 'Nome',
        width: "auto",
        data: 'nome'
    },
    {
        title: 'C.P.F.',
        width: "auto",
        data: 'cpf'
    },
    {
        title: 'Telefone',
        width: "auto",
        data: 'telefone'
    },
    {
        title: 'Endereço',
        width: "auto",
        data: 'rua'
    },
    {
        title: 'Bairro',
        width: "auto",
        data: 'bairro'
    },
    {
        title: 'Lote',
        width: "auto",
        data: 'lote_vacina'
    },
    {
        title: 'Vacina',
        width: "auto",
        data: 'vacina'
    },
    {
        title: 'Acamado',
        width: "auto",
        data: 'acamado'
    },
    {
        title: 'Data 4º Dose',
        width: "auto",
        data: '4dose'
    },
    // {
    //     title: 'Dt. da Aplicação',
    //     width: "auto",
    //     data: 'dt_aplicacao'
    // },
    // {
    //     title: 'Vacinado',
    //     width: "auto",
    //     data: 'vacinado'
    // }
];

export function DataTable(props) {
    var usuarios = props.usuario
    var table = $('#table').DataTable({
        dom: 'Bfrtip',
        responsive: true,
        buttons: [
            {
                extend: 'pdf', className: 'btn-pdf', text: 'PDF',
                orientation: 'landscape',
                pageSize: 'A4',
                filename: "Lista_Geral" + dateHoje(),
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 8],

                },
                footer: {
                    columns: [
                        'Left part',
                        { text: 'Right part', alignment: 'right' }
                    ]
                },
                customize: function (doc) {
                    var custom = doc.content[1].table
                    custom.widths = ['12%', '12%', '12%', '24%', '10%', '10%', '12%', '10%']
                    var objLayout = {};
                    objLayout['hLineWidth'] = function (i) { return .5; };
                    objLayout['vLineWidth'] = function (i) { return .5; };
                    objLayout['hLineColor'] = function (i) { return '#aaa'; };
                    objLayout['vLineColor'] = function (i) { return '#aaa'; };
                    objLayout['paddingLeft'] = function (i) { return 4; };
                    objLayout['paddingRight'] = function (i) { return 4; };
                    doc.content[1].layout = objLayout;
                    doc['header'] = (function () {
                        return {
                            columns: [
                                {
                                    alignment: 'center',
                                    image: logo,
                                    width: 220
                                },
                            ],
                            padding: 30
                        }
                    });
                    doc['footer'] = (function (page, pages,) {
                        // console.log(usuario)
                        return {
                            columns: [
                                {
                                    alignment: 'left',
                                    text: [dateHoje()]
                                },
                            ],
                            margin: 20
                        }
                    });
                }
            },
            {
                extend: 'excel', className: 'btn-excel', download: 'open', text: 'Excel',
                orientation: 'landscape',
                filename: "Lista_Geral" + dateHoje(),
                pageSize: 'A4',
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                },
            },
            // {
            //     extend: 'print', className: 'btn-print', text: 'Imprimir',
            //     orientation: 'landscape',
            //     pageSize: 'A4',
            //     layout: 'border solid 1px',
            //     exportOptions: {
            //         columns: [0, 1, 2, 3]
            //     },
            // }
        ],

        data: props.cadastros,
        columns,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {
            $('td:eq(0)', row).click(
                function () {
                    props.buscaCPF(data.cpf);
                })
            $('td:eq(1)', row).click(
                function () {
                    props.buscaCPF(data.cpf);
                })


            $('td:eq(3)', row).css('display', '')
            $('td:eq(1)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
        },
        "headerCallback": function (thead, data, display) {

            $('th:eq(0)', thead).css('display', '')
            $('th:eq(1)', thead).css('display', '')
            $('th:eq(3)', thead).css('display', '')
            $('td:eq(4)', thead).css('white-space', 'normal')
        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}

class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            unidades: [],
            vacinas: [],
            usuario: '',
            alert: {
                status: '',
                message: ''
            },
        };
        this.getEventos = this.getEventos.bind(this);
        this.buscaCPF = this.buscaCPF.bind(this)
    }

    async getEventos() {
        getUsers().then(response2 => {
            this.setState({
                dados: response2.data,
                usuario: response2.data.nome
            })
        });
        await getCadastradosListasAcamados4dose().then(response2 => {
            this.setState({
                cadastros: response2.data
            })
        });
    }

    async buscaCPF(cpf) {
        let cpf2 = btoa(cpf)
        return (window.location = "/CadastroCpf?" + cpf2)

    }

    componentDidMount() {
        this.getEventos();
    }
    render() {
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Lista Acamados Não Vacinados 4º Dose
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início
                                    </a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <DataTable
                                cadastros={this.state.cadastros}
                                usuario={this.state.usuario}
                                buscaCPF={this.buscaCPF} />
                        </section>
                    </div>
                    <Footer />
                </div >
            </div>
        );
    }
}
export default Panel;