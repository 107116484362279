import React, { Component } from "react";
import Sidebar from "../Vacina/Sidebar";
import NavTop from "../Vacina/Navtop";
import Footer from "../components/Footer";
import { getUsers, token, getAplicador } from "../services/dados"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { apiBusca } from "../services/api";
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alertin severity={classe}>
                    {param.texto}
                </Alertin>
            </Snackbar>
        </div>
    );
}
function Alertin(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class GerarOcorrencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            bairros: [],
            bairro: '',
            equipes: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getEventos() {
        getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
        getAplicador().then(response2 => {
            this.setState({ equipes: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    handleSubmit(event) {
        event.preventDefault();
        const { equipe, ocorrencia } = this.state
        var raw = { equipe, ocorrencia }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        apiBusca.post("/listas/cadOcorrencias", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                this.setState({ setOpen: true })
                if (this.state.alert.status == 200) {
                    this.apareceAlert();
                }

            })
            .catch(error => {
                console.log(error.data)
                this.setState({
                })
                this.setState({ setOpen: true })
            })


    }

    apareceAlert() {
        setTimeout(() => {
            this.setState({ setOpen: false })
            return (window.location = "/GerarOcorrencia")
        }, 1 * 1000)
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Gerar Listas por Bairros
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início
              </a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <fieldset className="card">
                                    <legend className="card-header text-left legend-pma faq-btn-link">
                                        Informações importantes
              </legend>
                                    <div id='resposta'></div>
                                    <div className="col-md-12 campos-pma card-body">
                                        <div className="col-md-12">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.equipes}
                                                getOptionLabel={(option) => option.nome}
                                                onChange={(option, value) => {
                                                    if (value != null) {
                                                        this.setState({
                                                            equipe: value.id,
                                                        })
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        id="equipe"
                                                        label="Aplicador"
                                                        required
                                                        name="equipe"
                                                        variant="outlined" />}
                                            />
                                            <TextField
                                                required
                                                className=" mb-3 col-md-12"
                                                id="ocorrencia"
                                                fullWidth
                                                name="ocorrencia"
                                                label="Ocorrência"
                                                variant="outlined"
                                                type="text"
                                                onChange={e => this.setState({ ocorrencia: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <button
                                    className="btn-pv col-md-12 text-center btn-primary"
                                    type="submit"
                                >
                                    Cadastrar
                </button>
                            </form>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>

        )
    }
}