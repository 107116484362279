import { Button } from "bootstrap";
import React, { Component } from "react";
// import { Bar } from "react-chartjs-2"
import { getVagasCidadao } from "../services/dados";
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dadosEntregues: [],
      dadosAvisos: [],
      dadosCount: [],
      vagos: "",
      limite: "",
      alert: {
        status: "",
        message: "",
      },
      termo: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  async getEventos() {
    await getVagasCidadao().then((response2) => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1],
        idadeCad: response2.data[3],
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    return (window.location = "/CadastrarPopulacao");
  }

  componentDidMount() {
    this.getEventos();
  }

  render() {
    return (
      <div className="home">
        <div className="col-md-12">
          <h1 className="text-center">Cadastro #IMUNIZAANÁPOLIS</h1>
          <hr />
          <div className="pma-text-center mb-3">
            <a
              href="/CadastroComorbidades"
              style={{ color: "white", whiteSpace: "break-spaces" }}
              className="btn btn-pv col text-center pv-btn-primary ml-2 mt-2"
            >
              Cadastro Comorbidades, Gestantes, Puérperas
            </a>
            <a
              href="/CadastroCidadao"
              style={{ color: "white", whiteSpace: "break-spaces" }}
              className="btn btn-pv col text-center pv-btn-primary ml-2 mr-2 mt-2"
            >
              Cadastro Cidadão
            </a>
            <a
              href="/CadastroAdolescentes"
              style={{ color: "white", whiteSpace: "break-spaces" }}
              className="btn btn-pv col text-center pv-btn-primary ml-2 mr-2 mt-2"
            >
              Cadastro Adolescentes
            </a>
            <a
              href="/CadastroComorbidadesAdolescentes"
              style={{ color: "white", whiteSpace: "break-spaces" }}
              className="btn btn-pv col text-center pv-btn-primary ml-2 mr-2 mt-2"
            >
              Cadastro Comorbidades Adolescentes
            </a>
            <a
              href="/CadastroCriancas"
              style={{ color: "white", whiteSpace: "break-spaces" }}
              className="btn btn-pv col text-center pv-btn-primary ml-2 mr-2 mt-2"
            >
              Cadastro Crianças
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Content;
