import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  makeStyles,
  Radio,
  Tooltip,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  token,
  getUnidades,
  getUsers,
  getVacinas,
  getUnidadesDIST,
  getLaboratorios,
} from "../services/dados";
import { apiBusca } from "../services/api";
import { apareceAlertHistory, Toast } from "../components/Alert";
import Axios from "axios";

export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe;
  var classe;
  if (status === 200) {
    classe = "success";
  } else if (status === 201 || status === 110) {
    classe = "error";
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>{param.texto}</Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      unidades: [],
      vacinas: [],
      alert: {
        status: "",
        message: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  getEventos() {
    getUsers().then((response2) => {
      this.setState({
        dados: response2.data,
      });
      if (response2.data.acesso === 0) {
        return (window.location = "/TrocarSenha");
      }
      getUnidadesDIST().then((response) => {
        this.setState({ unidades: response.data });
      });

      Axios.get("https://api.anapolis.go.gov.br/apivacina/api/v1/vacinas").then(
        (response) => {
          this.setState({ vacinas: response.data.result });
        }
      );
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { vacina, qtd, unidade, lote } = this.state;
    var raw = { vacina, qtd, unidade, lote };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    //console.log(raw)
    apiBusca
      .post("/cadastros/repasseVacina", requestOptions)
      .then((response) => {
        this.setState({
          alert: {
            status: response.data.response.status,
            message: response.data.response.result,
          },
        });
        this.setState({ setOpen: true });
        if (response.data.response.status === 200) {
          this.apareceAlert();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: {
            status: 201,
            message: "ERRO",
          },
        });
        this.setState({ setOpen: true });
      });
    //event.target.reset();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false });
      return (window.location = "/CadastrarLotes");
    }, 1 * 1000);
  }
  componentDidMount() {
    this.getEventos();
  }

  render() {
    console.log(this.state.vacinas);
    var alertMsg = this.state.alert;
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <div className="content-wrapper">
            <section className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h3 className="box-title">Cadastro de Lotes de Vacina</h3>
                    </div>
                    <section className="content">
                      <form id="wizard" onSubmit={this.handleSubmit}>
                        <Alerta
                          setOpen={this.state.setOpen}
                          texto={alertMsg.message}
                          classe={alertMsg.status}
                        />
                        <fieldset className="card">
                          <legend className="card-header text-left legend-pma faq-btn-link">
                            Informações importantes
                          </legend>
                          <div id="resposta"></div>
                          <div className="col-md-12 campos-pma card-body">
                            <div className="col-md-12">
                              <Autocomplete
                                id="combo-box-demo"
                                options={this.state.vacinas}
                                getOptionLabel={(option) =>
                                  option.nome +
                                  " - " +
                                  option.lote +
                                  " - " +
                                  option.dose +
                                  "ºDose"
                                }
                                onChange={(option, value) => {
                                  if (value != null) {
                                    this.setState({
                                      vacina: value.id,
                                      lote: value.lote,
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="vacina"
                                    label="Vacina"
                                    required
                                    name="vacina"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </div>
                            <TextField
                              required
                              className="ml-3 mb-3 col-md-12"
                              id="qtd"
                              InputProps={{
                                readOnly: false,
                              }}
                              fullWidth
                              name="qtd"
                              label="Qtd"
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                this.setState({ qtd: e.target.value })
                              }
                            />

                            <div className="col-md-12">
                              <Autocomplete
                                id="combo-box-demo"
                                options={this.state.unidades}
                                getOptionLabel={(option) =>
                                  option.nome + " - " + option.abrangencia
                                }
                                onChange={(option, value) => {
                                  if (value != null) {
                                    this.setState({ unidade: value.id });
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    id="unidades"
                                    label="Aplicador"
                                    required
                                    name="unidade"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </div>
                            <button
                              className="btn-pv col-md-12 text-center btn-success"
                              type="submit"
                            >
                              Cadastrar Vacina
                            </button>
                          </div>
                        </fieldset>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Panel;

export class CadastroGeralVacina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      unidades: [],
      vacinas: [],
      laboratorios: [],
      laboratorio: "",
      dose: "1",
      dias: "0",
      validade: "",
      recebimento: "",
      alert: {
        status: "",
        message: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  getEventos() {
    getUsers().then((response2) => {
      this.setState({
        dados: response2.data,
      });
      getUnidades().then((response) => {
        this.setState({ unidades: response.data });
      });

      getLaboratorios().then((response) => {
        this.setState({ laboratorios: response.data });
      });

      getVacinas().then((response) => {
        this.setState({ vacinas: response.data });
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      dias,
      vacina,
      qtd,
      lote,
      laboratorio,
      dose,
      validade,
      recebimento,
    } = this.state;
    var raw = {
      dias,
      vacina,
      qtd,
      lote,
      laboratorio,
      dose,
      validade,
      recebimento,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    apiBusca
      .post("/cadastros/cadastrarVacina", requestOptions)
      .then((response) => {
        this.setState({
          alert: {
            status: response.data.response.status,
            message: response.data.response.result,
          },
        });
        let propis = this.props;
        if (this.state.alert.status === 200) {
          Toast.fire({
            icon: "success",
            title: this.state.alert.message,
          }).then(function() {
            apareceAlertHistory(propis);
          });
        } else if (this.state.alert.status === 201) {
          Toast.fire({
            icon: "error",
            title: this.state.alert.message,
          });
        }
      })

      .catch((error) => {
        this.setState({
          alert: {
            status: 201,
            message:
              "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG",
          },
        });
      });
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false });
      return (window.location = "/CadastrarLotes");
    }, 1 * 1000);
  }
  componentDidMount() {
    this.getEventos();
  }
  render() {
    var alertMsg = this.state.alert;
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <div className="content-wrapper">
            <section className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h3 className="box-title">Cadastro de Vacinas</h3>
                    </div>
                    <section className="content">
                      <form id="wizard" onSubmit={this.handleSubmit}>
                        <Alerta
                          setOpen={this.state.setOpen}
                          texto={alertMsg.message}
                          classe={alertMsg.status}
                        />
                        <fieldset className="card">
                          <legend className="card-header text-left legend-pma faq-btn-link">
                            Informações importantes
                          </legend>
                          <div id="resposta"></div>
                          <div className="col-md-12 campos-pma card-body">
                            <Autocomplete
                              className="col-md-12"
                              id="laboratorio"
                              options={this.state.laboratorios}
                              getOptionLabel={(option) => option.label}
                              onChange={(option, value) => {
                                if (value != null) {
                                  this.setState({
                                    laboratorio: value.label,
                                    vacina: value.vacina,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  id="laboratorio"
                                  label="Laboratorio"
                                  required
                                  name="laboratorio"
                                  variant="outlined"
                                />
                              )}
                            />

                            <TextField
                              required
                              className="ml-3 mb-3 col-md-12"
                              id="vacina"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              name="vacina"
                              label="Vacina"
                              variant="outlined"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={this.state.vacina}
                              onChange={(e) =>
                                this.setState({ vacina: e.target.value })
                              }
                            />

                            <TextField
                              required
                              className="ml-3 mb-3 col-md-12"
                              id="lote"
                              InputProps={{
                                readOnly: false,
                              }}
                              fullWidth
                              name="lote"
                              label="Lote"
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                this.setState({ lote: e.target.value })
                              }
                            />

                            <div className="ml-2 mb-3 col-md-12 text-center row Mui-selected">
                              <RadioGroup
                                onChange={(e) =>
                                  this.setState({ dose: e.target.value })
                                }
                                className="col-md-12"
                                style={{ flexDirection: "row" }}
                                defaultValue="1"
                                name="dose"
                              >
                                <div className="ml-2 mb-3 col-md-2 btn-pv btn-1dose">
                                  <FormControlLabel
                                    value="1"
                                    control={
                                      <Radio className="ml-5 text-center" />
                                    }
                                    label="1º Dose"
                                  />
                                </div>
                                <div className="ml-2 mb-3 col-md-3 btn-pv btn-2dose">
                                  <FormControlLabel
                                    value="2"
                                    control={
                                      <Radio className="ml-5 text-center" />
                                    }
                                    label="2º Dose"
                                  />
                                </div>
                                <div className="ml-2 mb-3 col-md-3 btn-pv btn-3dose">
                                  <FormControlLabel
                                    value="3"
                                    control={
                                      <Radio className="ml-5 text-center" />
                                    }
                                    label="3º Dose"
                                  />
                                </div>
                                <div className="ml-2 mb-3 col-md-2 btn-pv btn-4dose">
                                  <FormControlLabel
                                    value="4"
                                    control={
                                      <Radio className="ml-5 text-center" />
                                    }
                                    label="4º Dose"
                                  />
                                </div>
                              </RadioGroup>
                              {/* <button
                                                                className="btn-pv ml-5 col-md-5 text-center btn-1dose"
                                                                type="submit">1º Dose</button>
                                                            <button
                                                                className="btn-pv ml-5 col-md-5 text-center btn-2dose"
                                                                type="submit">2º Dose</button> */}
                            </div>

                            <TextField
                              required
                              className="ml-3 mb-3 col-md-12"
                              id="validade"
                              InputProps={{
                                readOnly: false,
                              }}
                              fullWidth
                              name="validade"
                              label="Validade"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                this.setState({ validade: e.target.value })
                              }
                            />

                            <TextField
                              required
                              className="ml-3 mb-3 col-md-12"
                              id="recebimento"
                              InputProps={{
                                readOnly: false,
                              }}
                              fullWidth
                              name="recebimento"
                              label="Dt. Recebimento"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                this.setState({ recebimento: e.target.value })
                              }
                            />
                            <Tooltip
                              title="Se não possuir dias deixar com 0"
                              placement="top"
                            >
                              <TextField
                                required
                                className="ml-3 mb-3 col-md-12"
                                id="dias"
                                InputProps={{
                                  readOnly: false,
                                }}
                                fullWidth
                                name="dias"
                                placeholder="Se não possuir dias deixar com 0"
                                label="Dias 2º Dose"
                                variant="outlined"
                                type="text"
                                value={this.state.dias}
                                onChange={(e) =>
                                  this.setState({ dias: e.target.value })
                                }
                              />
                            </Tooltip>
                            <TextField
                              required
                              className="ml-3 mb-3 col-md-12"
                              id="qtd"
                              InputProps={{
                                readOnly: false,
                              }}
                              fullWidth
                              name="qtd"
                              label="Nº de Doses"
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                this.setState({ qtd: e.target.value })
                              }
                            />

                            <button
                              className="btn-pv col-md-12 text-center btn-success"
                              type="submit"
                            >
                              Salvar
                            </button>
                          </div>
                        </fieldset>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
