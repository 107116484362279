import { TOKEN_KEY } from "./../services/auth";
import { apiBusca, apiBairros } from "./../services/api";

export const token = localStorage.getItem(TOKEN_KEY);

function parseJwt(token) {
  if (!token) {
    return;
  }
  token = token.substring(7);
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
//REGRAS GET

export const getUsers = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getUsers", config));
    }, 200)
  );
};

export const getAlunosID = async (e) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getAlunosID/" + e, config));
    }, 200)
  );
};


export const getAlunosListas = async (e) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getAlunosListas/" + e, config));
    }, 200)
  );
};



export const getEditaIdadeComorbidades = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getEditaIdadeComorbidades/" + id, config));
    }, 200)
  );
};


export const getPainelVacinas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getFaixaEtaria", config));
    }, 200)
  );
};

export const getEstoque = async (dose) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getEstoque/" + dose, config));
    }, 200)
  );
};


export const getEditaIdade = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getEditaIdade/" + id, config));
    }, 200)
  );
};

export const getVacinaIdadeComorbidades = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getVacinaIdadeComorbidades/", config));
    }, 200)
  );
};

export const getVacinaIdade = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getVacinaIdade/", config));
    }, 200)
  );
};

export const getIdadesVacina = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getIdadesVacina/", config));
    }, 200)
  );
};

export const getProtocolos = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getProtocolos/" + cpf, config));
    }, 200)
  );
};

export const getUnidadesCad = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getUnidadesCad", config));
    }, 200)
  );
};

export const getListaVacinados1Dose = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("listas/getListaVacinadosDose/1", config));
    }, 200)
  );
};

export const getListaVacinados2Dose = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("listas/getListaVacinadosDose/2", config));
    }, 200)
  );
};

export const listaTipos = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("listas/getTipo", config));
    }, 200)
  );
};

export const getListasTipos = async (tipo) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("listas/getListasTipo/" + tipo, config));
    }, 200)
  );
};

export const getLaudos = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("upload/getArquivos/" + cpf, config));
    }, 200)
  );
};

export const getVagas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getVagas", config));
    }, 200)
  );
};

export const getVagasEstudantes = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getVagasEstudantes", config));
    }, 200)
  );
};

export const getVagasCriancas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getVagasCriancas", config));
    }, 200)
  );
};

export const getVagasCidadao = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getVagasCidadao", config));
    }, 200)
  );
};

export const getComorbidadesIdades = async (id) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getComorbidadesIdades/" + id));
    }, 200)
  );
};


export const getBuscaBairros = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/getBairrosLista/" + id, config));
    }, 200)
  );
};

export const getUnidadesDIST = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getUnidadesDIST", config));
    }, 200)
  );
};
export const getUnidadesAPLI = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getUnidadesAPLI", config));
    }, 200)
  );
};

export const getLaboratorios = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getLaboratorios", config));
    }, 200)
  );
};


export const getUnidades = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getUnidades", config));
    }, 200)
  );
};

export const getAplicador = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getAplicadores", config));
    }, 200)
  );
};



export const getVacinas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getVacinasTipo", config));
    }, 200)
  );
};


export const getbuscaEntregasGeral = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastro/buscaEntregasGeral", config));
    }, 200)
  );
};

export const getbuscaEntregasEntregues = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastro/buscaEntregasEntregues", config));
    }, 200)
  );
};

export const getbuscaCadastrosGeral = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastro/buscaCadastrosGeral", config));
    }, 200)
  );
};

export const getUsersID = async () => {
  var cpf = parseJwt(token).username
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/trocaSenha/" + cpf, config));
    }, 200)
  );
};

export const getEntregasID = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/entregas/inserirObs/" + id, config));
    }, 200)
  );
};

export const getUsersCPF = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/buscaCpf/" + cpf, config));
    }, 200)
  );
};

export const getCPFCesta = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/regras/buscaCPFCesta/" + cpf, config));
    }, 200)
  );
};

export const getTipoCad = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getTiposCadastros/", config));
    }, 200)
  );
};

export const getTipoCadastros = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getTiposNaoCadastrados/" + id, config));
    }, 200)
  );
};


export const getCadastradosCPF = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/buscaCadastrosCPF/" + cpf, config));
    }, 200)
  );
};

export const getCadastrados = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/buscaCadastros/" + cpf, config));
    }, 200)
  );
};

export const buscaLocais = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/buscaLocais/", config));
    }, 200)
  );
};

export const getHorariosDisponiveis = async (e, unidade) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getHorariosDisponiveis/" + e + "/" + unidade, config));
    }, 200)
  );
};




export const buscaCadastrosDose = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/buscaCadastrosDose/" + cpf, config));
    }, 200)
  );
};


export const getListaBairroAplicadores = async (id, bairro) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getListaBairroAplicadores/" + id + "/" + bairro, config));
    }, 200)
  );
};

export const getCadastradosListasVAC = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastrosVAC/", config));
    }, 200)
  );
};

export const getSIPNI = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getSIPNI", config));
    }, 200)
  );
};

export const getVacinadosGeral = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getVacinadosGeral/", config));
    }, 200)
  );
};

export const getVacinadosDrive = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getVacinadosDrive/", config));
    }, 200)
  );
};



export const getCadastradosListasAcamadosVACGeral = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastrosAcamadosVACGeral/", config));
    }, 200)
  );
};

export const getCadastradosListasAcamadosVAC = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastrosAcamadosVAC/", config));
    }, 200)
  );
};

export const getAgendamento = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getAgendamento/" + cpf, config));
    }, 200)
  );
};




export const getVacAplicador = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/getVacAplicador/" + id, config));
    }, 200)
  );
};


export const getCadastrosVAC2Dose = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastrosVAC2Dose/", config));
    }, 200)
  );
};


export const getCadastradosListasNVAC = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastrosNVAC/", config));
    }, 200)
  );
};

export const getCadastradosListas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastros/", config));
    }, 200)
  );
};

export const getCadastradosListasAcamados = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastradosListasAcamados/", config));
    }, 200)
  );
};
export const getCadastradosListasAcamados2dose = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastradosListasAcamados2dose/", config));
    }, 200)
  );
};

export const getCadastradosListasAcamados3dose = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastradosListasAcamados3dose/", config));
    }, 200)
  );
};

export const getCadastradosListasAcamados4dose = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getCadastradosListasAcamados4dose/", config));
    }, 200)
  );
};


export const getEditaCadastros = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastro/editarCadastro/" + cpf, config));
    }, 200)
  );
};

export const getEditaCadastrosCPF = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/editarCadastroCPF/" + cpf, config));
    }, 200)
  );
};

export const getReacoes = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getReacoes/" + cpf, config));
    }, 200)
  );
};

export const getLogsCPF = async (cpf) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getLogsCPF/" + cpf, config));
    }, 200)
  );
};


export const getEditaCadastrosNome = async (nome) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/editarCadastroNome/" + nome, config));
    }, 200)
  );
};

export const getListaCadastrados = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaCadastros", config));
    }, 200)
  );
};

export const getListaComtemplados = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaComtemplados", config));
    }, 200)
  );
};

export const getLista2Cesta = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/lista2Cesta", config));
    }, 200)
  );
};

export const getEntregaLista = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/entregas/entregaLista", config));
    }, 200)
  );
};

export const getMotorista = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/entregas/ListaMotorista", config));
    }, 200)
  );
};

export const getLogs = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/log", config));
    }, 200)
  );
}

export const getRelatoriosDiarioAPLI = async (id, data) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getRelatoriosDatas/" + id + "/" + data, config));
    }, 200)
  );
};

export const getRelatoriosDiario = async (data) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getRelatoriosDatasII/" + data, config));
    }, 200)
  );
};

export const getRelatoriosDiarioRapido = async (data) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/getVacDiario/" + data, config));
    }, 200)
  );
};

export const getDadosGrafico = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/graficos/", config));
    }, 200)
  );
};

// export const getDadosSigss = async (cpf) => {
//   const config = {
//     headers: { 'Authorization': token }
//   }
//   return new Promise(resolve =>
//     setTimeout(() => {
//       resolve(apiBusca.get("http://saude.anapolis.go.gov.br:8133/sigss/usuarioServicoConsultaPDQ/consultarPaciente?pdq.cartaoNacionalSus=&pdq.cpf=" + cpf, config));
//     }, 200)
//   );
// };



export const getAvisos = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getAvisos/", config));
    }, 200)
  );
};

export const getDadosGraficos = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/relatorios/graficoCount/", config));
    }, 200)
  );
};

export const listagemGeral = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaGeral/", config));
    }, 1000)
  );
}

export const listagemPendente = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaStatus/pendente", config));
    }, 1000)
  );
}

export const listagemEntregues = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaEntregues", config));
    }, 1000)
  );
}

export const listagemSaiuEntrega = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaStatus/saiuEntrega", config));
    }, 1000)
  );
}

export const listagemInconsistencias = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaInconsistentes", config));
    }, 1000)
  );
}

export const listagemInativos = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaInativos", config));
    }, 1000)
  );
}



export const getProfissoes = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/profissoes", config));
    }, 200)
  );
};

export const getListaUsuarios = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("cadastros/getUsuarios", config));
    }, 200)
  );
};


export const getAlunos = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("cadastros/getAlunos", config));
    }, 200)
  );
};

export const getEditaUsuarios = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("cadastros/getUsuariosID/" + id, config));
    }, 200)
  );
};

export const getListaUnidadesID = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("cadastros/getUnidadesEditID/" + id, config));
    }, 200)
  );
};

export const getListaUnidades = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("cadastros/getUnidadesEdit", config));
    }, 200)
  );
};

export const getDatas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/vacinas/getRelatorios/", config));
    }, 200)
  );
};


export const getBuscaMotoristas = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/entregas/buscaMotoristas/", config));
    }, 200)
  );
};

export const buscaIDMotorista = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/buscaGetAplicadores", config));
    }, 200)
  );
};

export const getBuscaAplicadorBairros = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/ListaAplicadoresBairro/" + id, config));
    }, 200)
  );
};

export const buscaEntregaData = async () => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/regras/buscaEntregaData/", config));
    }, 200)
  );
};

export const getBairros = async () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBairros.get("/endereco/bairros"));
    }, 200)
  );
}

export const getComorbidades = async () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getComorbidades"));
    }, 200)
  );
}

export const postProtocolo = async (prot3) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/protocolo/" + prot3));
    }, 200)
  );
}

export const getIDCadastros = async (id) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getIDCadastros/" + id));
    }, 200)
  );
}

export const getAtualizaVAC = async (id) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/cadastros/getAtualizaVAC/" + id));
    }, 200)
  );
}

export const removeVacina = async (id) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/cadastros/removeVacina/" + id));
    }, 200)
  );
}


export const listaBairrosBanco = async () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaBairrosBanco"));
    }, 200)
  );
}

export const listaBairrosDTBanco = async () => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.get("/listas/listaDataBanco"));
    }, 200)
  );
}


//REGRAS PUT

export const sairEntrega = async (idEntrega) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/sairEntrega/" + idEntrega, config));
    }, 200)
  );
};

export const AceitaVacina = async (cpf) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/aceitaVacina/" + cpf, config));
    }, 1000)
  );
};

export const BloqueioVacina = async (cpf) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/bloquearVacina/" + cpf, config));
    }, 1000)
  );
};

export const DesbloqueioVacina = async (cpf) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/desbloquearVacina/" + cpf, config));
    }, 1000)
  );
};

export const RecusaVacina = async (cpf) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/recusaVacina/" + cpf, config));
    }, 1000)
  );
};

export const ConfEntrega = async (idEntrega, formValue) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/confEntrega/" + idEntrega + "/" + formValue, config));
    }, 1000)
  );
};

export const LiberaSolicitacao = async (id, unidade) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/solicita2dose/" + id + "/" + unidade, config));
    }, 1000)
  );
};

export const Libera3Solicitacao = async (id, unidade) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/listas/solicita3dose/" + id + "/" + unidade, config));
    }, 1000)
  );
};

export const LiberaSolicitacao3 = async (id) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/liberaSolicitacao3/" + id, config));
    }, 1000)
  );
};

export const LiberaSolicitacao4 = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/liberaSolicitacao4/" + id, config));
    }, 1000)
  );
};

export const LiberaSolicitacao5 = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/liberaSolicitacao5/" + id, config));
    }, 1000)
  );
};

export const LiberaSolicitacao6 = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/liberaSolicitacao6/" + id, config));
    }, 1000)
  );
};

export const LiberaSolicitacao7 = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/liberaSolicitacao7/" + id, config));
    }, 1000)
  );
};

export const InativaCadastro = async (id) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/inativarCadastro/" + id, config));
    }, 1000)
  );
};

export const AtivaCadastro = async (id) => {

  const config = {
    headers: { 'Authorization': token }
  }
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(apiBusca.put("/entregas/ativarCadastro/" + id, config));
    }, 1000)
  );
};

export function ts2human(time) {
  var ts = time;
  var dt = new Date(ts * 1000);
  return (ts, dt.toLocaleString());
}
export function human2ts(data) {
  var di = data;
  var ts = Date.parse(di);

  return (ts / 1000, ts / 1000);
}

export const isAuthenticated = () => {
  if (localStorage.getItem(TOKEN_KEY) === null) {
    return false
  } else {
    var userLogged = parseJwt(token).username
    var expira = parseJwt(token).exp
    var data = new Date();
    var dataTS = human2ts(data)
    if (userLogged !== null && expira > dataTS) {
      return true;
    } else {
      return false;
    }
  }
}