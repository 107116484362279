import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { getUsers, getIDCadastros, token } from "../services/dados";
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */
const { cpf } = require("cpf-cnpj-validator");
const $ = require("jquery");

export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe;
  var classe;
  if (status === 200) {
    classe = "success";
  } else if (status === 201 || status === 110) {
    classe = "error";
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>{param.texto}</Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const inputParsers = {
  date(input) {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase(input) {
    return input.toUpperCase();
  },
  number(input) {
    return parseFloat(input);
  },
};

class Reacao extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: "",
        message: "",
      },
      nome: "",
      cpf: "",
      ocorrencia: "",
      idOcorrencia: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  getEventos() {
    const ID = localStorage.getItem("edit");
    console.log(ID);
    let idOcorrencia = window.atob(ID);
    console.log(idOcorrencia);
    getIDCadastros(idOcorrencia).then((response) => {
      this.setState({ nome: response.data.nome, cpf: response.data.cpf });
    });
    getUsers().then((response2) => {
      this.setState({ dados: response2.data, idOcorrencia: idOcorrencia });
    });
  }

  componentDidMount() {
    this.getEventos();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false });
      return window.history.back();
    }, 1 * 1000);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { cpf, nome, idOcorrencia, ocorrencia } = this.state;
    var raw = { cpf, nome, idOcorrencia, ocorrencia };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    apiBusca
      .post("/cadastros/InsereReacao", requestOptions)
      .then((response) => {
        this.setState({
          alert: {
            status: response.data.response.status,
            message: response.data.response.result,
          },
        });
        this.setState({ setOpen: true });
        if (this.state.alert.status === 200) {
          this.apareceAlert();
        }
      })
      .catch((error) => {
        this.setState({});
        this.setState({ setOpen: true });
      });
    //event.target.reset();
  }

  render() {
    var alertMsg = this.state.alert;
    const valores = [
      {
        value: "sim",
        label: "Sim",
      },
      {
        value: "nao",
        label: "Não",
      },
    ];
    return (
      <div className="skin-blue bg-light sidebar-mini" id="panel">
        <div className="wrapper">
          <NavTop dados={this.state.dados} />
          <Sidebar dados={this.state.dados} />
          <br />
          <div className="content-wrapper">
            {/* Main content */}
            <section className="content">
              <form id="wizard" onSubmit={this.handleSubmit}>
                <Alerta
                  setOpen={this.state.setOpen}
                  texto={alertMsg.message}
                  classe={alertMsg.status}
                />
                <fieldset className="card">
                  <legend className="card-header text-left legend-pma faq-btn-link">
                    Informações importantes
                  </legend>
                  <div id="resposta"></div>
                  <div className="col-md-12 campos-pma card-body">
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-12"
                      id="cpf"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      name="cpf"
                      label="C.P.F."
                      variant="outlined"
                      value={this.state.cpf}
                      type="text"
                      onChange={(e) => this.setState({ cpf: e.target.value })}
                    />
                    <TextField
                      required
                      className="ml-3 mb-3 col-md-12"
                      id="nome"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      name="nome"
                      label="Nome"
                      variant="outlined"
                      value={this.state.nome}
                      type="text"
                      onChange={(e) => this.setState({ nome: e.target.value })}
                    />

                    <TextField
                      required
                      className="ml-3 mb-3 col-md-12"
                      id="reacao"
                      InputProps={{
                        readOnly: false,
                      }}
                      fullWidth
                      name="reacao"
                      label="Observação"
                      variant="outlined"
                      type="text"
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(e) =>
                        this.setState({ ocorrencia: e.target.value })
                      }
                    />
                  </div>
                </fieldset>
                <button
                  id="cadastro_vacina"
                  className="btn-pv col-md-12 text-center pv-btn-primary"
                  type="submit"
                >
                  Cadastrar
                </button>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Reacao;
