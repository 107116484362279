import React, { Component } from "react";
import { TextField, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { getUsers, token, getUsersCPF, getVacinaIdade, getEditaIdade } from "../services/dados"
import Swal from 'sweetalert2'
import { logoExport } from '../services/logo'

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');


export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201 || status === 110) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alert severity={classe}>
                    {param.texto}
                </Alert>
            </Snackbar>
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

function apareceAlert(props) {
    var url = props.location.pathname
    var path = props.location.search
    return (window.location = "/EditarIdadesVacinacao");
}

export class EditaIdade extends Component {
    constructor() {
        super();
        this.state = {
            dados: [],
            idade_min: '',
            tipo: '',
            alert: {
                status: '',
                message: ''
            },
            setOpen: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
    }

    async getEventos() {
        var id = this.props.location.search
        await getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
        await getEditaIdade(id).then(response2 => {
            this.setState({
                tipo: response2.data.tipo,
                idade_min: response2.data.idade_min,
                id: response2.data.id,
            })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    buscaCpf = event => {
        var id = event.target.value;
        getUsersCPF(id).then(response => {
            if (response.data === 1) {
                alert("Tipo já Cadastrado")
                this.setState({ cpf: '' })
                setTimeout(() => {
                    $("#resposta").html('');
                }, 1 * 1000)
            } else {
                setTimeout(() => {
                    $("#resposta").html('');
                }, 1 * 1000)
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const { id, tipo, idade_min } = this.state
        var raw = { id, tipo, idade_min }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)
        var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log(raw)
        apiBusca.put("/vacinas/putIdadeVacina/", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                let propis = this.props
                if (this.state.alert.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: this.state.alert.message
                    }).then(function () {
                        apareceAlert(propis)
                    })

                }
                else if (this.state.alert.status === 201) {
                    Toast.fire({
                        icon: 'error',
                        title: this.state.alert.message
                    })
                }
            })

            .catch(error => {
                this.setState({
                    alert: {
                        status: 201,
                        message: "Contate o Desenvolvedor do Sistema! insertUsuarios()->BAD_CONFIG"
                    }
                })
            })
        event.target.reset();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Início
            <small>V. API 0.1</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Social">
                                        <i className="fa fa-dashboard" />Início
              </a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <fieldset className="card">
                                    <legend className="card-header text-left legend-pma faq-btn-link">
                                        Informações importantes
              </legend>
                                    <div className="col-md-4 alert-danger" id="resposta"></div>
                                    <div className="col-md-12 campos-pma card-body">

                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-11"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            fullWidth
                                            name="tipo"
                                            label="Tipo"
                                            variant="outlined"
                                            type="text"
                                            value={this.state.tipo}
                                            onChange={e => this.setState({ tipo: e.target.value })}
                                        />
                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-11"
                                            id="idade_min"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            fullWidth
                                            name="idade_min"
                                            label="Idade Minima"
                                            variant="outlined"
                                            type="text"
                                            value={this.state.idade_min}
                                            onChange={e => this.setState({ idade_min: e.target.value })}
                                        />

                                    </div>
                                </fieldset>
                                <button
                                    className="btn-pv col-md-12 text-center btn-success"
                                    type="submit"
                                >
                                    Atualizar
                </button>
                            </form>
                        </section>
                    </div>
                    <Footer />
                </div >
            </div >
        );
    }
}

const columns = [
    {
        title: 'Tipo',
        width: "auto",
        data: 'tipo'
    },
    {
        title: 'Idade Minima',
        width: "auto",
        data: 'idade_min'
    },
    {
        title: 'Editar',
        width: "auto",
        data: 'id'
    },
];

function DataTable(props) {
    $('#table').DataTable({
        dom: 'Bfrtip',
        responsive: {
            details: true
        },
        buttons: [
            {
                extend: 'pdf', className: 'btn-pdf', text: 'PDF',
                orientation: 'landscape',
                pageSize: 'A4',
                filename: "Lista Usuarios Sistema",
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4],

                },
                footer: {
                    columns: [
                        'Left part',
                        { text: 'Right part', alignment: 'right' }
                    ]
                },
                customize: function (doc) {
                    var custom = doc.content[1].table
                    custom.widths = ['60%', '20%', '6%', '14%']
                    var objLayout = {};
                    objLayout['hLineWidth'] = function (i) { return .5; };
                    objLayout['vLineWidth'] = function (i) { return .5; };
                    objLayout['hLineColor'] = function (i) { return '#aaa'; };
                    objLayout['vLineColor'] = function (i) { return '#aaa'; };
                    objLayout['paddingLeft'] = function (i) { return 4; };
                    objLayout['paddingRight'] = function (i) { return 4; };
                    doc.content[1].layout = objLayout;
                    doc['header'] = (function () {
                        return {
                            columns: [
                                {
                                    alignment: 'center',
                                    image: logoExport,
                                    width: 220
                                },
                            ],
                            padding: 30
                        }
                    });
                }
            },
            { extend: 'print', className: 'btn-print', text: 'Imprimir' }
        ],
        data: props.cadastros,
        columns,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {
            $('td:eq(1)', row).css('display', '')
            $('td:eq(1)', row).css('white-space', 'normal')
            $('td:eq(0)', row).css('display', '')
            $('td:eq(2)', row).html("<i class='fas fa-edit'></i>")
            $('td:eq(2)', row).click(
                function () {
                    props.editCadastro(data.id);
                })
        },
        "headerCallback": function (thead, data, display) {
            $('th:eq(0)', thead).css('display', '')
            $('th:eq(1)', thead).css('display', '')

        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}
export class EditarIdadesVacinacao extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            vacinaIdade: []
        };
        this.getEventos = this.getEventos.bind(this);
        this.editCadastro = this.editCadastro.bind(this)
    }

    getEventos() {
        getVacinaIdade().then(response => {
            this.setState({ vacinaIdade: response.data })
        });
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data })
        });
    }

    editCadastro = (teste) => {
        var e = window.btoa(teste)
        // var r = window.confirm("Tem certeza que deseja editar esse Cadastro?");
        // if (r === true) {
        return (window.location = "/EditaIdade?" + e)
        //this.props.location("/EditaCadastro", { teste: e })

        // }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getEventos();
    }

    render() {

        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Lista Usuarios
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Social">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <DataTable
                                cadastros={this.state.vacinaIdade}
                                editCadastro={this.editCadastro} />
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}