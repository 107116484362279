import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { getAlunosListas, getEditaCadastrosNome, getUsers } from "../services/dados"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField } from "@material-ui/core";
import { removeAcento } from "./buscaCPF";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alertin severity={classe}>
                    {param.texto}
                </Alertin>
            </Snackbar>
        </div>
    );
}
export function Alertin(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function apareceAlert() {
    setTimeout(() => {
        return (window.location = "/ListaMotoristas");
    }, 1 * 1000)
}

const columns = [
    {
        title: 'Nome',
        width: "auto",
        data: 'nome_do_aluno'
    },
    {
        title: 'Nome da Mãe',
        width: "auto",
        data: 'nome_mae'
    },
    {
        title: 'CPF/SUS',
        width: "auto",
        data: 'cpf'
    },
    {
        title: 'Unidade Escolar',
        width: "auto",
        data: 'unidade_escolar'
    },
    {
        title: 'Cadastrado',
        width: "auto",
        data: 'cadastrado'
    }
];

function DataTable(props) {
    var table = $('#table').DataTable({
        dom: 'frtip',
        data: props.cadastros,
        columns,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {
            if (data.cadastrado === 'NAO') {
                $('td', row).click(
                    function () {
                        props.buscaCPF(data.id);
                    })
                $('td', row).css('backgroundColor', 'rgb(255, 165, 163)')
                $('td', row).css('fontWeight', 'Bold')
                $('td', row).css('cursor', 'pointer')
            } else if (data.cadastrado === 'SIM') {
                $('td', row).css('backgroundColor', 'rgb(44, 156, 103)')
                $('td', row).css('fontWeight', 'Bold')
            }

        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}



export class AlunoNomeBuscaEdita extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
        this.buscaCPF = this.buscaCPF.bind(this)
        this.obsInserir = this.obsInserir.bind(this)
    }

    getEventos() {
        var idBusca = this.props.location.search
        var id = idBusca.replace('?', '')
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data })
        });
        getAlunosListas(id).then(response2 => {
            this.setState({ dados: response2.data })
        });
    }

    obsInserir(id) {
        var teste = window.btoa(id)
        localStorage.setItem('edit', teste);
        return (window.location = "/EntregaObs");
    }

    async buscaCPF(teste) {
        var e = window.btoa(teste)
        return (window.location = "/EditarAlunos?" + e)
    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Resultados
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <Alerta
                                setOpen={this.state.setOpen}
                                texto={alertMsg.message}
                                classe={alertMsg.status} />

                            <DataTable
                                cadastros={this.state.dados}
                                inserirObs={this.obsInserir}
                                buscaCPF={this.buscaCPF} />
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}
export class BuscaAlunoEdita extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            bairros: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);

    }

    getEventos() {
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    handleSubmit(event) {
        event.preventDefault();
        var e
        const form = event.target;
        const data = new FormData(form);
        let cpf = data.get('busca')
        var t = removeAcento(cpf)
        e = window.btoa(t)
        return (window.location = "/BuscaEditaAlunos?" + e)

    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>Busca Cadastros</h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <TextField
                                    required
                                    className="mb-3 ml-3 col-md-11"
                                    id="busca"
                                    fullWidth
                                    name="busca"
                                    value={this.state.qtd}
                                    onChange={this.qtdChange}
                                    label="Busca Nome ou CPF(sem traços e pontos)"
                                    variant="outlined"
                                />
                                <button
                                    className="btn-pv mb-3 ml-3 col-md-11 text-center pv-btn-primary"
                                    type="submit"
                                >
                                    Buscar Cadastro
                                </button>
                            </form>
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}