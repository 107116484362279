import React, { Component } from "react";
import NavTop from "./Navtop";
import { getAvisos} from "../services/dados"
import Footer from "../components/Footer";
import Content from "./Content";


class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],

dadosAvisos : '',    };
  }

  render() {
    return (
      <div className="conteudo" style={{ overflowX: 'hidden' }}>
        <NavTop />
        <Content />
        <Footer />
      </div>
    );
  }
}
export default Panel;
