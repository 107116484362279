import React, { Component } from "react";

export default class HeaderAcess extends Component {
    render() {
        return (
            <div className='pma-top'>
                <div className="top-head">
                    <div className="col-md-12 row">
                        <div className="col-md-9">
                            <a href="/">
                                <img alt='logopma' width='118px' height='43px' src='https://cdn.anapolis.go.gov.br/img/logos/logo_branco.png' />
                                <span style={{ marginLeft: "20px" }} className="text-center pma-bolder">

                                </span>
                            </a>
                        </div>
                    </div >
                </div>
                <div className="faixa-menu"></div>

            </div>
        )
    }
}