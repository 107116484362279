import React, { Component } from "react";
import Sidebar from "../Vacina/Sidebar";
import NavTop from "../Vacina/Navtop";
import Footer from "../components/Footer";
import { getBuscaBairros, getUsers, getUnidades, token, listaBairrosDTBanco } from "../services/dados"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataTable } from "../Vacina/ListaNaoVacinados";
import { apiBusca } from "../services/api";

function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alertin severity={classe}>
                    {param.texto}
                </Alertin>
            </Snackbar>
        </div>
    );
}
function Alertin(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class GerarListaBairroDatas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            bairros: [],
            bairro: '',
            equipes: [],
            dadosUser: [],
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getEventos() {
        listaBairrosDTBanco().then(response => {
            this.setState({ bairros: response.data })
        })
        getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
        getUnidades().then(response2 => {
            this.setState({ equipes: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    handleSubmit(event) {
        event.preventDefault();
        const { bairro, equipe, lote, qtd, id_repasse, ids } = this.state
        var raw = { bairro, equipe, lote, qtd, id_repasse, ids }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        apiBusca.post("/listas/cadListaBairros", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                this.setState({ setOpen: true })
                this.apareceAlert();

            })
            .catch(error => {
                this.setState({
                })
                this.setState({ setOpen: true })
            })
        var bairros = Buffer.from(bairro).toString('base64')
        return (window.location = "/ListaConfAplicador?" + equipe + "?" + bairros)

    }

    apareceAlert() {
        setTimeout(() => {
            this.setState({ setOpen: false })
            var bairro1 = this.state.bairro
            var bairro = window.btoa(bairro1)
            return (window.location = "/ListaBairro?" + bairro)
        }, 1 * 1000)
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Gerar Listas por Datas/Bairros
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início
              </a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <fieldset className="card">
                                    <legend className="card-header text-left legend-pma faq-btn-link">
                                        Informações importantes
              </legend>
                                    <div class="text-center card btn-warning">
                                        <b style={{ color: 'red' }}>ATENÇÃO<br /></b>
                                        Só irá aparecer a data mais antiga com seu respectivo bairro.<br />
                                    Porém irá ser gerada uma lista com as pessoas daquele bairro.<br />
                                    EX: 21/02/2021 - Centro - 182<br />Onde temos:<b> data - bairro - qtd de pessoas</b>
                                    </div>
                                    <div className="col-md-12 campos-pma card-body">
                                        <div className="col-md-12">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.bairros}
                                                getOptionLabel={(option) => option.data + " - " + option.bairro + " - " + option.pessoas}
                                                onChange={(option, value) => {

                                                    if (value != null) {
                                                        this.setState({
                                                            bairro: value.bairro,
                                                            qtd: value.pessoas,
                                                            ids: value.cadastros
                                                        })
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        id="bairro"
                                                        label="Bairro"
                                                        onChange={this.bairroChange}
                                                        onBlur={this.buscaEndereco}
                                                        required
                                                        name="bairro"
                                                        variant="outlined" />}
                                            />

                                        </div>

                                        <div className="col-md-12">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.equipes}
                                                getOptionLabel={(option) => option.nome + " - " + option.qtd + " doses"}
                                                onChange={(option, value) => {
                                                    if (value != null) {
                                                        this.setState({
                                                            equipe: value.id,
                                                            lote: value.lote,
                                                            id_repasse: value.id_repasse
                                                        })
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        id="equipe"
                                                        label="Aplicador"
                                                        required
                                                        name="equipe"
                                                        variant="outlined" />}
                                            />

                                        </div>
                                    </div>
                                </fieldset>
                                <button
                                    className="btn-pv col-md-12 text-center btn-primary"
                                    type="submit"
                                >
                                    Cadastrar
                </button>
                            </form>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>

        )
    }
}

export class ListaBairro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            dadosUser: [],
            cadastros: [],
            usuario: '',
            alert: {
                status: '',
                message: ''
            }
        };
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        var idBusca = this.props.location.search
        var busca = idBusca.split('?')
        var id = busca[1]
        getUsers().then(response2 => {
            this.setState({ dadosUser: response2.data, usuario: response2.data.nome })
        });
        getBuscaBairros(id).then(response2 => {
            this.setState({ cadastros: response2.data })
        });
    }

    async buscaCPF(cpf) {
        //var bairros = window.btoa(bairro)
        var cpfc = Buffer.from(cpf).toString('base64')
        return (window.location = "/CadastroCPF?" + cpfc)

    }

    componentDidMount() {
        this.getEventos();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dadosUser} />
                    <Sidebar dados={this.state.dadosUser} />
                    <br />
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <h1>
                                Lista Entregas
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <a href="/Vacina">
                                        <i className="fa fa-dashboard" />Início</a>
                                </li>
                            </ol>
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <Alerta
                                setOpen={this.state.setOpen}
                                texto={alertMsg.message}
                                classe={alertMsg.status} />
                            <DataTable
                                cadastros={this.state.cadastros}
                                usuario={this.state.usuario}
                                buscaCPF={this.buscaCPF}
                            />
                        </section>
                    </div >
                    <Footer />
                </div >
            </div >
        );
    }
}