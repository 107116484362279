import React, { Component } from "react";
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { getUsers, getVacinas } from "../services/dados";

function TableFaixaEtaria(props) {
    var dados = props.vacinas
    var style, style2
    return dados.map(function (item, i) {
        if (item.imunizados_n > 50) {
            style = "percentGreen"
        } else {
            style = "percentRed"
        }
        if (item.imunizados_n2 > 50) {
            style2 = "percentGreen"
        } else {
            style2 = "percentRed"
        }
        return (
            <tr key={i}>
                <td>{item.faixa_etaria}</td>
                <td>{item.populacao}</td>
                <td>{item.doses_aplicadas1dose}</td>
                <td className={style}>{item.imunizados}</td>
                <td>{item.doses_aplicadas2dose}</td>
                <td className={style2}>{item.imunizados2dose}</td>
            </tr>
        )
    })
}

function TableEstoque(props) {
    if (props.vacinas === null || props.vacinas === '') {
        return '';
    }
    var dados = props.vacinas
    console.log(dados)
    return dados.map(function (item, i) {
        return (
            <tr key={i}>
                <td>{item.nome}</td>
                <td>{item.lote}</td>
                <td>{item.qtd_doses}</td>
                <td>{item.laboratorio}</td>
                <td>{item.dose}º Dose</td>
                <td>{item.validade}</td>
                <td>{item.recebimento}</td>
            </tr>
        )
    })
}


export default class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            alert: {
                status: '',
                message: ''
            },
            Vacinas: [],
            estoque: [],
            estoque2: []
        }
        this.getEventos = this.getEventos.bind(this);
    }

    async getEventos() {
        let dose = 1
        let dose2 = 2
        await getUsers().then(response2 => {
            this.setState({
                dados: response2.data
            })
            if (response2.data.acesso === 0) {
                return (window.location = "/TrocarSenha");
            }
        });
        await getVacinas().then(response2 => {
            this.setState({
                Vacinas: response2.data
            })
        })
    }

    backBusca() {
        window.location = "/Vacina"
    }

    componentDidMount() {
        this.getEventos();
    }
    render() {

        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <div className="content-wrapper">
                        <section className="content">
                            <div className="box-header with-border">
                                <h3 className="box-title">Vacinas Cadastradas</h3>
                            </div>
                            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }}
                                refs="main">
                                <thead>
                                    <tr className="title_table">
                                        <th>Vacina</th>
                                        <th>Lote</th>
                                        <th>Quantidade de DosesRecebidas</th>
                                        <th>Laboratorio</th>
                                        <th>Doses</th>
                                        <th>Validade</th>
                                        <th>Recebimento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableEstoque vacinas={this.state.Vacinas} />
                                </tbody>
                            </table>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}