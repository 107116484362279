import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import Footer from "../components/Footer";
import NavTop from "./Navtop";
import { TextField, Tooltip, Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api, { apiBusca } from "../services/api";
import {
  token,
  getCadastrados,
  getBairros,
  getVagasCidadao,
  getComorbidades,
  getComorbidadesIdades,
} from "../services/dados";
import {
  apareceAlertProtocolo,
  apareceAlertProtocoloError,
  Toast,
} from "../components/Alert";
const { cpf } = require("cpf-cnpj-validator");
const $ = require("jquery");
/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

export function Alerta(param) {
  const open = param.setOpen;
  var status = param.classe;
  var classe;
  if (status === 200) {
    classe = "success";
  } else if (status === 201 || status === 110) {
    classe = "error";
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={200}>
        <Alert severity={classe}>{param.texto}</Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dadosEntregues: [],
      dadosAvisos: [],
      dadosCount: [],
      vagos: "",
      limite: "",
      alert: {
        status: "",
        message: "",
      },
      termo: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
  }

  async getEventos() {
    await getVagasCidadao().then((response2) => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1],
        idadeCad: response2.data[3],
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    return (window.location = "/CadastrarComorbidadesAdolescentes");
  }

  componentDidMount() {
    this.getEventos();
  }

  render() {
    let button;
    if (this.state.termo === true) {
      button = "btn-pv col-md-12 text-center pv-btn-primary";
    } else {
      button = "btn-pv col-md-12 text-center pv-btn-danger";
    }

    var maximo = 900000;
    var totalAtual = this.state.vagos.qtd;

    if (totalAtual > maximo) {
      return (
        <div className="content-wrapper">
          {/* Main content */}
          <section className="content btn-danger col-md-12">
            <div
              style={{ fontWeight: "bolder", fontSize: "70px" }}
              className="text-center"
            >
              Sistema Indisponível
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div className="home">
          <div className="col-md-12">
            <h1 className="text-center">Cadastro #IMUNIZAANÁPOLIS</h1>
            <hr />
            <h1
              style={{ fontWeight: "bold", color: "red" }}
              className="text-center"
            >
              LEIA COM ATENÇÃO!
            </h1>
            <div className="pma-text-center mb-3">
              <br />
              Documento para comprovação de comorbidades: Formulário preenchido
              ou ou relatório médico que descreva a comorbidade em questão,
              <br />
              que esteja assinado por médico e tenha sido emitido há menos de 12
              meses.
              <br />
              <br />
              <h3>Para efetuar o seu cadastro será necessário anexar:</h3>
              <br />
              <b>
                - CPF
                <br />
              </b>
              <b>
                - ou Cartão do SUS de Anápolis
                <br />
              </b>
              <b>
                - Comprovante de Residência (Domicílio em Anápolis)
                <br />
              </b>
              <b>- Laudo médico que comprove a comorbidade</b>
              <br />
              <br />
              <b>
                Quem pode se vacinar:
                <br />
              </b>
              - Adolescentes com Transtorno do Espectro Autista.
              <br />
              - Grávidas.
              <br />
              - Adolescentes com as comorbidades abaixo.
              <br />
              <br />
              <br />
              <b>
                Lista de comorbidades: <br />
                <br />
              </b>
              <i>
                DIABETES MELLITUS - Qualquer indivíduo com diabetes.
                <br />
                <br />
                PNEUMOPATIAS CRÔNICAS GRAVES - Indivíduos compneumopatias graves
                incluindo doença pulmonar obstrutiva crônica, ﬁbrose cística,
                ﬁbroses pulmonares, pneumoconioses, displasia broncopulmonar e
                asma grave (uso recorrente de corticoides sistêmicos, internação
                prévia por crise asmática).
                <br />
                <br />
                HIPERTENSÃO ARTERIAL RESISTENTE (HAR) - Quando a pressão
                arterial (PA) permanece acima das metas recomendadas com o uso
                de três ou mais anti-hipertensivos de diferentes classes, em
                doses máximas preconizadas e toleradas, administradas com
                frequência, dosagem apropriada e comprovada adesão ou PA
                controlada em uso de quatro ou mais fármacos antihipertensivos.
                <br />
                <br />
                HIPERTENSÃO ARTERIAL ESTÁGIO 3 - PA sistólica≥180mmHg e/ou
                diastólica ≥110mmHg independente da presença de lesão em
                órgão-alvo (LOA) ou comorbidade.
                <br />
                <br />
                HIPERTENSÃO ARTERIAL ESTÁGIOS 1 E 2 COMLESÃO EM ÓRGÃO-ALVO E/OU
                COMORBIDADE - PA sistólica entre 140 e 179mmHg e/ou diastólica
                entre 90 e 109mmHg na presença de lesão em órgão-alvo e/ou
                comorbidade.
                <br />
                <br />
                INSUFICIÊNCIA CARDÍACA (IC) - IC com fração deejeção reduzida,
                intermediária ou preservada; em estágios B, C ou D, <br />
                independente de classe funcional da New York HeartAssociation.
                <br />
                <br />
                COR-PULMONALE E HIPERTENSÃO PULMONAR - Cor-pulmonale crônico,
                hipertensão pulmonar primária ou secundária.
                <br />
                <br />
                CARDIOPATIA HIPERTENSIVA - Cardiopatiahipertensiva (hipertroﬁa
                ventricular esquerda ou dilatação,
                <br />
                sobrecarga atrial e ventricular, disfunção diastólica
                e/ousistólica, lesões em outros órgãos alvo).
                <br />
                <br />
                SÍNDROMES CORONARIANAS - Síndromes coronarianascrônicas (Angina
                Pectoris estável, cardiopatia isquêmica, pós Infarto Agudo do
                Miocárdio, outras).
                <br />
                <br />
                VALVOPATIAS - Lesões valvares com repercussãohemodinâmica ou
                sintomática ou com comprometimento <br />
                miocárdico (estenose ou insuﬁciência aórtica; estenose
                ouinsuﬁciência mitral; estenose ou insuﬁciência pulmonar; <br />
                estenose ou insuﬁciência tricúspide, e outras).
                <br />
                <br />
                MIOCARDIOPATIAS E PERICARDIOPATIAS - Miocardiopatias de
                quaisquer etiologias ou fenótipos;pericardite crônica;
                cardiopatia reumática.
                <br />
                <br />
                DOENÇAS DA AORTA, DOS GRANDES VASOS EFÍSTULAS ARTERIOVENOSAS -
                <br />
                Aneurismas, dissecções, hematomas da aorta e demais grandes
                vasos.
                <br />
                <br />
                ARRITMIAS CARDÍACAS- Arritmias cardíacas com importância clínica
                e/ou cardiopatia associada (ﬁbrilação e ﬂutter atriais; e
                outras).
                <br />
                <br />
                CARDIOPATIAS CONGÊNITA NO ADULTO - Cardiopatias congênitas com
                repercussão hemodinâmica,
                <br />
                crises hipoxêmicas; insuﬁciência cardíaca; arritmias;
                comprometimento miocárdico.
                <br />
                <br />
                PRÓTESES VALVARES E DISPOSITIVOS CARDÍACOSIMPLANTADOS -
                Portadores de próteses valvares <br />
                biológicasou mecânicas; e dispositivos cardíacos implantados
                (marca-passos, cardio desﬁbriladores, <br />
                ressincronizadores, assistência circulatória de média e longa
                permanência).
                <br />
                <br />
                DOENÇA CEREBROVASCULAR - Acidente vascular cerebral isquêmico ou
                hemorrágico; ataque isquêmicotransitório; demência vascular.
                <br />
                <br />
                DOENÇA RENAL CRÔNICA - Doença renal crônicaestágio 3 ou mais
                (taxa de ﬁltração glomerular {"<"} 60ml/min/1,73 m2) e/ou
                síndrome nefrótica.
                <br />
                <br />
                IMUNOSSUPRIMIDOS - Indivíduos transplantados de órgãosólido ou
                de medula óssea; pessoas vivendo com HIV; <br />
                doençasreumáticas imunomediadas sistêmicas em atividade e em
                usode dose de prednisona ou equivalente {">"} 10 mg/dia <br />
                ou recebendo pulsoterapia com corticoide e/ou ciclofosfamida;
                demais indivíduos em uso de imunossupressores ou <br />
                comimunodeﬁciências primárias; pacientes oncológicos
                querealizaram tratamento quimioterápico ou radioterápico <br />
                nos últimos 6 meses; neoplasias hematológicas.
                <br />
                <br />
                HEMOGLOBINOPATIAS GRAVES - Doença falciforme etalassemia maior.
                <br />
                <br />
                OBESIDADE MÓRBIDA - Índice de massa corpórea (IMC) ≥40 kg/m2.
                <br />
                <br />
                SÍNDROME DE DOWN - Trissomia do cromossomo 21.
                <br />
                <br />
                CIRROSE HEPÁTICA - Cirrose hepática Child-Pugh A, Bou C.
                <br />
              </i>
              <br />
              <br />
              <div
                style={{ color: "white" }}
                className="pv-btn-danger text-center col-md-12"
              >
                <b>
                  {" "}
                  ATENÇÃO!
                  <br />
                  <br />
                  Ao inserir os laudos ou relatório médico confira se a sua
                  comorbidade está contemplada na lista acima descrita.
                  <br />
                  Lembrando que receitas de medicamentos não são válidas para
                  comprovação de comorbidade.
                  <br />
                  No ato da vacinação serão verificados os dados do laudo ou
                  relatório médico,
                  <br />
                  e a vacinação poderá ser bloqueada no momento, em caso de
                  laudos emitidos há mais 12 meses,
                  <br />
                  relatório médico sem assinatura e carimbo, receitas de
                  medicamentos e comorbidades que não
                  <br />
                  estejam na lista de prioridades estabelecida pelo Ministério
                  da Saúde.
                  <br />
                </b>{" "}
              </div>{" "}
              <b>
                <br />
                <br />
                Antes de iniciar, tenha todos os documentos em arquivo digital
                (foto ou PDF), pois estes documentos são obrigatórios para a
                finalização do seu cadastro.
              </b>
              <br />
              Antes de iniciar, é necessário ler e aceitar o Termo abaixo.
              <br />
              <object
                style={{ height: "400PX", width: "120%" }}
                data="https://cdn.anapolis.go.gov.br/img/comorbidades/termo.pdf"
                type="application/pdf"
              >
                <p>
                  Leia o pdf{" "}
                  <a href="https://cdn.anapolis.go.gov.br/img/comorbidades/termo.pdf">
                    aqui!
                  </a>
                </p>
              </object>
              <br />
              <div
                style={{ color: "white" }}
                className="pv-btn-danger text-center col-md-12"
              >
                Atenção: Para evitar erros, utilize preferencialmente
                <br />
                os navegadores{" "}
                <b>Google Chrome ou Mozilla Firefox em um Computador.</b>
              </div>
              <form id="wizard" onSubmit={this.handleSubmit}>
                <FormControlLabel
                  control={
                    <Checkbox
                      label="termo"
                      name="termo"
                      checked={this.state.termo}
                      onChange={(e) =>
                        this.setState({ termo: e.target.checked })
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="Eu li e estou de acordo"
                />
                <button
                  hidden={this.state.termo === false}
                  disabled={this.state.termo === false}
                  id="cadastro_vacina"
                  className={button}
                  type="submit"
                >
                  Cadastrar
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export class CadastrarComorbidadesEstudantes extends Component {
  constructor() {
    super();
    this.state = {
      dados: [],
      alert: {
        status: "",
        message: "",
      },
      cpf: "",
      tipRes: "",
      quadra: "",
      lote: "",
      pontoreferencia: "",
      bairros: [],
      comorbidades: [],
      comorbidade: "",
      idade: "",
      nome: "",
      dtNascimento: "",
      complemento: "",
      cep: "",
      tipo_pne: "",
      numero: "",
      vagos: "",
      limite: "",
      idadeCad: "",
      cnh: false,
      nacionalidade: "BR",
      vacinado: false,
      comorbidadeId: "",
      tipo_cadastro: "",
      idade_min: "",
      dtCrianca: "",
      idadeCrianca: "",
      esconder: true,
      imgcpf: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgrgFrente: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgrgVerso: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgcompresid: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgsus: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgcnhV: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgcnh: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imglaudo: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imggestantes: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgpuerperas: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      imgmarcapasso: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEventos = this.getEventos.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.deleteArquivos = this.deleteArquivos.bind(this);
    this.validAnexos = this.validAnexos.bind(this);
    this.validLaudos = this.validLaudos.bind(this);
    this.buscaComorbidade = this.buscaComorbidade.bind(this);
    this.limpaState = this.limpaState.bind(this);
    this.calcula = this.calcula.bind(this);
  }

  async getEventos() {
    getBairros().then((response) => {
      this.setState({ bairros: response.data });
    });
    getComorbidades().then((response) => {
      this.setState({ comorbidades: response.data });
    });
    await getVagasCidadao().then((response2) => {
      this.setState({
        vagos: response2.data[0],
        limite: response2.data[1],
        idadeCad: response2.data[3],
      });
    });
  }

  validPhone(phone) {
    const isCelular = phone.length >= 14;
    if (isCelular === false) {
      $("#telefone").addClass("telefoneErr");
      $("#cadastro_vacina").attr("disabled", true);
      $("#cadastro_vacina").addClass("btn-danger");
      $("#cadastro_vacina").removeClass("btn-primary");
    } else {
      $("#telefone").removeClass("telefoneErr");
      $("#cadastro_vacina").removeAttr("disabled", false);
      $("#cadastro_vacina").removeClass("btn-danger");
      $("#cadastro_vacina").addClass("btn-primary");
    }
  }

  async buscaComorbidade() {
    let id = this.state.comorbidadeId;
    await getComorbidadesIdades(id).then((response) => {
      this.setState({ idade_min: response.data.idade_min });
    });

    let idade_min = this.state.idade_min;
    let teste = this.state.idade;
  }

  componentDidMount() {
    this.getEventos();
  }

  apareceAlert() {
    setTimeout(() => {
      this.setState({ setOpen: false });
      var cpf = this.state.cpf;
      var e = window.btoa(cpf);
      return (window.location = "/CadastroCpf?" + e);
    }, 1 * 1000);
  }

  calculaIdade(dataNasc) {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split("/");
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;
    if (mesAtual < mesNasc) {
      idade--;
    } else {
      //Se estiver no mes do nascimento, verificar o dia
      if (mesAtual == mesNasc) {
        if (new Date().getDate() < diaNasc) {
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
          idade--;
        }
      }
    }
    return idade;
  }
  buscadtNascimento = (event) => {
    var data;

    if (event.target === undefined) {
      data = event;
    } else {
      data = event.target.value;
    }
    var date1 = data.split("-");
    date1 = date1[2] + "/" + date1[1] + "/" + date1[0];
    var teste = this.calculaIdade(date1);
    this.setState({ idade: teste });
    // if (teste >= '12') { }
    // else {
    //     alert("Idade de " + teste + " anos, menor que o permitido!")
    //     this.limpaState()
    //     setTimeout(() => {
    //         return (window.location = "/Cadastrar");
    //     }, 100)
    // }
  };

  calcula() {
    var dataAtual = new Date();
    data2 = this.state.dtCrianca;
    var data1 = new Date(dataAtual);
    var data2 = new Date(data2);
    var total = (data1 - data2) / (1000 * 3600 * 24);
    this.setState({ idadeCrianca: total });
    if (total > 45) {
      alert(
        "Só é permitido cadastro de Puérperas quando a criança tem no máximo 45 dias de vida"
      );
      this.limpaState();
      setTimeout(() => {
        return (window.location = "/Cadastrar");
      }, 100);
    }
  }

  cepBlur = (event) => {
    let cep = event.target.value;
    if (cep > "75999-999" || cep < "74999-999") {
      alert(
        "Cidade não permitida para Cadastro! Procure a unidade do seu Municipio"
      );
      setTimeout(() => {
        return (window.location = "/Cadastrar");
      }, 100);
    }
  };

  onClose() {
    window.opener = null;
    window.open("", "_self");
    window.close();
  }

  buscaCpf = (event) => {
    var id = event.target.value;
    let validCPF = cpf.isValid(id);
    if (validCPF === false) {
      this.limpaState();
      alert("Verifique o CPF digitado ele está inválido");
      this.setState({ cpf: "" });
      setTimeout(() => {
        $("#resposta").html("");
      }, 1 * 100);
    } else {
      getCadastrados(id).then((response) => {
        if (response.data.data_nasc != null) {
          this.setState({
            nome: response.data.nome,
            dtNascimento: response.data.data_nasc,
          });
          this.buscadtNascimento(this.state.dtNascimento);
          $("#telefone").focus();
          $("#nome").attr("readonly", true);
          $("#dataNasc").attr("readonly", true);
        }
        if (response.data.cadastrado >= 1) {
          alert("Usuario já Cadastrado, não é possivel cadastrar novamente!");
          this.setState({ cpf: "" });
          setTimeout(() => {
            $("#resposta").html("");
          }, 1 * 1000);
          return (window.location = "/Cadastrar");
        } else {
          setTimeout(() => {
            $("#anexos").css("display", "block");
          }, 1 * 100);
        }
      });
    }
  };

  valueCPF = (event) => {
    this.setState({ cpf: event.target.value });
  };
  validAnexos() {
    if (this.state.cnh === false) {
      if ($("#cpfUp").val() === "" || $("#cpfUp").val() === null) {
        $("#cpfUp").focus();
        return false;
      } else {
        if ($("#compresid").val() === "" || $("#compresid").val() === null) {
          $("#compresid").focus();
          return false;
        } else {
          return true;
        }
      }
    } else {
      if ($("#cnh").val() === "" || $("#cnh").val() === null) {
        $("#cnh").focus();
        return false;
      } else {
        if ($("#compresid").val() === "" || $("#compresid").val() === null) {
          $("#compresid").focus();
          return false;
        } else {
          return true;
        }
      }
    }
  }

  validLaudos() {
    if (this.state.comorbidadeId == 46) {
      return true;
    }
    if (this.state.comorbidadeId == 25) {
      if ($("#gestantes").val() === "" || $("#gestantes").val() === null) {
        $("#gestantes").on("focus");
        return false;
      } else {
        return true;
      }
    } else if (this.state.comorbidadeId == 26) {
      if ($("#puerperas").val() === "" || $("#puerperas").val() === null) {
        $("#puerperas").focus();
        return false;
      } else {
        return true;
      }
    } else if (this.state.comorbidadeId == 40) {
      if ($("#marcapasso").val() === "" || $("#marcapasso").val() === null) {
        $("#marcapasso").focus();
        return false;
      } else {
        return true;
      }
    } else {
      if ($("#laudo").val() === "" || $("#laudo").val() === null) {
        $("#laudo").focus();
        return false;
      } else {
        return true;
      }
    }
  }

  mCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  };

  limpaState() {
    this.setState({
      cpf: "",
      // tipRes: '',
      // quadra: '',
      // lote: '',
      // pontoreferencia: '',
      // idade: '',
      // nome: '',
      // dtNascimento: '',
      // complemento: '',
      // cep: '',
      // tipo_pne: '',
      // numero: '',
      // vagos: '',
      // limite: '',
      // idadeCad: '',
      // cnh: false,
      // nacionalidade: '',
      // tipo_cadastro: '',
      // vacinado: false,
      // imgcpf: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgrgFrente: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgrgVerso: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgcompresid: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgsus: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
      // imgcnhV: 'https://cdn.anapolis.go.gov.br/img/vacina/noimage.png',
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    var cpf, cpf2;
    if (cpf == "" || cpf == null) {
      cpf = this.state.cns;
    } else {
      cpf = this.state.cpf;
    }
    cpf2 = this.state.cpf2;
    if (this.validLaudos() == false) {
      alert("É Obrigatorio anexar o Laudo/Exame/Certidão de Nascimento");
    } else {
      if (this.validAnexos() == false) {
        alert("ANEXOS NÃO PODEM FICAR SEM ARQUIVO");
      } else {
        const {
          nome,
          telefone,
          dtNascimento,
          idade,
          rg,
          pne,
          tipo_pne,
          cep,
          numero,
          complemento,
          quadra,
          lote,
          pontoreferencia,
          bairro,
          sexo,
          cns,
          nome_mae,
          nacionalidade,
          tipo_cadastro,
        } = this.state;
        const logradouro = $("#rua").val();
        var raw = {
          cpf2,
          cpf,
          nome,
          telefone,
          dtNascimento,
          idade,
          rg,
          pne,
          tipo_pne,
          cep,
          logradouro,
          numero,
          complemento,
          quadra,
          lote,
          pontoreferencia,
          bairro,
          sexo,
          cns,
          nome_mae,
          nacionalidade,
          tipo_cadastro,
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        await apiBusca
          .post("/cadastros/formCadComorbidadesAlunos", requestOptions)
          .then((response) => {
            this.setState({
              alert: {
                status: response.data.response.status,
                message: response.data.response.result,
              },
            });
            let propis = this.props;
            if (this.state.alert.status === 200) {
              this.limpaState();
              Toast.fire({
                icon: "success",
                title: this.state.alert.message,
              }).then(function() {
                apareceAlertProtocolo(cpf);
              });
            } else if (this.state.alert.status === 201) {
              let error = this.state.alert.message;
              Toast.fire({
                icon: "error",
                title: this.state.alert.message,
              }).then(function() {
                apareceAlertProtocoloError(error);
              });
            }
          })

          .catch((error) => {
            this.setState({
              alert: {
                status: 201,
                message:
                  "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG",
              },
            });
          });
      }
    }
  }

  //uploads de Arquivos
  handleOnChange = (e) => {
    const file = e.target.files;
    const tipo = e.target.name;
    if (file.length === 0) {
      return; // se não selecionar nenhum file
    }
    //funcao de resize
    this.uploadFile(file[0], tipo);
    // resize(image, callback);
    e.target.files = null;
  };

  async uploadFile(file, tipo) {
    var cpf;
    if (this.state.cpf === "" || this.state.cpf === null) {
      cpf = this.state.cns;
    } else {
      cpf = this.state.cpf;
    }
    const formData = new FormData();
    formData.append("fileCHEFE", file);
    formData.append("tipo", tipo);
    var imgUpload = "img" + tipo;
    await api
      .post("/upload/filesAlunosComorbidades/" + cpf, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        this.setState({ [imgUpload]: response.data.response.result });
      });
  }

  deleteArquivos = (ev) => {
    ev.preventDefault();
    var teste = ev.target.value.split(
      "https://cdn.anapolis.go.gov.br/img/vacina-tst/"
    );
    var deletar = window.btoa(teste[1]);
    let img = ev.target.name;
    return api.post("/upload/deleteFiles/" + deletar, {}).then((response) => {
      this.setState({
        [img]: "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png",
      });
    });
  };

  render() {
    var alertMsg = this.state.alert;
    const sexo = [
      {
        value: "masculino",
        label: "Masculino",
      },
      {
        value: "feminino",
        label: "Feminino",
      },
      {
        value: "outros",
        label: "Outros",
      },
    ];
    const valores = [
      {
        value: "sim",
        label: "Sim",
      },
      {
        value: "nao",
        label: "Não",
      },
    ];
    var busca,
      buscaimgcnhV,
      buscaimgcpf,
      buscaimgrgFrente,
      buscaimgrgVerso,
      buscaimgcompresid,
      buscaimgsus,
      buscaimglaudo,
      buscaimggestantes,
      buscaimgpuerperas,
      buscaimgmarcapasso;
    if (
      this.state.imgcnh ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      busca = true;
    }
    if (
      this.state.imgcnhV ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgcnhV = true;
    }
    if (
      this.state.imgcpf ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgcpf = true;
    }
    if (
      this.state.imgrgFrente ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgrgFrente = true;
    }
    if (
      this.state.imgrgVerso ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgrgVerso = true;
    }
    if (
      this.state.imgcompresid ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgcompresid = true;
    }
    if (
      this.state.imgsus ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgsus = true;
    }
    if (
      this.state.imglaudo ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimglaudo = true;
    }
    if (
      this.state.imggestantes ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimggestantes = true;
    }
    if (
      this.state.imgpuerperas ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgpuerperas = true;
    }
    if (
      this.state.imgmarcapasso ===
      "https://cdn.anapolis.go.gov.br/img/vacina/noimage.png"
    ) {
      buscaimgmarcapasso = true;
    }

    if (
      $("#cpfUp").val() == "" ||
      $("#cpfUp").val() == null ||
      buscaimgcpf === true
    ) {
      $("#btn-cpf").css("color", "white");
      $("#btn-cpf").css("background-color", "red");
    } else {
      $("#btn-cpf").css("color", "white");
      $("#btn-cpf").css("background-color", "green");
    }
    if ($("#cnh").val() === "" || $("#cnh").val() === null || busca === true) {
      $("#btn-cnh").css("color", "white");
      $("#btn-cnh").css("background-color", "red");
    } else {
      $("#btn-cnh").css("color", "white");
      $("#btn-cnh").css("background-color", "green");
    }
    if (
      $("#cnhV").val() === "" ||
      $("#cnhV").val() === null ||
      buscaimgcnhV === true
    ) {
      $("#btn-cnhV").css("color", "white");
      $("#btn-cnhV").css("background-color", "red");
    } else {
      $("#btn-cnhV").css("color", "white");
      $("#btn-cnhV").css("background-color", "green");
    }

    if (
      $("#rgFrente").val() === "" ||
      $("#rgFrente").val() === null ||
      buscaimgrgFrente === true
    ) {
      $("#btn-rgFrente").css("color", "white");
      $("#btn-rgFrente").css("background-color", "red");
    } else {
      $("#btn-rgFrente").css("color", "white");
      $("#btn-rgFrente").css("background-color", "green");
    }

    if (
      $("#rgVerso").val() === "" ||
      $("#rgVerso").val() === null ||
      buscaimgrgVerso === true
    ) {
      $("#btn-rgVerso").css("color", "white");
      $("#btn-rgVerso").css("background-color", "red");
    } else {
      $("#btn-rgVerso").css("color", "white");
      $("#btn-rgVerso").css("background-color", "green");
    }

    if (
      $("#compresid").val() === "" ||
      $("#compresid").val() === null ||
      buscaimgcompresid === true
    ) {
      $("#btn-compresid").css("color", "white");
      $("#btn-compresid").css("background-color", "red");
    } else {
      $("#btn-compresid").css("color", "white");
      $("#btn-compresid").css("background-color", "green");
    }

    if (
      $("#sus").val() === "" ||
      $("#sus").val() === null ||
      buscaimgsus === true
    ) {
      $("#btn-sus").css("color", "white");
      $("#btn-sus").css("background-color", "red");
    } else {
      $("#btn-sus").css("color", "white");
      $("#btn-sus").css("background-color", "green");
    }

    if (
      $("#laudo").val() === "" ||
      $("#laudo").val() === null ||
      buscaimglaudo === true
    ) {
      $("#btn-laudo").css("color", "white");
      $("#btn-laudo").css("background-color", "red");
    } else {
      $("#btn-laudo").css("color", "white");
      $("#btn-laudo").css("background-color", "green");
    }

    if (
      $("#gestantes").val() === "" ||
      $("#gestantes").val() === null ||
      buscaimggestantes === true
    ) {
      $("#btn-gestantes").css("color", "white");
      $("#btn-gestantes").css("background-color", "red");
    } else {
      $("#btn-gestantes").css("color", "white");
      $("#btn-gestantes").css("background-color", "green");
    }

    if (
      $("#puerperas").val() === "" ||
      $("#puerperas").val() === null ||
      buscaimgpuerperas === true
    ) {
      $("#btn-puerperas").css("color", "white");
      $("#btn-puerperas").css("background-color", "red");
    } else {
      $("#btn-puerperas").css("color", "white");
      $("#btn-puerperas").css("background-color", "green");
    }

    if (
      $("#marcapasso").val() === "" ||
      $("#marcapasso").val() === null ||
      buscaimgmarcapasso === true
    ) {
      $("#btn-marcapasso").css("color", "white");
      $("#btn-marcapasso").css("background-color", "red");
    } else {
      $("#btn-marcapasso").css("color", "white");
      $("#btn-marcapasso").css("background-color", "green");
    }

    var cnh = this.state.cnh;

    return (
      <div className="conteudo">
        <NavTop />
        <br />
        <div className="content-wrapper">
          {/* Main content */}
          <section className="content">
            <form id="wizard" onSubmit={this.handleSubmit}>
              <Alerta
                setOpen={this.state.setOpen}
                texto={alertMsg.message}
                classe={alertMsg.status}
              />
              <fieldset className="card">
                <legend className="card-header text-left legend-pma faq-btn-link">
                  Informações importantes
                </legend>
                <span className="text-center">
                  Está com problemas para se cadastrar?
                  <a href="/Contato"> Clique Aqui</a>
                </span>
                <div id="resposta"></div>
                <div
                  style={{ color: "white" }}
                  className="pv-btn-danger text-center col-md-12"
                >
                  ATENÇÃO!!!!
                  <br /> Para finalizar o seu cadastro é necessário
                  <br /> preencher todos os campos obrigatórios e anexar todos
                  os documentos.
                  <br />O campo do CPF é obrigatório, se não possuir cpf, o
                  cartão do SUS passa a ser obrigatório
                </div>
                <div className="col-md-12 campos-pma card-body">
                  <div className="col-md-12">
                    <FormControlLabel
                      control={
                        <Checkbox
                          label="termo"
                          name="termo"
                          checked={this.state.cnh}
                          onChange={(e) =>
                            this.setState({ cnh: e.target.checked })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Possuo CPF"
                    />
                  </div>
                  {{
                    ...(cnh === true ? (
                      <TextField
                        required
                        className="ml-3 mb-3 col-md-5"
                        id="2"
                        InputProps={{
                          readOnly: false,
                        }}
                        inputProps={{
                          maxlength: 14,
                        }}
                        fullWidth
                        name="cpf"
                        label="C.P.F."
                        variant="outlined"
                        value={this.state.cpf}
                        type="text"
                        onBlur={this.buscaCpf}
                        onChange={(e) =>
                          this.setState({
                            cpf: e.target.value,
                            cpf2: e.target.value,
                            esconder: false,
                          })
                        }
                      />
                    ) : (
                      <TextField
                        required
                        className="ml-3 mb-3 col-md-5"
                        id="cns"
                        InputProps={{
                          readOnly: false,
                        }}
                        fullWidth
                        name="cns"
                        label="Cartão do SUS"
                        variant="outlined"
                        type="text"
                        value={this.state.cns}
                        inputProps={{
                          maxLength: 200,
                        }}
                        onChange={(e) =>
                          this.setState({
                            cns: e.target.value,
                            esconder: false,
                          })
                        }
                      />
                    )),
                  }}

                  <TextField
                    required
                    className="ml-3 mb-3 col-md-6"
                    id="nome"
                    InputProps={{
                      readOnly: false,
                    }}
                    fullWidth
                    name="nome"
                    label="Nome"
                    variant="outlined"
                    type="text"
                    value={this.state.nome}
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(e) => this.setState({ nome: e.target.value })}
                  />
                  <TextField
                    required
                    className="mb-3 ml-3 col-md-5"
                    id="dataNascimento"
                    InputProps={{
                      readOnly: false,
                    }}
                    fullWidth
                    name="dtNascimento"
                    label="Data de Nascimento"
                    variant="outlined"
                    type="date"
                    value={this.state.dtNascimento}
                    onBlur={this.buscadtNascimento}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      this.setState({ dtNascimento: e.target.value })
                    }
                  />
                  <TextField
                    required
                    className="ml-3 mb-3 col-md-6"
                    id="idade"
                    fullWidth
                    name="idade"
                    label="Idade"
                    variant="outlined"
                    type="number"
                    value={this.state.idade}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Tooltip
                    title="Digitar DDD e numero com 9 Ex. (62) 9 9999-9999"
                    placement="top"
                  >
                    <TextField
                      placeholder="Ex. (62) 9 9999-9999"
                      required
                      className="mb-3 ml-3 col-md-5"
                      id="telefone"
                      InputProps={{
                        readOnly: false,
                      }}
                      fullWidth
                      name="telefone"
                      label="Telefone"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                      onKeyDown={(e) => this.validPhone(e.target.value)}
                      onChange={(e) =>
                        this.setState({ telefone: e.target.value })
                      }
                    />
                  </Tooltip>

                  <TextField
                    className="ml-3 mb-3 col-md-6"
                    id="rg"
                    fullWidth
                    name="rg"
                    label="RG"
                    variant="outlined"
                    type="text"
                    onChange={(e) => this.setState({ rg: e.target.value })}
                  />

                  <TextField
                    required
                    className="ml-3 mb-3 col-md-5"
                    id="nome_mae"
                    fullWidth
                    name="nome_mae"
                    label="Nome da Mãe"
                    variant="outlined"
                    type="text"
                    onChange={(e) =>
                      this.setState({ nome_mae: e.target.value })
                    }
                  />
                  <TextField
                    InputLabelProps={{
                      ...(cnh === false
                        ? { readOnly: true, shrink: true }
                        : { readOnly: false }),
                    }}
                    {...(cnh === false ? { hidden: true } : {})}
                    className="ml-3 mb-3 col-md-6"
                    id="cns"
                    fullWidth
                    name="cns"
                    label="Nº Cartão do SUS(Não Obrigatório)"
                    variant="outlined"
                    type="text"
                    value={this.state.cns}
                    onChange={(e) => this.setState({ cns: e.target.value })}
                  />

                  <TextField
                    required
                    className="mb-3 ml-3 col-md-6 required"
                    id="sexo"
                    select
                    fullWidth
                    name="sexo"
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(e) => this.setState({ sexo: e.target.value })}
                    label="Sexo"
                    variant="outlined"
                  >
                    <option key={0} value=""></option>
                    {sexo.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    required
                    className="mb-3 ml-3 col-md-5 required"
                    id="pne"
                    select
                    fullWidth
                    name="pne"
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(e) => this.setState({ pne: e.target.value })}
                    label="Acamado"
                    variant="outlined"
                  >
                    <option key={0} value=""></option>
                    {valores.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <div className="mb-3 col-md-6 required">
                    <Autocomplete
                      disabled={this.state.idade == ""}
                      id="comorbidades"
                      options={this.state.comorbidades}
                      onBlur={this.buscaComorbidade}
                      getOptionLabel={(option) => option.tipo}
                      onChange={(option, value) => {
                        if (value != null) {
                          this.setState({
                            comorbidade: value.tipo,
                            comorbidadeId: value.id,
                            tipo_cadastro: value.tipo_cadastro,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="comorbidades"
                          label="Comorbidade"
                          required
                          name="comorbidades"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <TextField
                    required={this.state.comorbidadeId === 26}
                    hidden={this.state.comorbidadeId != 26}
                    className="mb-3 ml-3 col-md-5"
                    id="dataNascimentoCrianca"
                    InputProps={{
                      readOnly: false,
                    }}
                    fullWidth
                    name="dtNascimentoCrianca"
                    label="Data de Nascimento da Criança"
                    variant="outlined"
                    type="date"
                    value={this.state.dtCrianca}
                    onBlur={this.calcula}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      this.setState({ dtCrianca: e.target.value })
                    }
                  />
                  <TextField
                    required
                    className="mb-3 ml-3 col-md-5 required"
                    id="nacionalidade"
                    fullWidth
                    name="nacionalidade"
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(e) =>
                      this.setState({ nacionalidade: e.target.value })
                    }
                    label="Nacionalidade"
                    variant="outlined"
                  />
                </div>
              </fieldset>
              <fieldset className="card ">
                <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                  Upload de documentos
                </legend>
                <br />
                <div className="row">
                  <div
                    style={{ color: "white" }}
                    className="pv-btn-danger text-center col-md-12"
                  >
                    ATENÇÃO!!!!
                    <br /> Para finalizar o seu cadastro é necessário
                    <br /> preencher todos os campos obrigatórios e anexar todos
                    os documentos.
                    <br />
                    <p></p>
                  </div>
                  <br />
                  <div
                    hidden={this.state.esconder}
                    className="col-md-6 campos-pma"
                  >
                    <div className="">
                      {{
                        ...(cnh === true ? (
                          <Button
                            id="btn-cpf"
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            C.P.F.
                            <input
                              onChange={this.handleOnChange}
                              name="cpf"
                              id="cpfUp"
                              hidden
                              className="ml-3 mb-3 col-md-12"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                            />
                          </Button>
                        ) : (
                          <Button
                            id="btn-cnh"
                            className="ml-3 mb-3 col-md-6"
                            variant="contained"
                            component="label"
                          >
                            Cartão do SUS
                            <input
                              onChange={this.handleOnChange}
                              name="cnh"
                              id="cnh"
                              hidden
                              className="ml-3 mb-3 col-md-12"
                              type="file"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                            />
                          </Button>
                        )),
                      }}
                      <div
                        id="div-gestantes"
                        hidden={this.state.comorbidadeId != 25}
                      >
                        <Button
                          className="ml-3 mb-3 col-md-6"
                          variant="contained"
                          component="label"
                          id="btn-gestantes"
                        >
                          Exame Comprobatório
                          <input
                            onChange={this.handleOnChange}
                            name="gestantes"
                            id="gestantes"
                            className="ml-3 mb-3 col-md-6"
                            type="file"
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            style={{ display: "none" }}
                          />
                        </Button>
                      </div>

                      <div
                        id="div-puerperas"
                        hidden={this.state.comorbidadeId != 26}
                      >
                        <Button
                          className="ml-3 mb-3 col-md-6"
                          variant="contained"
                          component="label"
                          id="btn-puerperas"
                        >
                          Certidão De Nascimento da Criança
                          <input
                            onChange={this.handleOnChange}
                            name="puerperas"
                            id="puerperas"
                            className="ml-3 mb-3 col-md-6"
                            type="file"
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            style={{ display: "none" }}
                          />
                        </Button>
                      </div>

                      <div
                        id="div-marcapasso"
                        hidden={this.state.comorbidadeId != 40}
                      >
                        <Button
                          className="ml-3 mb-3 col-md-6"
                          variant="contained"
                          component="label"
                          id="btn-marcapasso"
                        >
                          Documento de Identificação de Portador de Marcapasso
                          <input
                            onChange={this.handleOnChange}
                            name="marcapasso"
                            id="marcapasso"
                            className="ml-3 mb-3 col-md-6"
                            type="file"
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            style={{ display: "none" }}
                          />
                        </Button>
                      </div>
                      <div
                        hidden={
                          this.state.comorbidadeId == 25 ||
                          this.state.comorbidadeId == 26 ||
                          this.state.comorbidadeId == 40 ||
                          this.state.comorbidadeId == 46
                        }
                      >
                        <Button
                          className="ml-3 mb-3 col-md-6"
                          variant="contained"
                          component="label"
                          id="btn-laudo"
                        >
                          Laudo Médico
                          <input
                            onChange={this.handleOnChange}
                            name="laudo"
                            id="laudo"
                            className="ml-3 mb-3 col-md-6"
                            type="file"
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            style={{ display: "none" }}
                          />
                        </Button>
                      </div>
                      <Button
                        className="ml-3 mb-3 col-md-6"
                        variant="contained"
                        component="label"
                        id="btn-compresid"
                      >
                        Comprovante Residencia
                        <input
                          onChange={this.handleOnChange}
                          name="compresid"
                          id="compresid"
                          className="ml-3 mb-3 col-md-6"
                          type="file"
                          accept="application/pdf, image/png, image/jpg, image/jpeg"
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                  </div>
                  <div hidden className="col-md-5 row">
                    <div hidden={this.state.cnh === false} className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imgcnh"
                        hidden={busca}
                        value={this.state.imgcnh}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="logopma"
                        width="100px"
                        height="100px"
                        src={this.state.imgcnh}
                      />
                    </div>

                    <div hidden={this.state.cnh === false} className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imgcnhV"
                        hidden={buscaimgcnhV}
                        value={this.state.imgcnhV}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="logopma"
                        width="100px"
                        height="100px"
                        src={this.state.imgcnhV}
                      />
                    </div>

                    <div hidden={this.state.cnh === true} className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imgcpf"
                        hidden={buscaimgcpf}
                        value={this.state.imgcpf}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="imgcpf"
                        width="100px"
                        height="100px"
                        src={this.state.imgcpf}
                      />
                    </div>

                    <div hidden={this.state.cnh === true} className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imgrgFrente"
                        hidden={buscaimgrgFrente}
                        value={this.state.imgrgFrente}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="imgrgfrente"
                        width="100px"
                        height="100px"
                        src={this.state.imgrgFrente}
                      />
                    </div>

                    <div hidden={this.state.cnh === true} className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imgrgVerso"
                        hidden={buscaimgrgVerso}
                        value={this.state.imgrgVerso}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="imgrgverso"
                        width="100px"
                        height="100px"
                        src={this.state.imgrgVerso}
                      />
                    </div>

                    <div className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imgcompresid"
                        hidden={buscaimgcompresid}
                        value={this.state.imgcompresid}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="compresid"
                        width="100px"
                        height="100px"
                        src={this.state.imgcompresid}
                      />
                    </div>

                    <div className="col-md-6">
                      <button
                        className="btn-danger-img"
                        name="imglaudo"
                        hidden={buscaimglaudo}
                        value={this.state.imglaudo}
                        onClick={this.deleteArquivos}
                      >
                        X
                      </button>
                      <img
                        alt="imglaudo"
                        width="100px"
                        height="100px"
                        src={this.state.imglaudo}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="card ">
                <legend className="col-md-12  text-left card-header legend-pma faq-btn-link">
                  Endereço
                </legend>
                <br />
                <div className="col-md-12 campos-pma">
                  <div className="col-md-6">
                    <TextField
                      id="cep"
                      fullWidth
                      required
                      name="cep"
                      label="CEP"
                      variant="outlined"
                      type="text"
                      value={this.state.cep}
                      onChange={(e) => this.setState({ cep: e.target.value })}
                      onBlur={this.cepBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title="Digitar a Rua, Av., Trav. não deixar em branco ou com 0"
                      placement="top"
                    >
                      <TextField
                        id="rua"
                        required
                        fullWidth
                        placeholder="Ex: Rua, Av, Trav."
                        name="logradouro"
                        label="Logradouro"
                        variant="outlined"
                        type="text"
                        value={this.state.logradouro}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title="Se não Possuir número digite 0"
                      placement="top"
                    >
                      <TextField
                        id="numero"
                        required
                        fullWidth
                        name="numero"
                        placeholder="Apenas número, se não possuir deixe com 0"
                        label="Numero"
                        variant="outlined"
                        type="number"
                        value={this.state.numero}
                        onChange={(e) =>
                          this.setState({ numero: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title="Não Digitar nome de rua, quadra, lote, bairro"
                      placement="top"
                    >
                      <TextField
                        id="complemento"
                        fullWidth
                        name="complemento"
                        placeholder="Bl. Apt. Casa"
                        label="Complemento"
                        variant="outlined"
                        type="text"
                        value={this.state.complemento}
                        onChange={(e) =>
                          this.setState({ complemento: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title="Não digitar QD. ,Q. ,Quadra, etc"
                      placement="top"
                    >
                      <TextField
                        id="quadra"
                        fullWidth
                        {...(this.state.numero == 0
                          ? { required: true }
                          : { required: false })}
                        name="quadra"
                        placeholder="Não digitar QD. ,Q. ,Quadra, etc"
                        label="Quadra"
                        variant="outlined"
                        type="text"
                        value={this.state.quadra}
                        onChange={(e) =>
                          this.setState({ quadra: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title="Não digitar Lt., L., Lote, etc"
                      placement="top"
                    >
                      <TextField
                        id="lote"
                        fullWidth
                        {...(this.state.numero == 0
                          ? { required: true }
                          : { required: false })}
                        name="lote"
                        placeholder="Não digitar Lt., L., Lote, etc"
                        label="Lote"
                        variant="outlined"
                        type="text"
                        value={this.state.lote}
                        onChange={(e) =>
                          this.setState({ lote: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title="Digitar apenas ponto de referência, se existir"
                      placement="top"
                    >
                      <TextField
                        id="pontoreferencia"
                        fullWidth
                        name="pontoreferencia"
                        placeholder="Digitar apenas ponto de referência, se existir"
                        label="Ponto de Referência"
                        variant="outlined"
                        type="text"
                        onChange={(e) =>
                          this.setState({ pontoreferencia: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-md-6">
                    <Autocomplete
                      id="bairro2"
                      options={this.state.bairros}
                      getOptionLabel={(option) => option.nome}
                      onChange={(option, value) => {
                        if (value != null) {
                          this.setState({ bairro: value.nome });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="bairro"
                          label="Bairro"
                          required
                          name="bairro"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <button
                    id="cadastro_vacina"
                    className="btn-pv col-md-12 text-center pv-btn-primary"
                    type="submit"
                  >
                    Cadastrar
                  </button>
                </div>
              </fieldset>
            </form>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
