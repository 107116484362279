import React, { Component } from "react";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { postProtocolo } from "../services/dados";
import { cpfMask } from './mask'
import { cdnURL } from "../services/api";


class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            protocolo: '',
        };
        this.getEventos = this.getEventos.bind(this);
    }
    async getEventos() {
        var id = this.props.location.search
        var prot = window.atob(id.replace("?", ""))
        let prot2 = prot.replace(".", "")
        let cpf = prot2.split("-")
        let prot3 = prot2.replace(cpf[2], "").replace("--", "-")
        let gravProt = window.btoa(prot3 + "/" + cpf[2])
        postProtocolo(gravProt)
        this.setState({ protocolo: prot3, cpf: cpf[2] })
    }
    componentDidMount() {
        this.getEventos();
    }

    render() {
        let protocolo = this.state.protocolo
        let cpf = this.state.cpf
        var cpf2 = cpfMask(cpf)
        return (
            <div className="conteudo">
                <NavTop />
                <div className="home">
                    <span className="pma-text-center text-center">Está com problemas para se cadastrar?
                        <a href="/Contato"> Clique Aqui</a></span>
                    <br />
                    <div id='printable' className='col-md-12'>
                        <h1 className="text-center ">
                            Cadastro #IMUNIZAANÁPOLIS
                        </h1>
                        <br />
                        <img src={cdnURL + 'vacinaSolidaria.jpg'} className="pma-center img img-responsive" width='100%' />
                        <hr />
                        <div className="text-center mb-3">
                            <h3> Parabéns! Seu cadastro foi realizado com sucesso!<br />
                                O número do seu protocolo é:<br /></h3>
                            <h2><b>{protocolo}</b></h2><br />
                            <h3>No dia da vacinação, não se esqueça de levar um documento pessoal de identificação.<br /></h3>
                            <h3>Se o cadastro é de comorbidades, nao se esqueça de levar o laudo também.<br /></h3><br />
                            <h2>Acompanhe as datas de vacinação através das redes oficiais da Prefeitura de Anápolis.<br />
                                <a href='https://www.anapolis.go.gov.br/vacinacao-em-anapolis/'>
                                    ou Clique Aqui!
                                </a>
                            </h2>
                        </div>
                    </div>
                    <button className="pv-btn pv-btn-primary col-md-6 pma-text-center"
                        onClick={() => window.print()}>IMPRIMIR</button>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Panel;
