import React, { Component } from "react";
import api from "./../services/api";
import {
  Modal,
  Button,
  Navbar,
  InputGroup,
  FormControl
} from "react-bootstrap";
import $ from "jquery";
// import mask from "jquery-mask-plugin";
const { cpf } = require('cpf-cnpj-validator');

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      cpf: ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  mCPF() {
    $("#cpf2").mask("000.000.000-00");
  }

  validCpf = event => {

    if (cpf.isValid(event.target.value) === true) {
      $("#cadastroButton").removeAttr("style");
      $("#cadastroButton").removeAttr("disabled");
      $("#cadastroButton").addClass(" btn-success");
      $("#cadastroButton").removeClass("btn-danger");
      $("#retorno").removeClass("alert-danger");
      $("#retorno").addClass("alert-success");
      $("#retorno").html("CPF VALIDO");
    }
    else {
      $("#cadastroButton").attr("disabled", true);
      $("#cadastroButton").addClass("btn-danger");
      $("#retorno").addClass(" alert-danger");
      $("#retorno").html("CPF INVALIDO");
    }

  }

  handleChange = event => {
    this.setState({ cpf: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const user = this.state.cpf;
    localStorage.setItem("cpf", JSON.stringify(user));
    api
      .get("/atletas/cpf/" + user)
      .then(function (response) {
        localStorage.removeItem("cpf");
        return (window.location = "/Acessar");
      })
      .catch(function (error) {
        // console.error(error.response.data.message);
        return (window.location = "/Cadastro");
      });
  };

  render() {
    return (
      <header className="header">
        <link rel="stylesheet" href="/css/owl.carousel.css" />
        <div className="top-head">
          <div className="">
            <div className="">
              <div style={{ textAlign: 'center' }} className="col-md-12 row">
                <div className="col-md-6">
                  <a href="/Acessar">Acesso</a>
                </div>
                <div className="col-md-6">
                  <a href="/Cadastrar">Cadastrar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faixa-menu" />
        <div id="main-nav" className="">
          <div className="text-center col-md-12">
            <Navbar
              className="navbar-pma  navbar-expand-lg navbar-light"
              collapseOnSelect
              expand="lg"
              bg="light"
            >
              <Navbar.Brand className="navbar-brand logoPInicial" href="/Inicio">
                <img alt="logoNova" className="logoNova"
                  src="https://cdn.anapolis.go.gov.br/img/logos/sem_fundo/azuis/saude.png" />
              </Navbar.Brand>
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto text-center">
                  <Nav.Link href="/Inicio">Inicio</Nav.Link>
                  <Nav.Link href="/Calendario">Calendário</Nav.Link>
                  <Nav.Link href="/Resultados">Resultados</Nav.Link>
                  <Nav.Link href="/FotosVideos">Fotos e videos</Nav.Link>
                  <Nav.Link href="/FaleConosco">Fale Conosco</Nav.Link>
                </Nav>
              </Navbar.Collapse> */}
            </Navbar>
          </div>
        </div>
        <div style={{ textAlignLast: 'center' }} className="login-content pt-4 pb-4">
          <div className="col-md-12">
            <div className="row align-content-center">
              <div className="col-sm-12">
                <div className="msg-login">
                  <h6>Se você já é funcionario, clique para fazer login.</h6>
                  <a
                    style={{ color: '#FFF' }}
                    href="/Acessar"
                    className="col-md-4 pv-btn pv-btn-block pv-btn-primary btn-acessar"
                  >
                    Acessar
                  </a>
                </div>
              </div>

              {/* <div className="col-sm-12 col-lg-4 offset-lg-4">
                <div className="msg-login">
                  <h6>Se você ainda não tem cadastro, clique aqui.</h6>
                  <button
                    onClick={this.handleOpenModal}
                    className="pv-btn pv-btn-block pv-btn-primary btn-cadastrar"
                  >
                    Cadastrar
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Informe seu CPF
            </Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <div style={{ fontWeight: "bold" }} className="alert-danger" id="retorno"></div>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">C.P.F.:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="cpf2"
                  required="true"
                  onKeyDown={this.mCPF}
                  onKeyUpCapture={this.validCpf}
                  name="cpf"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </InputGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button id="cadastroButton" onClick={this.handleSubmit} style={{ display: "none" }} className="btn-secondary">
                Iniciar
              </Button>
              <Button onClick={this.handleCloseModal}>Close</Button>
            </Modal.Footer>
          </form>
        </Modal>
      </header>
    );
  }
}
