import React, { Component } from "react";
import { TextField, Snackbar, Checkbox, FormGroup } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from "./Sidebar";
import NavTop from "./Navtop";
import Footer from "../components/Footer";
import { apiBusca } from "../services/api";
import { getUsers, token, getTipoCadastros, getEditaCadastrosCPF } from "../services/dados"
import Swal from 'sweetalert2'

const $ = require('jquery');


export function Alerta(param) {
    const open = param.setOpen;
    var status = param.classe
    var classe
    if (status === 200) {
        classe = "success"
    } else if (status === 201 || status === 110) {
        classe = "error"
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={200}>
                <Alert severity={classe}>
                    {param.texto}
                </Alert>
            </Snackbar>
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

function apareceAlert(props) {
    var url = props.location.pathname
    var path = props.location.search
    var caminho = url + path
    return (window.location = caminho);
}

export class CadastrarTestes extends Component {
    constructor() {
        super();
        this.state = {
            dados: [],
            idadeMin: '',
            tipo: '',
            cpf: '',
            alert: {
                status: '',
                message: ''
            },
            setOpen: false,
            tipos: [],
            dadosCad: [],
            sintomas: [],
            condicoes: [],
            digAssitencialChecked: true,
            buscaAssintomaticoChecked: true,
            triagemEspecificaChecked: true,
            outro: true,
            outroSintoma: true,
            outraCondicao: true,
            sintomaOutro: '',
            condicaoOutra: '',
            dtIncioSintomas: '',
            outroDiagnostico: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    estrategia = e => {
        let value = e.target.value
        if (value === 'digAssitencial') {
            this.setState({ digAssitencialChecked: false, buscaAssintomaticoChecked: true, triagemEspecificaChecked: true })
        }
        else if (value === 'buscaAssintomatico') {
            this.setState({ digAssitencialChecked: true, buscaAssintomaticoChecked: false, triagemEspecificaChecked: true })
        }
        else if (value === 'triagemEspecifica') {
            this.setState({ digAssitencialChecked: true, buscaAssintomaticoChecked: true, triagemEspecificaChecked: false })
        }
    }

    diagnostico = e => {
        let value = e.target.value
        if (value === 'monitoramentoContatos') {
            this.setState({ monitoramentoContatos: false, investigacaoSurtos: true, MonitoramentoRiscoVOC: true, outro: true })
        }
        else if (value === 'investigacaoSurtos') {
            this.setState({ monitoramentoContatos: true, investigacaoSurtos: false, MonitoramentoRiscoVOC: true, outro: true })
        }
        else if (value === 'MonitoramentoRiscoVOC') {
            this.setState({ monitoramentoContatos: true, investigacaoSurtos: true, MonitoramentoRiscoVOC: false, outro: true })
        }
        else if (value === 'outro') {
            this.setState({ monitoramentoContatos: true, investigacaoSurtos: true, MonitoramentoRiscoVOC: true, outro: false })
        }
    }

    resultado = e => {
        let value = e.target.value
        this.setState({ resultado: value })
    }

    triagem = e => {
        let value = e.target.value
        if (value === 'trabalhadoresEssenciais') {
            this.setState({ trabalhadoresEssenciais: false, ProfissionaisSaude: true, gestantesPuerperas: true, outro: true })
        }
        else if (value === 'ProfissionaisSaude') {
            this.setState({ trabalhadoresEssenciais: true, ProfissionaisSaude: false, gestantesPuerperas: true, outro: true })
        }
        else if (value === 'gestantesPuerperas') {
            this.setState({ trabalhadoresEssenciais: true, ProfissionaisSaude: true, gestantesPuerperas: false, outro: true })
        }
        else if (value === 'outro') {
            this.setState({ trabalhadoresEssenciais: true, ProfissionaisSaude: true, gestantesPuerperas: true, outro: false })
        }
    }

    localTeste = e => {
        let value = e.target.value
        this.setState({ localTeste: value })
    }

    evolucao = e => {
        let value = e.target.value
        this.setState({ evolucao: value })
    }

    classFinal = e => {
        let value = e.target.value
        this.setState({ evolucao: value })
    }

    estadoTeste = e => {
        let value = e.target.value
        this.setState({ estadoTeste: value })
    }

    dadosClinicos = e => {
        let sintomasTarget = e.target
        let sintomasState = this.state.sintomas
        let sintomaOutro = this.state.sintomaOutro
        console.log(sintomasTarget.name)
        if (sintomasTarget.checked === true) {
            if (sintomasTarget.checked === true && sintomasTarget.name === 'outro') {
                this.setState({ outroSintoma: false })
            }
            else {
                sintomasState.push(sintomasTarget.value)
            }
        }

        else if (sintomasTarget.checked === false && sintomasTarget.name === 'outro') {
            this.setState({
                sintomas: sintomasState.filter(function (sintomas) {
                    return sintomas !== sintomaOutro
                })
            })
            this.setState({ outroSintoma: true, sintomaOutro: '' })
        }
        else {
            this.setState({
                sintomas: sintomasState.filter(function (sintomas) {
                    return sintomas !== sintomasTarget.value
                })
            });
        }
        console.log(sintomasState)
    }

    condicoes = e => {
        let condicoesTarget = e.target
        let condicoesState = this.state.condicoes
        let condicaoOutra = this.state.condicaoOutra
        if (condicoesTarget.checked === true) {
            if (condicoesTarget.checked === true && condicoesTarget.name === 'outro') {
                this.setState({ outraCondicao: false })
            }
            else {
                condicoesState.push(condicoesTarget.value)
            }
        }

        else if (condicoesTarget.checked === false && condicoesTarget.name === 'outro') {
            this.setState({
                condicoes: condicoesState.filter(function (condicoesValue) {
                    return condicoesValue !== condicaoOutra
                })
            })
            this.setState({ outraCondicao: true, condicaoOutra: '' })
        }
        else {
            this.setState({
                condicoes: condicoesState.filter(function (condicoesValue) {
                    return condicoesValue !== condicoesTarget.value
                })
            });
        }
        console.log(condicoesState)
    }

    setOutroOnBlur = e => {
        let sintomasState = this.state.sintomas
        this.setState({ sintomaOutro: e.target.value })
        sintomasState.push(this.state.sintomaOutro)
    }

    setOutraCondicaoOnBlur = e => {
        let condicoesState = this.state.condicoes
        this.setState({ condicaoOutra: e.target.value })
        condicoesState.push(this.state.condicaoOutra)
    }


    buscaCpf = async cpf => {
        let e
        if (cpf.length > 11) {
            e = window.btoa(cpf)
            await getEditaCadastrosCPF('?' + e).then(response3 => {
                this.setState({
                    dadosCad: response3.data,
                    tipo_cadastro: response3.data.tipo_cadastro
                })
            })
        }
        else {
            let cpfForm = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            e = window.btoa(cpfForm)
            await getEditaCadastrosCPF('?' + e).then(response3 => {
                this.setState({
                    dadosCad: response3.data,
                    tipo_cadastro: response3.data.tipo_cadastro
                })
            })
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const { idadeMin, tipo } = this.state
        var raw = { idadeMin, tipo }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        apiBusca.post("/cadastros/cadTipos", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                let propis = this.props
                if (this.state.alert.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: this.state.alert.message
                    }).then(function () {
                        apareceAlert(propis)
                    })

                }
                else if (this.state.alert.status === 201) {
                    Toast.fire({
                        icon: 'error',
                        title: this.state.alert.message
                    })
                }
            })

            .catch(error => {
                this.setState({
                    alert: {
                        status: 201,
                        message: "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG"
                    }
                })
            })
        event.target.reset();
    }

    render() {
        let cadastro = this.state.dadosCad
        let dataFormatada = cadastro.dt_nascimento;
        var alertMsg = this.state.alert

        let vacinacao, dose4, class1, class2
        if (cadastro.tipo_cadastro === 'comorbidade-imuno' && cadastro.idade >= 18) {
            class1 = 'col-md-5'
            class2 = 'col-md-6'

            dose4 = <div className={`ml-3 ${class2} table mt-3`}>
                <div className="col-md-12 mt-3 bg-primary fontWhite"><b>4º DOSE</b></div>
                <div className="col-md-12 mt-3">Vacinado: <b>{cadastro.vacinado4}</b></div>
                <div className="col-md-12 mt-3">Dose: <b>{cadastro.dose_vacina4}</b></div>
                <div className="col-md-12 mt-3">Lote Vacina: <b>{cadastro.lote_vacin43}</b></div>
                <div className="col-md-12 mt-3">Nome Vacina: <b>{cadastro.nome_vacin43}</b></div>
                <div className="col-md-12 mt-3">Aplicador: <b>{cadastro.aplicador4}</b></div>
            </div>
        } else {
            class1 = 'col-md-3'
            class2 = 'col-md-3'
            dose4 = ''
        }

        if (cadastro.nome_vacina === 'JANSSEN') {
            vacinacao =
                <div className='row'>
                    <div className='ml-3 mr-3 col-md-5 table mt-3'>
                        <div className="col-md-12 mt-3 bg-primary fontWhite"><b>DOSE UNICA</b></div>
                        <div className="col-md-12 mt-3">Vacinado: <b>{cadastro.vacinado}</b></div>
                        <div className="col-md-12 mt-3">Dose: <b>{cadastro.dose_vacina}</b></div>
                        <div className="col-md-12 mt-3">Lote Vacina: <b>{cadastro.lote_vacina}</b></div>
                        <div className="col-md-12 mt-3">Nome Vacina: <b>{cadastro.nome_vacina}</b></div>
                        <div className="col-md-12 mt-3">Aplicador: <b>{cadastro.aplicador}</b></div>
                    </div>
                    <div className='ml-3 mr-3 col-md-6 table mt-3'>
                        <div className="col-md-12 mt-3 bg-primary fontWhite"><b>DOSE REFORÇO</b></div>
                        <div className="col-md-12 mt-3">Vacinado: <b>{cadastro.vacinado3}</b></div>
                        <div className="col-md-12 mt-3">Dose: <b>{cadastro.dose_vacina3}</b></div>
                        <div className="col-md-12 mt-3">Lote Vacina: <b>{cadastro.lote_vacina3}</b></div>
                        <div className="col-md-12 mt-3">Nome Vacina: <b>{cadastro.nome_vacina3}</b></div>
                        <div className="col-md-12 mt-3">Aplicador: <b>{cadastro.aplicador3}</b></div>
                    </div>
                </div>
        } else {
            vacinacao =
                <div className='row'>
                    <div className={`ml-3 mr-3 ${class1} table mt-3`}>
                        <div className="col-md-12 mt-3 bg-primary fontWhite"><b>1º DOSE</b></div>
                        <div className="col-md-12 mt-3">Vacinado: <b>{cadastro.vacinado}</b></div>
                        <div className="col-md-12 mt-3">Dose: <b>{cadastro.dose_vacina}</b></div>
                        <div className="col-md-12 mt-3">Lote Vacina: <b>{cadastro.lote_vacina}</b></div>
                        <div className="col-md-12 mt-3">Nome Vacina: <b>{cadastro.nome_vacina}</b></div>
                        <div className="col-md-12 mt-3">Aplicador: <b>{cadastro.aplicador}</b></div>
                    </div>
                    <div className={`ml-3 ${class2} table mt-3`}>
                        <div className="col-md-12 mt-3 bg-primary fontWhite"><b>2º DOSE</b></div>
                        <div className="col-md-12 mt-3">Vacinado: <b>{cadastro.vacinado2}</b></div>
                        <div className="col-md-12 mt-3">Dose: <b>{cadastro.dose_vacina2}</b></div>
                        <div className="col-md-12 mt-3">Lote Vacina: <b>{cadastro.lote_vacina2}</b></div>
                        <div className="col-md-12 mt-3">Nome Vacina: <b>{cadastro.nome_vacina2}</b></div>
                        <div className="col-md-12 mt-3">Aplicador: <b>{cadastro.aplicador2}</b></div>
                    </div>
                    <div className={`ml-3 mr-3 ${class1} table mt-3`}>
                        <div className="col-md-12 mt-3 bg-primary fontWhite"><b>3º DOSE</b></div>
                        <div className="col-md-12 mt-3">Vacinado: <b>{cadastro.vacinado3}</b></div>
                        <div className="col-md-12 mt-3">Dose: <b>{cadastro.dose_vacina3}</b></div>
                        <div className="col-md-12 mt-3">Lote Vacina: <b>{cadastro.lote_vacina3}</b></div>
                        <div className="col-md-12 mt-3">Nome Vacina: <b>{cadastro.nome_vacina3}</b></div>
                        <div className="col-md-12 mt-3">Aplicador: <b>{cadastro.aplicador3}</b></div>
                    </div >
                    {dose4}
                </div >
        }
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        <section className="content">
                            <Alerta
                                setOpen={this.state.setOpen}
                                texto={alertMsg.message}
                                classe={alertMsg.status} />
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Cadastrar Testes
                                </legend>

                                <div className="col-md-12 campos-pma card-body">
                                    <TextField
                                        required
                                        className="ml-3 mb-3 col-md-6"
                                        id="filled-size-normal"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        fullWidth
                                        name="tipo"
                                        label="CPF, Cartão do SUS"
                                        variant="outlined"
                                        type="text"
                                        onChange={e => this.setState({ cpf: e.target.value })}
                                    />
                                    <button
                                        className="ml-3 mb-3 btn-pv col-md-2 text-center btn-primary"
                                        onClick={() => this.buscaCpf(this.state.cpf)}
                                    >
                                        Buscar
                                    </button>
                                </div>
                            </fieldset>
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Dados Paciente
                                </legend>
                                <div className="col-md-12 row">
                                    <div className="col-md-4 mt-3">Nome: <b>{cadastro.nome}</b></div>
                                    <div className="col-md-4 mt-3">CPF: <b>{cadastro.cpf}</b></div>
                                    <div className="col-md-4 mt-3">Data de Nascimento: <b>{dataFormatada}</b></div>
                                    <div className="col-md-4 mt-3">Telefone: <b>{cadastro.telefone}</b></div>
                                    <div className="col-md-4 mt-3">Idade: <b>{cadastro.idade}</b></div>
                                </div>
                                {vacinacao}
                            </fieldset>
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Estratégia e Local para realização de testagem
                                </legend>
                                <div className="col-md-12 row">
                                    <div className="ol-md-12 mt-3 ml-3">Estratégia: </div>
                                    <FormControl className="col-md-12">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(e) => this.estrategia(e)}
                                        >
                                            <div className="col-md-4 mt-3"><FormControlLabel value="digAssitencial" control={<Radio />} label="Diagnóstico assistencial (sintomático)" /></div>
                                            <div className="col-md-4 mt-3"><FormControlLabel value="buscaAssintomatico" control={<Radio />} label="Busca ativa de assintomático" /></div>
                                            <div className="col-md-4 mt-3"> <FormControlLabel value="triagemEspecifica" control={<Radio />} label="Triagem de população específica" /></div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <hr />
                                <div className="col-md-12 row">
                                    <div hidden={this.state.digAssitencialChecked} className="col-md-12 mt-3">
                                        <FormControl className="col-md-12">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => this.diagnostico(e)}
                                            >
                                                <div className="col-md-4 mt-3"><FormControlLabel value="monitoramentoContatos" control={<Radio />} label="Diagnóstico assistencial (sintomático)" /></div>
                                                <div className="col-md-4 mt-3"><FormControlLabel value="investigacaoSurtos" control={<Radio />} label="Busca ativa de assintomático" /></div>
                                                <div className="col-md-4 mt-3"> <FormControlLabel value="MonitoramentoRiscoVOC" control={<Radio />} label="Triagem de população específica" /></div>
                                                <div className="col-md-4 mt-3"> <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                                                    <TextField
                                                        required={!this.state.outro}
                                                        hidden={this.state.outro}
                                                        className="col-md-8 campoAlinha"
                                                        id="filled-size-normal"
                                                        InputProps={{
                                                            readOnly: false
                                                        }}
                                                        fullWidth
                                                        name="tipo"
                                                        label="Outro"
                                                        variant="outlined"
                                                        type="text"
                                                        onChange={e => this.setState({ outroDiagnostico: e.target.value })}
                                                    />
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div hidden={this.state.triagemEspecificaChecked} className="col-md-12 mt-3">
                                        <FormControl className="col-md-12">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => this.triagem(e)}
                                            >
                                                <div className="col-md-3 mt-3"><FormControlLabel value="trabalhadoresEssenciais" control={<Radio />} label="Trabalhadores de serviços essenciais ou estratégicos" /></div>
                                                <div className="col-md-3 mt-3"><FormControlLabel value="ProfissionaisSaude" control={<Radio />} label="Profissionais da saúde" /></div>
                                                <div className="col-md-3 mt-3"> <FormControlLabel value="gestantesPuerperas" control={<Radio />} label="Gestantes e puerperas" /></div>
                                                <div className="col-md-3 mt-3"> <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                                                    <TextField
                                                        required={!this.state.outro}
                                                        hidden={this.state.outro}
                                                        className="col-md-8 campoAlinha"
                                                        id="filled-size-normal"
                                                        InputProps={{
                                                            readOnly: false
                                                        }}
                                                        fullWidth
                                                        name="tipo"
                                                        label="Outra Triagem"
                                                        variant="outlined"
                                                        type="text"
                                                        onChange={e => this.setState({ outraTriagem: e.target.value })}
                                                    />
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <hr />
                                <div className="col-md-12 mt-3">Local de realização de Testagem: </div>
                                <FormControl className="col-md-12">
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => this.localTeste(e)}
                                    >
                                        <div className="col-md-3 mt-3"><FormControlLabel value="parqueIracema" control={<Radio />} label="Parque Iracema" /></div>
                                        <div className="col-md-3 mt-3"><FormControlLabel value="upaIII" control={<Radio />} label="UPA III" /></div>
                                        <div className="col-md-3 mt-3"> <FormControlLabel value="leblon" control={<Radio />} label="Leblon" /></div>
                                        <div className="col-md-3 mt-3"> <FormControlLabel value="ubsSaoJose" control={<Radio />} label="UBs São José" /></div>
                                        <div className="col-md-3 mt-3"><FormControlLabel value="ubsBairrodeLourdes" control={<Radio />} label="UBs Bairo de Lourdes" /></div>
                                        <div className="col-md-3 mt-3"><FormControlLabel value="ubsVilaUnião" control={<Radio />} label="UBs Vila União" /></div>
                                        <div className="col-md-3 mt-3"> <FormControlLabel value="ubsSaoCarlos" control={<Radio />} label="UBs São Carlos" /></div>
                                        <div className="col-md-3 mt-3"><FormControlLabel value="ubsArcoIris" control={<Radio />} label="UBs Arco Íris" /></div>
                                        <div className="col-md-3 mt-3"><FormControlLabel value="ubsVilaNorte" control={<Radio />} label="UBs Vila Norte" /></div>
                                    </RadioGroup>
                                </FormControl>
                            </fieldset>
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Dados Clínicos Epidemiológicos
                                </legend>
                                <div className="col-md-12">
                                    <FormControl className="row">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(e) => this.dadosClinicos(e)}
                                        >
                                            <div className="col-md-3"><FormControlLabel value="assintomatico"
                                                control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="assintomatico" />} label="Assintomático" /></div>
                                            <div className="col-md-3"><FormControlLabel value="febre" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="febre" />} label="Febre" /></div>
                                            <div className="col-md-3"><FormControlLabel value="dorGarganta" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="dorGarganta" />} label="Dor de Garganta" /></div>
                                            <div className="col-md-3"><FormControlLabel value="dispneia" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="dispneia" />} label="Dispnéia" /></div>
                                            <div className="col-md-3"><FormControlLabel value="tosse" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="tosse" />} label="Tosse" /></div>
                                            <div className="col-md-3"><FormControlLabel value="coriza" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="coriza" />} label="Coriza" /></div>
                                            <div className="col-md-3"><FormControlLabel value="dorCabeca" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="dorCabeca" />} label="Dor de Cabeça" /></div>
                                            <div className="col-md-3"><FormControlLabel value="disturbiosGustativos" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="disturbiosGustativos" />} label="Distúrbios Gustativos" /></div>
                                            <div className="col-md-3"><FormControlLabel value="disturbiosOlfativos" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="disturbiosOlfativos" />} label="Distúrbios Olfativos" /></div>
                                            <div className="col-md-3"> <FormControlLabel value="outro" control={<Checkbox onChange={(e) => this.dadosClinicos(e)} name="outro" />} label="Outro" />
                                                <TextField
                                                    required={!this.state.outroSintoma}
                                                    hidden={this.state.outroSintoma}
                                                    className="col-md-8 campoAlinha"
                                                    id="filled-size-normal"
                                                    InputProps={{
                                                        readOnly: false
                                                    }}
                                                    fullWidth
                                                    name="outro"
                                                    label="Outro sintoma"
                                                    variant="outlined"
                                                    type="text"
                                                    onChange={e => this.setState({ sintomaOutro: e.target.value })}
                                                    onBlur={e => this.setOutroOnBlur(e)}
                                                />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                    <hr />
                                    <TextField
                                        className="col-md-12 campoAlinha"
                                        id="filled-size-normal"
                                        InputProps={{
                                            readOnly: false
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        fullWidth
                                        name="dtInicio"
                                        label="Data Início dos Sintomas"
                                        variant="outlined"
                                        type="date"
                                        onChange={e => this.setState({ dtIncioSintomas: e.target.value })}
                                    />
                                    <hr />
                                    <div className="col-md-12 mt-3">Condições: </div>
                                    <FormControl className="row">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(e) => this.condicoes(e)}
                                        >
                                            <div className="col-md-3"><FormControlLabel value="drcd" control={<Checkbox onChange={(e) => this.condicoes(e)} name="drcd" />} label="Doenças respiratórias crônicas descompensadas" /></div>
                                            <div className="col-md-3"><FormControlLabel value="drcea" control={<Checkbox onChange={(e) => this.condicoes(e)} name="drcea" />} label="Doenças renais crônicas em estágio avançado(graus 3,4,e 5)" /></div>
                                            <div className="col-md-3"><FormControlLabel value="pdcefi" control={<Checkbox onChange={(e) => this.condicoes(e)} name="pdcefi" />} label="Portador de doenças cromossômicas ou estado de fragilidade imunológica" /></div>
                                            <div className="col-md-3"><FormControlLabel value="dcc" control={<Checkbox onChange={(e) => this.condicoes(e)} name="dcc" />} label="Doenças cardíacas crônicas" /></div>
                                            <div className="col-md-3"><FormControlLabel value="puerpera" control={<Checkbox onChange={(e) => this.condicoes(e)} name="puerpera" />} label="Púerpera(até 45 dias do parto)" /></div>
                                            <div className="col-md-3"><FormControlLabel value="diabete" control={<Checkbox onChange={(e) => this.condicoes(e)} name="diabete" />} label="Diabetes" /></div>
                                            <div className="col-md-3"><FormControlLabel value="gestante" control={<Checkbox onChange={(e) => this.condicoes(e)} name="gestante" />} label="Gestante" /></div>
                                            <div className="col-md-3"><FormControlLabel value="obessidade" control={<Checkbox onChange={(e) => this.condicoes(e)} name="obessidade" />} label="Obessidade" /></div>
                                            <div className="col-md-3"><FormControlLabel value="imuno" control={<Checkbox onChange={(e) => this.condicoes(e)} name="imuno" />} label="Imunosupressão" /></div>
                                            <div className="col-md-3"> <FormControlLabel value="outro" control={<Checkbox onChange={(e) => this.condicoes(e)} name="outro" />} label="Outro" />
                                                <TextField
                                                    required={!this.state.outraCondicao}
                                                    hidden={this.state.outraCondicao}
                                                    className="col-md-8 campoAlinha"
                                                    id="filled-size-normal"
                                                    InputProps={{
                                                        readOnly: false
                                                    }}
                                                    fullWidth
                                                    name="outro"
                                                    label="Outra Condição"
                                                    variant="outlined"
                                                    type="text"
                                                    onChange={e => this.setState({ condicaoOutra: e.target.value })}
                                                    onBlur={e => this.setOutraCondicaoOnBlur(e)}
                                                />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </fieldset>
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Exames Laboratoriais
                                </legend>
                                <div className="col-md-12 pmaRow rowPma">
                                    <div className="col-md-2 tablePma">
                                        <div className="col-md-12 borderBottom fildTable">Tipo de teste</div>
                                        <div className="col-md-12 vertical">Teste rápido de antigeno</div>
                                    </div>
                                    <div className="col-md-2 tablePma">
                                        <div className="col-md-12 borderBottom borderRight fildTable">Estado do teste</div>
                                        <div className="col-md-12 rowPma">
                                            <FormControl className="col-md-12">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => this.estadoTeste(e)}
                                                >
                                                    <div className="col-sm-12"><FormControlLabel value="solicitado" control={<Radio />} label="Solicitado" /></div>
                                                    <div className="col-sm-12"><FormControlLabel value="concluido" control={<Radio />} label="Concluido" /></div>
                                                    <div className="col-sm-12"> <FormControlLabel value="coletado" control={<Radio />} label="Coletado" /></div>
                                                    <div className="col-sm-12"> <FormControlLabel value="naoSolicitado" control={<Radio />} label="Não Solicitado" /></div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-2 tablePma">
                                        <div className="col-md-12 borderBottom fildTable">Data de Coleta</div>
                                        <TextField
                                            className="col-md-12"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            fullWidth
                                            name="dtInicio"
                                            label="Data Coleta"
                                            variant="outlined"
                                            type="date"
                                            onChange={e => this.setState({ dtColeta: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-2 tablePma">
                                        <div className="col-md-12 borderBottom fildTable">Fabricante</div>
                                        <div className="col-md-12 ">Teste rápido de antigeno</div>
                                    </div>
                                    <div className="col-md-2 tablePma">
                                        <div className="col-md-12 borderBottom fildTable">Lote</div>
                                        <div className="col-md-12 ">Teste rápido de antigeno</div>
                                    </div>
                                    <div className="col-md-2 tablePma borderRight">
                                        <div className="col-md-12 borderBottom borderRight fildTable">Resultado</div>
                                        <div className="col-md-12 rowPma">
                                            <FormControl className="col-md-12">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => this.resultado(e)}
                                                >
                                                    <div className="col-md-12 "><FormControlLabel value="naoReagente" control={<Radio />} label="Não reagente" /></div>
                                                    <div className="col-md-12"><FormControlLabel value="reagente" control={<Radio />} label="Reagente" /></div>
                                                    <div className="col-md-12 "> <FormControlLabel value="inconclusivo" control={<Radio />} label="Inconclusivo ou Inderteminado" /></div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="card">
                                <legend className="card-header text-left legend-pma faq-btn-link">
                                    Encerramento
                                </legend>
                                <div className="col-md-12 pmaRow rowPma">
                                    <div className="col-md-5 tablePma">
                                        <div className="col-md-12 borderBottom borderRight fildTable">Evolução do caso</div>
                                        <div className="col-md-12 rowPma">
                                            <FormControl className="col-md-12">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => this.evolucao(e)}
                                                >
                                                    <div className="col-md-4 "><FormControlLabel value="cancelado" control={<Radio />} label="Cancelado" /></div>
                                                    <div className="col-md-4 "><FormControlLabel value="tratamentoDomiciliar" control={<Radio />} label="Em tratamento domiciliar" /></div>
                                                    <div className="col-md-4 "> <FormControlLabel value="cura" control={<Radio />} label="Cura" /></div>
                                                    <div className="col-md-4 "> <FormControlLabel value="internado" control={<Radio />} label="Internado" /></div>
                                                    <div className="col-md-4 "> <FormControlLabel value="internadoUTI" control={<Radio />} label="Internado em UTI" /></div>
                                                    <div className="col-md-4 "> <FormControlLabel value="obito" control={<Radio />} label="Óbito" /></div>
                                                    <div className="col-md-4 "> <FormControlLabel value="ignorado" control={<Radio />} label="Ignorado" /></div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-5 tablePma borderRight">
                                        <div className="col-md-12 borderBottom borderRight fildTable">Classificação Final</div>
                                        <div className="col-md-12 rowPma">
                                            <FormControl className="col-md-12">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => this.classFinal(e)}
                                                >
                                                    <div className="col-md-6 "><FormControlLabel value="descartado" control={<Radio />} label="Descartado" /></div>
                                                    <div className="col-md-6 "><FormControlLabel value="confirmadoClinicoEpidemiologico" control={<Radio />} label="Confirmado Clínico-Epidemiológico" /></div>
                                                    <div className="col-md-6 "> <FormControlLabel value="confimadoLaboratorial" control={<Radio />} label="Confimado Laboratorial" /></div>
                                                    <div className="col-md-6 "> <FormControlLabel value="confirmadoClinicoImagem" control={<Radio />} label="Confimado Clínico-Imagem" /></div>
                                                    <div className="col-md-6 "> <FormControlLabel value="confirmadoClinico" control={<Radio />} label="Confimado por Critério Clínico" /></div>
                                                    <div className="col-md-6 "> <FormControlLabel value="sindrome" control={<Radio />} label="Síndrome Gripal não Especificado" /></div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-2 tablePma borderRight">
                                        <div className="col-md-12 borderBottom fildTable">Data de Encerramento</div>
                                        <TextField
                                            className="col-md-12"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            fullWidth
                                            name="dtInicio"
                                            label="Data Encerramento"
                                            variant="outlined"
                                            type="date"
                                            onChange={e => this.setState({ dtEncerramento: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <button
                                className="btn-pv col-md-12 text-center btn-primary"
                                type="submit"
                            >
                                Cadastrar
                            </button>
                        </section>
                    </div>
                    <Footer />
                </div >
            </div >
        );
    }
}

export class RelatoriosTestes extends Component {
    constructor() {
        super();
        this.state = {
            dados: [],
            idadeMin: '',
            tipo: '',
            alert: {
                status: '',
                message: ''
            },
            setOpen: false,
            tipos: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getEventos = this.getEventos.bind(this);
    }

    getEventos() {
        getUsers().then(response2 => {
            this.setState({ dados: response2.data })
        });
    }

    componentDidMount() {
        this.getEventos();
    }

    buscaCpf = event => {
        var id = event.target.value;
        getTipoCadastros(id).then(response => {
            if (response.data === 1) {
                alert("Tipo já Cadastrado")
                this.setState({ tipo: '' })
                setTimeout(() => {
                    $("#resposta").html('');
                }, 1 * 1000)
            } else {
                setTimeout(() => {
                    $("#resposta").html('');
                }, 1 * 1000)
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const { idadeMin, tipo } = this.state
        var raw = { idadeMin, tipo }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", token)
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        apiBusca.post("/cadastros/cadTipos", requestOptions)
            .then(response => {
                this.setState({
                    alert: {
                        status: response.data.response.status,
                        message: response.data.response.result
                    }
                })
                let propis = this.props
                if (this.state.alert.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: this.state.alert.message
                    }).then(function () {
                        apareceAlert(propis)
                    })

                }
                else if (this.state.alert.status === 201) {
                    Toast.fire({
                        icon: 'error',
                        title: this.state.alert.message
                    })
                }
            })

            .catch(error => {
                this.setState({
                    alert: {
                        status: 201,
                        message: "Contate o Desenvolvedor do Sistema! cadastrarUnidades()->BAD_CONFIG"
                    }
                })
            })
        event.target.reset();
    }

    render() {
        var alertMsg = this.state.alert
        return (
            <div className="skin-blue bg-light sidebar-mini" id="panel">
                <div className="wrapper">
                    <NavTop dados={this.state.dados} />
                    <Sidebar dados={this.state.dados} />
                    <br />
                    <div className="content-wrapper">
                        {/* Main content */}
                        <section className="content">
                            <form id="wizard" onSubmit={this.handleSubmit}>
                                <Alerta
                                    setOpen={this.state.setOpen}
                                    texto={alertMsg.message}
                                    classe={alertMsg.status} />
                                <fieldset className="card">
                                    <legend className="card-header text-left legend-pma faq-btn-link">
                                        Relatórios Testes
                                    </legend>

                                    <div className="col-md-12 campos-pma card-body">

                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-11"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            fullWidth
                                            name="idadeMin"
                                            label="Idade Miníma"
                                            variant="outlined"
                                            type="text"
                                            onChange={e => this.setState({ idadeMin: e.target.value })}
                                        />

                                        <TextField
                                            required
                                            className="ml-3 mb-3 col-md-12"
                                            id="filled-size-normal"
                                            InputProps={{
                                                readOnly: false
                                            }}
                                            fullWidth
                                            name="tipo"
                                            label="Tipo Cadastro"
                                            variant="outlined"
                                            type="text"
                                            onBlur={this.buscaCpf}
                                            onChange={e => this.setState({ tipo: e.target.value })}
                                        />
                                    </div>
                                </fieldset>
                                <button
                                    className="btn-pv col-md-12 text-center btn-primary"
                                    type="submit"
                                >
                                    Cadastrar
                                </button>
                            </form>
                        </section>
                    </div>
                    <Footer />
                </div >
            </div >
        );
    }
}