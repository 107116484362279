import React from "react";
import App from "./App";
import { isAuthenticated } from "./services/dados";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Cadastro from "./Vacina/Cadastro";
import CadastroEdita from "./Vacina/CadastroEdita";
import CadastroEditaADM from "./Vacina/CadastroEditaADM";
import CadastroSaude from "./Vacina/CadastroSaude";
import CadastroAbrigo from "./Vacina/CadastroAbrigo";

import Acessar from "./components/Acessar";
import Vacina from "./Vacina/";
import CadastrarLotes, { CadastroGeralVacina } from "./Vacina/CadastrarLotes";
import ListaNaoVacinados from "./Vacina/ListaNaoVacinados2";
import ListaVacinados from "./Vacina/ListaVacinados";
import Lista2Dose from "./Vacina/Lista2Dose";
import ListaRecusados from "./Vacina/ListaRecusados";
import { GerarListaBairro, ListaBairro } from "./Listas/GerarListasBairros";
import { GerarListaBairroDatas } from "./Listas/GerarListasBairrosDatas";
import {
  BuscaCadastro,
  BuscaCadastroCPF,
  CadastroCpf,
} from "./Vacina/buscaCPF";
import {
  BuscaCadastroBaixa,
  BuscaCadastroCPFBaixa,
  CadastroCpfBaixa,
} from "./Vacina/buscaCPFBaixa";
import { AlunoNomeBusca, BuscaAluno } from "./Vacina/buscaAluno";
import TrocaSenha from "./Vacina/TrocaSenha";
import ConsultaListas from "./Listas/ListaAplicadores";
import ListaBairroAplicador from "./Listas/ListaBairroAplicador";
import ListaConfAplicador from "./Listas/ListaConfAplicador";
import CadastroNomeBusca from "./Vacina/buscaNome";
import CadastroNomeBuscaBaixa from "./Vacina/buscaNomeBaixa";
import { GerarOcorrencia } from "./Listas/GerarOcorrencia";
import InserirReacao from "./Vacina/InserirReacao";
import inserirAplicador from "./Vacina/inserirAplicador";
import InserirAplicador2Dose from "./Vacina/inserirAplicador2Dose";
import InserirAplicador3Dose from "./Vacina/inserirAplicador3Dose";
import InserirAplicador4Dose from "./Vacina/inserirAplicador4Dose";
import SimpleForm from "./Vacina/ChatBot";
import { ListaTipo, ListaTiposData } from "./Listas/ListasTipo";
import RelatorioDiario, {
  RelatorioDiarioAplicador,
} from "./Vacina/RelatorioDiario";
import RelatorioDiarioRapido from "./Vacina/RelatorioDiarioRapido";
import ListaNaoVacinadosAcamados from "./Vacina/ListaNaoVacinadosAcamados";
import ListaNaoVacinadosAcamados2dose from "./Vacina/ListaNaoVacinadosAcamados2dose";
import ListaNaoVacinadosAcamados3dose from "./Vacina/ListaNaoVacinadosAcamados3dose";
import ListaNaoVacinadosAcamados4dose from "./Vacina/ListaNaoVacinadosAcamados4dose";
import ListaVacinadosAplicador from "./Vacina/ListaVacinadosAplicador";
import ListaVacinadosAcamados from "./Vacina/ListaVacinadosAcamados";
import ListaVacinadosGeral from "./Vacina/ListaVacinadosGeral";
import ListaVacinadosAnterior0702 from "./Vacina/ListaVacinadosAnterior0702";
import RemoverVacina from "./Vacina/removerVacina";
import {
  ListaVacinados1Dose,
  ListaVacinados2Dose,
} from "./Vacina/ListaVacinadosDose";
import { Panel } from "./Vacina/PainelVacinas";
import DadosVacinas from "./Vacina/DadosVacinas";
import Pagina404 from "./Pagina404";
import Logs from "./Vacina/Logs";
import CadastroUsuarios from "./Vacina/CadastrarUsuarios";
import CadastroUnidades from "./Vacina/CadastrarUnidades";
import { EditarUsuarios, EditaUser } from "./Vacina/EditarUsuarios";
import { EditaAlunos } from "./Vacina/EditarAlunos";
import { AlunoNomeBuscaEdita, BuscaAlunoEdita } from "./Vacina/buscaAlunoEdita";

import { EditarUnidades, EditaUni } from "./Vacina/EditarUnidades";
import Contatos from "./Contatos/Contatos";
import {
  EditarIdadesVacinacao,
  EditaIdade,
} from "./Vacina/EditarIdadesVacinacao";
import {
  EditarIdadesComorbidades,
  EditaComorbidade,
} from "./Vacina/EditarComorbidades";
import CadastroIdades from "./Vacina/CadastroIdades";
import ListaVacinadosAcamadosGeral from "./Vacina/ListaVacinadosAcamadosGeral";
import ListaSIPNI from "./Vacina/ListaSIPNI";
import AlunosExcel from "./Contatos/AlunosExcel";

//Cadastro Cidadao
import CadastrarComorbidades from "./Cadastro/CadastroComorbidades";
import CadastrarCidadao from "./Cadastro/";
import CadastrarPopulacao from "./Cadastro/Cadastro";
import Protocolo from "./Cadastro/Protocolo";
import Contato from "./Cadastro/Contato";
import Protocolos from "./Contatos/Protocolos";
import Excel from "./Contatos/Excel";
import CadastroComorbidades from "./Cadastro/CadastrarComorbidade";
import CadastroCidadao from "./Cadastro/CadastroCidadao";
import CadastroCaminhoneiros from "./Cadastro/CadastroCaminhoneiros";
import CadastrarCaminhoneiros from "./Cadastro/CadastrarCaminhoneiros";
import CadastrarImprensa from "./Cadastro/CadastrarImprensa";
import CadastrarLactantes from "./Cadastro/CadastrarLactantes";
import CadastroImprensa from "./Cadastro/CadastroImprensa";
import CadastroLactantes from "./Cadastro/CadastroLactantes";
import CadastroEstudantes, {
  CadastrarEstudantes,
} from "./Cadastro/CadastroEstudantes";
import CadastroComorbidadesEstudantes, {
  CadastrarComorbidadesEstudantes,
} from "./Cadastro/CadastroComorbidadeAlunos";
import ProtocoloEstudantes from "./Cadastro/ProtocoloEstudantes";

import AlunosListas from "./Vacina/CadastroListas";

//Agendamento
import Agendamento from "./Agendamento/index";
import CadastroCriancas, {
  CadastrarCriancas,
} from "./Cadastro/CadastroCriancas";
import { CadastrarTestes, RelatoriosTestes } from "./Vacina/CadastrarTestes";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/Acessar", state: { from: props.location } }}
        />
      )
    }
  />
);
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={App} />
      <Route path="/Inicio" exact={true} component={App} />
      <Route path="/Cadastrar" component={CadastrarCidadao} />
      <Route path="/CadastrarPopulacao" component={CadastrarPopulacao} />
      <Route path="/CadastrarComorbidades" component={CadastrarComorbidades} />
      <Route
        path="/CadastrarCaminhoneiros"
        component={CadastrarCaminhoneiros}
      />
      <Route path="/CadastrarLactantes" component={CadastrarLactantes} />
      <Route path="/CadastrarImprensa" component={CadastrarImprensa} />
      <Route path="/CadastroLactantes" component={CadastroLactantes} />
      <Route path="/CadastroImprensa" component={CadastroImprensa} />

      <Route path="/Protocolo" component={Protocolo} />
      <Route path="/Acessar" component={Acessar} />
      <Route path="/Contato" component={Contato} />
      <Route path="/Protocolos" component={Protocolos} />
      <Route path="/ProtocoloEstudantes" component={ProtocoloEstudantes} />

      <PrivateRoute path="/AlunosExcel" component={AlunosExcel} />
      <PrivateRoute path="/BuscaAlunoRede" component={BuscaAluno} />
      <PrivateRoute path="/CadastroAlunoBusca" component={AlunoNomeBusca} />
      <PrivateRoute path="/AlunosListas" component={AlunosListas} />

      <PrivateRoute path="/Cadastro" component={Cadastro} />
      <PrivateRoute path="/CadastroSaude" component={CadastroSaude} />
      <PrivateRoute path="/CadastroAbrigo" component={CadastroAbrigo} />
      <PrivateRoute path="/Vacina" component={Vacina} />
      <PrivateRoute path="/CadastrarLotes" component={CadastrarLotes} />
      <PrivateRoute path="/TrocarSenha" component={TrocaSenha} />
      <PrivateRoute
        path="/CadastroGeralVacina"
        component={CadastroGeralVacina}
      />
      <PrivateRoute path="/ListaNaoVacinados" component={ListaNaoVacinados} />
      <PrivateRoute path="/ListaVacinados" component={ListaVacinados} />
      <PrivateRoute path="/Lista2Dose" component={Lista2Dose} />
      <PrivateRoute path="/ListaRecusados" component={ListaRecusados} />
      <PrivateRoute path="/GerarListaBairro" component={GerarListaBairro} />
      <PrivateRoute
        path="/GerarListaBairroData"
        component={GerarListaBairroDatas}
      />
      <PrivateRoute path="/ListaBairro" component={ListaBairro} />
      <PrivateRoute path="/BuscaCadastro" component={BuscaCadastro} />
      <PrivateRoute path="/BuscaCadastroCPF" component={BuscaCadastroCPF} />
      <PrivateRoute path="/BuscaCadastroBaixa" component={BuscaCadastroBaixa} />
      <PrivateRoute
        path="/BuscaCadastroCPFBaixa"
        component={BuscaCadastroCPFBaixa}
      />
      <PrivateRoute path="/CadastroCpf" component={CadastroCpf} />
      <PrivateRoute path="/CadastroCpfBaixa" component={CadastroCpfBaixa} />
      <PrivateRoute path="/ConsultaListas" component={ConsultaListas} />
      <PrivateRoute
        path="/ListaBairroAplicador"
        component={ListaBairroAplicador}
      />
      <PrivateRoute path="/ListaConfAplicador" component={ListaConfAplicador} />
      <PrivateRoute path="/CadastroNomeBusca" component={CadastroNomeBusca} />
      <PrivateRoute
        path="/CadastroNomeBuscaBaixa"
        component={CadastroNomeBuscaBaixa}
      />
      <PrivateRoute path="/GerarOcorrencia" component={GerarOcorrencia} />
      <PrivateRoute path="/InserirReacao" component={InserirReacao} />
      <PrivateRoute path="/Logs" component={Logs} />
      <PrivateRoute path="/SIPNI" component={ListaSIPNI} />
      <PrivateRoute path="/CadastrarUsuarios" component={CadastroUsuarios} />
      <PrivateRoute path="/CadastrarUnidades" component={CadastroUnidades} />
      <PrivateRoute path="/RelatorioDiario" component={RelatorioDiario} />
      <PrivateRoute
        path="/RelatorioDiarioRapido"
        component={RelatorioDiarioRapido}
      />
      <PrivateRoute path="/ListaTipo" component={ListaTipo} />
      <PrivateRoute path="/ListaTiposData" component={ListaTiposData} />
      <PrivateRoute path="/inserirAplicador" component={inserirAplicador} />
      <PrivateRoute
        path="/InserirAplicador2Dose"
        component={InserirAplicador2Dose}
      />
      <PrivateRoute
        path="/InserirAplicador3Dose"
        component={InserirAplicador3Dose}
      />
      <PrivateRoute
        path="/InserirAplicador4Dose"
        component={InserirAplicador4Dose}
      />
      <PrivateRoute
        path="/RelatorioDiarioAplicador"
        component={RelatorioDiarioAplicador}
      />
      <PrivateRoute
        path="/ListaVacinadosAcamadosGeral"
        component={ListaVacinadosAcamadosGeral}
      />
      <PrivateRoute
        path="/ListaNaoVacinadosAcamados"
        component={ListaNaoVacinadosAcamados}
      />
      <PrivateRoute
        path="/ListaNaoVacinadosAcamados2dose"
        component={ListaNaoVacinadosAcamados2dose}
      />
      <PrivateRoute
        path="/ListaNaoVacinadosAcamados3dose"
        component={ListaNaoVacinadosAcamados3dose}
      />
      <PrivateRoute
        path="/ListaNaoVacinadosAcamados4dose"
        component={ListaNaoVacinadosAcamados4dose}
      />
      <PrivateRoute
        path="/ListaVacinadosAplicador"
        component={ListaVacinadosAplicador}
      />
      <PrivateRoute
        path="/ListaVacinadosAcamados"
        component={ListaVacinadosAcamados}
      />
      <PrivateRoute
        path="/ListaVacinadosGeral"
        component={ListaVacinadosGeral}
      />
      <PrivateRoute
        path="/ListaVacinadosAnterior0702"
        component={ListaVacinadosAnterior0702}
      />
      <PrivateRoute path="/CadastroEdita" component={CadastroEdita} />
      <PrivateRoute path="/CadastroEditaADM" component={CadastroEditaADM} />
      <PrivateRoute path="/RemoverVacina" component={RemoverVacina} />
      <PrivateRoute path="/EditarUsuarios" component={EditarUsuarios} />
      <PrivateRoute path="/EditaUser" component={EditaUser} />
      <PrivateRoute path="/EditarAlunos" component={EditaAlunos} />
      <PrivateRoute path="/EditaAlunos" component={BuscaAlunoEdita} />
      <PrivateRoute path="/BuscaEditaAlunos" component={AlunoNomeBuscaEdita} />
      <PrivateRoute path="/EditarUnidades" component={EditarUnidades} />
      <PrivateRoute path="/EditaUni" component={EditaUni} />
      <PrivateRoute
        path="/ListaVacinados1Dose"
        component={ListaVacinados1Dose}
      />
      <PrivateRoute
        path="/ListaVacinados2Dose"
        component={ListaVacinados2Dose}
      />
      <PrivateRoute path="/PainelVacinas" component={Panel} />
      <PrivateRoute path="/DadosVacinas" component={DadosVacinas} />
      <PrivateRoute path="/Contatos" component={Contatos} />
      <PrivateRoute
        path="/EditarIdadesVacinacao"
        component={EditarIdadesVacinacao}
      />
      <PrivateRoute
        path="/EditarIdadesComorbidades"
        component={EditarIdadesComorbidades}
      />
      <PrivateRoute path="/EditaIdade" component={EditaIdade} />
      <PrivateRoute path="/EditaComorbidade" component={EditaComorbidade} />
      {/* Cadastros */}
      <Route path="/CadastroCidadao" component={CadastroCidadao} />
      <Route path="/CadastroComorbidades" component={CadastroComorbidades} />
      <Route path="/CadastroCaminhoneiros" component={CadastroCaminhoneiros} />
      <Route path="/CadastroAdolescentes" component={CadastroEstudantes} />
      <Route path="/CadastroCriancas" component={CadastroCriancas} />
      <Route
        path="/CadastroComorbidadesAdolescentes"
        component={CadastroComorbidadesEstudantes}
      />
      {/* Paginas de Cadastros */}
      <Route path="/CadastrarAdolescentes" component={CadastrarEstudantes} />
      <Route
        path="/CadastrarComorbidadesAdolescentes"
        component={CadastrarComorbidadesEstudantes}
      />
      <Route path="/CadastrarCriancas" component={CadastrarCriancas} />
      <PrivateRoute path="/CadastroIdades" component={CadastroIdades} />
      <PrivateRoute path="/CadastrarTestes" component={CadastrarTestes} />
      <PrivateRoute path="/RelatoriosTestes" component={RelatoriosTestes} />
      <Route path="/Chatbot" component={SimpleForm} />
      <PrivateRoute path="/excel" component={Excel} />
      <Route path="*" component={Pagina404} />
    </Switch>
  </BrowserRouter>
);
export default Routes;
