import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { apiNova } from "../services/api";
import ModalAplicarVacina from "./ModalAplicarVacina";

export function PainelADM({ dados, dadosUser }) {
  const [aplicacoes, setAplicacoes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();

  const handleObservacao = () => {
    const idCriptografadoPorAlgumMotivo = window.btoa(dados.id);
    localStorage.setItem("edit", idCriptografadoPorAlgumMotivo);
    history.push("/InserirReacao");
  };

  const aplicadorDTO = {
    dados,
    dadosUser,
    unidades,
    aplicacoes,
  };

  const getDose = (dose) => {
    switch (dose) {
      case 1:
        return "D1";
      case 2:
        return "D2";
      case 3:
        return "R1";
      case 4:
        return "R2";
      case 5:
        return "R3";
      case 6:
        return "DA";
      case 7:
        return "DU";
      case 8:
        return "D3";
      default:
        return "erro";
    }
  };

  useEffect(() => {
    async function fetchAplicacoes() {
      const response = await Axios.get(
        `${apiNova}/api/v1/vacinados/${dados.id}`
      );
      const aplicacoes = response.data.result.sort(
        (a, b) => a.dose_vacina - b.dose_vacina
      );
      setAplicacoes(aplicacoes);
    }
    if (dados.id) {
      fetchAplicacoes();
    }
  }, [dados]);
  useEffect(() => {
    async function fetchUnidades() {
      const response = await Axios.get(
        `https://api.anapolis.go.gov.br/vacinaApi/cadastros/getUnidades`
      );
      setUnidades(response.data);
    }
    fetchUnidades();
  }, []);

  const vacinaNaoAplicada = aplicacoes.filter(
    (aplicacao) => aplicacao.dt_aplicacao === null
  ).length;

  if (vacinaNaoAplicada) {
    return (
      <div>
        <h2>
          ERRO NA DOSAGEM DE VACINAS. POR FAVOR ENTRE EM CONTATO COM A CENTRAL
          DE IMUNIZAÇÃO
        </h2>
      </div>
    );
  }

  const aplicacoesSortidas = aplicacoes.sort((a, b) => {
    console.log(new Date(a.dt_aplicacao));
    console.log(new Date(b.dt_aplicacao));
    return new Date(a.dt_aplicacao) - new Date(b.dt_aplicacao);
  });

  return (
    <div>
      <div className="col-md-12 mt-3 mb-3">
        <Button
          onClick={handleObservacao}
          className="btn btn-warning"
          style={{ color: "white", fontWeight: "600" }}
        >
          Inserir Observação
        </Button>

        <Button variant="primary" className="ml-2" onClick={handleShow}>
          Aplicar Vacina
        </Button>
      </div>
      <table
        style={{ width: 100 + "%" }}
        className="display table-bordered table"
      >
        <thead>
          <tr>
            <th style={{ fontWeight: "bolder" }}>Dose</th>
            <th style={{ fontWeight: "bolder" }}>Data</th>
          </tr>
        </thead>
        <tbody>
          {aplicacoesSortidas.map((aplicacao, index) => {
            let yourDate = new Date(aplicacao.dt_aplicacao);
            const offset = yourDate.getTimezoneOffset();
            yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);
            const data = yourDate.toLocaleDateString("pt-BR");

            return (
              <tr key={index}>
                <td>
                  <Button className="pma-1vacina">
                    {getDose(aplicacao.dose_vacina)}
                  </Button>
                </td>
                <td className={"pma-1vacina"}>{data}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ModalAplicarVacina
        show={show}
        handleClose={handleClose}
        aplicadorDTO={aplicadorDTO}
      />
    </div>
  );
}
